import styled from 'styled-components';

export const Tabs = styled.div<{
  tabsCount?: number;
  isAuth?: boolean;
}>`
  display: ${({ tabsCount }) => (tabsCount && tabsCount > 2 ? 'grid' : 'none')};
  cursor: pointer;
  position: relative;
  grid-template-columns: ${({ tabsCount }) => `repeat(${tabsCount}, 1fr)`};
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgba(60, 64, 67, 0.07)'
      : isIframe && iframeTheme.current === 'cintera'
      ? 'rgba(92, 65, 75, 0.37)'
      : '#1a1145'};
  padding: 3px;
  border-radius: 0.75rem;
  max-width: max-content;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

export const TabItem = styled.div`
  padding: 6px 14px;
  border-radius: 0.5rem;
  transition: all 300ms;
  position: relative;

  :focus-visible {
    outline: none;
  }

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;
