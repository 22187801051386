import DropDownMenu from './DropDownMenu';

import {useRef} from 'react';
import {DropDownProps, DropDownStyled} from '@/components/NavBar/components';

const DropDown = ({children, items, isOpen, setOpened, ...props}: DropDownProps) => {
  const DropRef = useRef(null);

  return (
    <DropDownStyled onClick={setOpened} ref={DropRef} {...props}>
      {children}
      <DropDownMenu open={isOpen} items={items} setOpened={setOpened} wrapperRef={DropRef}/>
    </DropDownStyled>
  );
};

export default DropDown;
