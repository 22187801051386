import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link) <{ color?: string; isState?: boolean }>`
font-size: 28px;
color:${({ color = '#ffffff' }) => color};
font-weight: 500;

user-select: none;

  cursor: pointer;

  transition: color 0.3s ease-in-out;

  :hover {
  color: #ffffff;

}

`;

export const Title = styled.span`
 color: #ffffff;
font-size: 28px;

font-weight: 500;

user-select: none;


`;

export const Separator = styled.span`
font-size: 28px;
color: #BBB6DA;
user-select: none;
`;