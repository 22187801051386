export const ExportIcon = ({ isIframe, iframeTheme }: { isIframe?: boolean; iframeTheme?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6668 10.8333V15.8333C16.6668 16.2754 16.4912 16.6993 16.1787 17.0118C15.8661 17.3244 15.4422 17.5 15.0002 17.5H5.00016C4.55814 17.5 4.13421 17.3244 3.82165 17.0118C3.50909 16.6993 3.3335 16.2754 3.3335 15.8333V10.8333M10.0002 12.5V2.5M10.0002 2.5L7.0835 5.41667M10.0002 2.5L12.9168 5.41667"
        stroke={
          isIframe && iframeTheme === 'light' ? 'rgb(26 17 69)' : iframeTheme === 'cintera' ? '#E4E4FF' : '#E4E4FF'
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
