export const BurgerMenu = ({onClick, opened}: { onClick?: (value: boolean) => void, opened: boolean }) => {
  return (
    <svg onClick={() => onClick?.(!opened)} width="32" height="32" viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M5.33301 7.66669H26.6663" stroke="#E4E4FF" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M5.33301 16H26.6663" stroke="#E4E4FF" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M5.33301 24.3333H26.6663" stroke="#E4E4FF" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
};
