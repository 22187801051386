import styled from 'styled-components';
import { LabelText } from '@/styles';

export const FormButtonsStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  > button {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    position: sticky;
    bottom: 0;
    z-index: 999;
    padding: 8px 0;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AddVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 196px;
  height: max-content;
  justify-content: center;
  align-items: center;
  border: 1px dashed #792d9c;
  border-radius: 12px;
  background-color: #1a1145;

  padding: 30px;
  cursor: pointer;

  &:hover {
    border: 1px dashed #b03ee5;
  }
`;

export const AddVideoText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;

  @media (max-width: 1028px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const AddVideoText1 = styled(AddVideoText)`
  cursor: pointer;
  position: relative;
  background-image: linear-gradient(to right, #792d9c, #d33e8b);
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
`;

export const TextBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
`;
export const SelectedFileWrapper = styled.div`
  background-color: #a855f71a;
  width: 100%;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
`;
export const LabelTextPurple = styled(LabelText)`
  font-size: 15px;
  font-weight: normal;
  color: #d8b4fe;
  text-align: center;
  margin-bottom: 5px;
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #251a56;
  border-radius: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
