import {CardContent, CardHeader, Description, TitleCard, VideoCardItemWrapper, VideoPreview,} from './VideoCard.styled';

import {memo} from 'react';

import {Link, useLocation} from 'react-router-dom';

import {ItemDropDown} from '@/components';
import {useActions} from '@/hooks';
import {Cue, Cues, Video, VideoInFolder, VideoInPlaylist} from '@/types';

export type VideoCardPropsType = {
  movie?: Video | VideoInPlaylist | VideoInFolder;
  cue?: Cue | Cues;
  to?: string;
};
export const VideoCard = memo(({movie, cue, to}: VideoCardPropsType) => {
  // const {title, thumbnailUrl, publicId, created} = movie;
  const location = useLocation();
  const {openModal} = useActions();

  const itemsDropDown = [
    {
      id: 1,
      title: 'Редактировать',
      styles: {color: '#FFFFFF', fontSize: '16px', fontWeight: '400'},
      onClick: () => {
        openModal({active: 'EditVideo', data: {id: movie?.publicId, title: movie?.title}});
      },
    },
    {
      id: 2,
      title: 'Переместить',
      styles: {color: '#FFFFFF', fontSize: '16px', fontWeight: '400'},
      onClick: () => {
        openModal({
          active: 'MoveVideo',
          data: {id: movie?.publicId, title: movie?.title},
        });
      },
    },
    {
      id: 3,
      title: 'Удалить',
      styles: {color: '#D33E8B', fontSize: '16px', fontWeight: '400'},
      onClick: () => {
        openModal({active: 'RemoveVideo', data: {id: movie?.publicId}});
      },
    },
  ];

  return (
    <VideoCardItemWrapper>
      <Link to={to ?? ''} state={`${location.pathname}`}>
        <VideoPreview bgImage={movie?.thumbnailUrl ?? cue?.image ?? ''}></VideoPreview>
      </Link>
      <CardContent>
        <CardHeader>
          <Link
            style={{display: 'flex', flexDirection: 'column', gap: '5px'}}
            to={to ?? ''}
            state={`${location.pathname}`}
          >
            <TitleCard>{movie?.title.slice(0, 100) ?? cue?.title}</TitleCard>
            {movie && <Description>Загружено {movie.created.slice(0, 10) ?? ''}</Description>}
          </Link>
          <ItemDropDown items={itemsDropDown}/>
        </CardHeader>
      </CardContent>
    </VideoCardItemWrapper>
  );
});

VideoCard.displayName = 'VideoCard';
