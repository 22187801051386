import styled from 'styled-components';

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #d8b4fe;
  background-color: #0b0330;
  border: 1px solid rgba(168, 85, 247, 0.3);
  border-radius: 6px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition:
    color 0.3s,
    background-color 0.3s;

  :hover {
    color: white;
    background-color: rgba(168, 85, 247, 0.2);
  }

  :focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px rgba(168, 85, 247, 0.2);
  }

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;
