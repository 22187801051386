import {Description, Inner, Modal, Title} from '../Modal';

import {useState} from 'react';

import {useGetFoldersTreeQuery, useMoveFolderMutation} from '@/api';
import {Button} from '@/components';
import {useActions, useAppSelector} from '@/hooks';
import {FormButtonsStyled} from '@/styles';
import {FoldersDropDown} from "@/components/Shared/FoldersDropDown";
import {filterFolders} from "@/utils/filterFolders";

export const MoveFolder = () => {
  const {closeModal, showNotification} = useActions();
  const id = useAppSelector((state) => state.modal.data?.id);
  const title = useAppSelector((state) => state.modal.data?.title);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const {data: folders} = useGetFoldersTreeQuery(undefined, {skip: !isAuth});
  const [moveInFolder, {isLoading}] = useMoveFolderMutation();
  const [selectedFolder, setSelectedFolder] = useState<string | null>('');
  const filteredData = filterFolders(folders ?? [], id ?? '');

  const moveFolder = async () => {
    try {
      if (id && selectedFolder) {
        const data = await moveInFolder({publicId: id, parentId: selectedFolder ?? null}).unwrap();
        if (data) {
          showNotification({
            text: `Папка ${title} успешно перемещена ${data.title ? `в папку ${data.title}` : ''}`,
            severity: 'success',
          });
          closeModal();
        }
      }
    } catch (error) {
      showNotification({
        text: 'Произошла ошибка',
        severity: 'error',
      });
    }
  };

  const closeHandler = () => {
    closeModal();
    setSelectedFolder('');
  };

  return (
    <Modal modal={'MoveFolder'} onClose={() => closeHandler()} closeButton>
      <Title>Переместить папку</Title>
      <Inner modal={'MoveFolder'}>
        <Description>{`Выберите, куда переместить папку «${title}»`}</Description>

        <FoldersDropDown
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          title={folders ? 'Все папки' : ''}
          data={filteredData}
        />

        <FormButtonsStyled>
          <Button themed="pink" variant="pink" onClick={closeHandler}>
            Отмена
          </Button>
          <Button
            width="100%"
            themed="colored"
            variant="colored"
            type="submit"
            disabled={selectedFolder === null || isLoading}
            onClick={moveFolder}
          >
            Переместить
          </Button>
        </FormButtonsStyled>
      </Inner>
    </Modal>
  );
};
