import styled from 'styled-components';

import { theme } from '@/styles';

export const TabsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  background-color: ${theme.colors.modal.purple_800};
  gap: 0.5rem;
  padding: 4px;
  border-radius: 10px;
`;

export const TabsTrigger = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s;
  padding: 4px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const TabsTriggerButton = styled(TabsTrigger)<{ isActive: boolean }>(({ isActive }) => ({
  backgroundColor: isActive ? theme.colors.modal.purple_100 : theme.colors.modal.purple_800,
  color: isActive ? 'white' : theme.colors.modal.text.gray,
}));
