import styled, { css } from 'styled-components';

type PropsType = {
  isFixed?: boolean;
  isMobile: boolean;
  isSearch?: boolean;
};

export const Wrapper = styled.div<PropsType>`
  min-height: 100vh;

  @media screen and (max-width: 768px) {
    gap: 8px;
  }

  ////!!!Modal open to correct view
  ${({ isFixed, isMobile }) => {
    if (isFixed) {
      if (isMobile) {
        return css`
          overflow-y: hidden;
          padding-right: 0;
          position: fixed;
          left: 0;
          right: 0;
          /// отключение шивтинга на тач устройствах
          @media (pointer: coarse) {
            padding-right: 0;
          }
        `;
      }
      return css`
        overflow-y: hidden;
        padding-right: 10px;
        position: fixed;
        left: 0;
        right: 0;
        /// отключение шивтинга на тач устройствах
        @media (pointer: coarse) {
          padding-right: 10px;
        }
      `;
    }
  }} /* ${({ isSearch }) =>
    isSearch &&
    css`
      background: url('/images/bg/ellipse-5.png') no-repeat 0% 90%;
    `} */ ${({ theme: { isIframe } }) =>
    isIframe &&
    css`
      padding-top: 0;
      min-height: unset;
    `}
`;

export const Main = styled.main`
  position: relative;
  width: 84%;

  @media screen and (max-width: 480px) {
    min-height: 100vh;
  }
`;
