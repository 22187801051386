export const Telegram = ({ onClick }: { onClick?: () => void }) => {
  return (
    <svg onClick={onClick} width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.57586 9.31823C1.63192 9.2902 1.688 9.26358 1.74265 9.23835C2.6928 8.79831 3.65556 8.38631 4.61691 7.9743C4.66877 7.9743 4.75563 7.91404 4.80468 7.89442C4.87895 7.86219 4.95324 7.83136 5.02751 7.79912C5.17046 7.73746 5.31341 7.6772 5.45495 7.61554C5.74083 7.49362 6.02529 7.37169 6.31118 7.24977L8.02228 6.51685C9.16301 6.02916 10.3052 5.54007 11.4459 5.05239C12.5866 4.5647 13.7288 4.07561 14.8695 3.58793C16.0102 3.10024 17.1524 2.61116 18.2931 2.12348C19.4338 1.63579 20.576 1.1467 21.7167 0.659019C21.9704 0.54971 22.2451 0.387144 22.5169 0.339496C22.7454 0.298856 22.9682 0.220382 23.198 0.176938C23.6338 0.0942562 24.1145 0.0606258 24.5321 0.241406C24.6765 0.304468 24.8096 0.392756 24.9203 0.503466C25.45 1.02759 25.3758 1.88804 25.2636 2.62517C24.4831 7.76268 23.7025 12.9016 22.9205 18.0391C22.814 18.744 22.6683 19.5176 22.1119 19.9632C21.641 20.3402 20.9712 20.3822 20.3896 20.2225C19.808 20.0613 19.2951 19.7236 18.792 19.3915C16.7053 18.0097 14.6173 16.6279 12.5306 15.2461C12.0345 14.9182 11.4824 14.4894 11.488 13.8938C11.4908 13.535 11.7052 13.2155 11.9238 12.931C13.7372 10.5655 16.3536 8.93986 18.3001 6.68361C18.5748 6.3655 18.7906 5.79092 18.4136 5.60734C18.1894 5.49803 17.9315 5.64658 17.7269 5.78812C15.154 7.5749 12.5824 9.36308 10.0095 11.1499C9.17004 11.7328 8.28997 12.3326 7.27816 12.4756C6.37286 12.6045 5.46196 12.3523 4.58609 12.0944C3.85176 11.8786 3.1188 11.6572 2.38868 11.4287C2.00049 11.3082 1.59969 11.1779 1.29979 10.9046C0.999891 10.6313 0.827543 10.1717 1.00832 9.80732C1.12184 9.57889 1.34186 9.43455 1.57309 9.31683L1.57586 9.31823Z"
        fill="#E4E4FF"
      />
    </svg>
  );
};
