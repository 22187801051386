import { Container } from './BalancePage.styled';
import { HistoryTab } from './HistoryTab/HistoryTab';
import { ReferralTab } from './ReferralTab';
import { Tabs } from './Tabs';
import { TariffsTab } from './TariffsTab/TariffsTab';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetOrdersQuery } from '@/api';
import { TitlePage } from '@/styles';

export const BalancePage = () => {
  const { t } = useTranslation('pages');
  const [tab, setTab] = useState(1);
  const { data: history, isLoading } = useGetOrdersQuery();

  return (
    <Container>
      <TitlePage style={{ marginBottom: '16px' }} initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }}>
        {t('balance.balance')}
      </TitlePage>
      <Tabs tab={tab} onTabChange={setTab} />
      {tab === 1 && <TariffsTab />}
      {tab === 2 && <HistoryTab history={history ?? []} isLoading={isLoading} />}
      {tab === 3 && <ReferralTab history={history ?? []} isLoading={isLoading} />}
    </Container>
  );
};
