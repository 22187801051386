export const CopyIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3135 11.8733C14.3135 13.2209 13.2211 14.3133 11.8735 14.3133H5.7735C4.42592 14.3133 3.3335 13.2209 3.3335 11.8733V5.77334C3.3335 4.42577 4.42592 3.33334 5.7735 3.33334H11.8735C13.2211 3.33334 14.3135 4.42577 14.3135 5.77334V11.8733Z"
        stroke="rgb(228, 228, 255)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12695 16.6667H14.227C15.5745 16.6667 16.667 15.5743 16.667 14.2267V8.12668"
        stroke="rgb(228, 228, 255)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
