import { BalanceText, BalanceWrapper } from './BalanceInfo.styled';

import { Button } from '../Shared/Buttons/Button/Button';

import { useAppSelector } from '@/hooks';

export const BalanceInfo = ({ onClick }: { onClick?: () => void }) => {
  const balance = useAppSelector((state) => state.user.user_balance);

  return (
    <BalanceWrapper>
      <BalanceText>{balance} ₽</BalanceText>
      <Button onClick={onClick} style={{ minHeight: '36px', height: '36px', padding: '8px 16px' }} themed="black">
        Пополнить
      </Button>
    </BalanceWrapper>
  );
};
