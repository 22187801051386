import {useCreateFolder} from '../../model';

import {useEffect} from 'react';

// import Pagination from '@mui/material/Pagination';
import {useGetFoldersQuery} from '@/api';
import {EmptyContent, FullScreenLoader, VideoCard, VideoFragmentCard} from '@/components';
import {FolderCard} from '@/components/Card/FolderCard';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import {FolderIcon} from '@/components/SvgIcons/FolderIcon';
import {useSearch} from '@/hooks';
import {FoldersWrapper, NotVideoText, VideosWrapper} from '@/styles';
import {Link} from "react-router-dom";

export const FolderWrapper = () => {
  const {searchVideos, isSearchVideosError, catchError, makeSearch, searchQueryValue, isSearchVideosLoading} =
    useSearch();
  // const {
  //   pagination: { pages, page },
  //   handleChangePage,
  //   setCount,
  // } = usePagination();
  const {openModalHandler} = useCreateFolder();
  const {data, isLoading, error} = useGetFoldersQuery({params: {sorted: 'date'}});

  // useEffect(() => {
  //   if (isSuccess) {
  //     setCount(data.folders.length === 0 ? 1 : data.folders.length);
  //   }
  // }, [isSuccess]);

  useEffect(() => {
    catchError(error || isSearchVideosError);
  }, [error, isSearchVideosError]);

  const renderSearchResults = () => {
    if (!searchQueryValue) {
      return (
        <>
          <FoldersWrapper>
            {data?.folders.map((folder) => (
              <FolderCard key={folder.publicId} to={`/folders/${folder.publicId}`} folder={folder}/>
            ))}
          </FoldersWrapper>
          {/* {data && pages > 1 && (
            <PaginationWrapper>
              <Pagination
                count={pages}
                page={page}
                color="primary"
                onChange={(e, newPage) => handleChangePage(e, newPage)}
              />
            </PaginationWrapper>
          )} */}
        </>
      );
    }

    return (
      <VideosWrapper>
        {searchVideos &&
          searchVideos.map((fragment) =>
            fragment?.cues.map((cue, i) => (
              <>
                {cue.isVideo ?
                  <VideoCard cue={cue} key={`${fragment.publicId}-${i}`} to={`/videos/${fragment.publicId}`}/>
                  : (
                    <Link to={`/videos/${fragment.publicId}?t=${cue.startsFrom ?? 0}`}
                          key={`${fragment.publicId}-${i}`}>
                      <VideoFragmentCard fragment={cue}/>
                    </Link>
                  )}
              </>
            )),
          )}
      </VideosWrapper>
    );
  };

  if (isLoading || isSearchVideosLoading) return <FullScreenLoader/>;

  if (!data?.folders.length) {
    return (
      <EmptyContent
        onClick={openModalHandler}
        icon={<FolderIcon/>}
        text="У вас ещё нет папок"
        buttonText="Создать папку"
        description={`Создайте первую папку\nи добавьте в неё видео`}
      />
    );
  }

  return (
    <>
      <SearchInVideoInput placeholder="По видео и фрагментам" getSearch={makeSearch}/>
      {searchVideos && searchVideos.length === 0 && searchQueryValue && !searchVideos ? (
        <NotVideoText>Результатов по запросу не найдено</NotVideoText>
      ) : (
        <>{renderSearchResults()}</>
      )}
    </>
  );
};
