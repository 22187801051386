export const API_SHARE_URL = import.meta.env.VITE_BASE_URL;
export const VK_AUTH_CLIENT_ID = import.meta.env.VITE_VK_AUTH_CLIENT_ID;
export const YANDEX_AUTH_CLIENT_ID = import.meta.env.VITE_YANDEX_AUTH_CLIENT_ID;

export const ACCESS_TOKEN = 'access';
export const REFRESH_TOKEN = 'refresh';
export const ONBOARDING = 'onboarding';
export const REFERRAL_CODE = 'referralCode';

export const GET_IMAGE = (category: string | number) =>
  `${import.meta.env.VITE_MEDIA_URL}/photos/backgrounds/${category}.jpg`;
