import { TimecodesButtonWrapper, TimecodesWrapper, TimecodesFormWrapper } from './TimecodesForm.styled';
import { TitleTime } from './TitleAndTime';

import { FormButtons } from '../../components';

import { Fragment } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { useUpdatePartialTimecodesMutation } from '@/api';
import { Button, FullScreenLoader } from '@/components';
import PlusBigIconBlack from '@/components/SvgIcons/PlusBigIconBlack';
import { useActions, useHandlingError } from '@/hooks';
import { BasePutTimecodesRequest, Timecode, TransformedTimecodesResponse } from '@/types';
import { parseDuration } from '@/utils';

type TimecodesFormProps = {
  timecodes: Timecode[];
  publicId: string;
  videoId: string;
  goToTime: (time: number | string) => void;
  onClose: () => void;
} & TransformedTimecodesResponse;

export const TimecodesForm = ({ timecodes, publicId, goToTime, onClose, videoId }: TimecodesFormProps) => {
  const { showNotification } = useActions();
  const [updateTimecodes, { isLoading: isUpdating, error }] = useUpdatePartialTimecodesMutation();
  const { catchError } = useHandlingError();

  const methods = useForm<{ timecodes: Timecode[] }>({ defaultValues: { timecodes } });

  const onFormSubmit = async ({ timecodes }: { timecodes: Timecode[] }) => {
    try {
      const resultData = {
        data: {
          timecodes: timecodes.map((it) => ({ ...it, start: parseDuration(it.start as string) })),
        },
      };

      await updateTimecodes({
        videoId,
        publicId,
        body: resultData as BasePutTimecodesRequest,
      }).unwrap();

      showNotification({
        text: 'Изменения успешно сохранены',
        severity: 'success',
      });
      onClose();
    } catch (e) {
      catchError(error, 'Изменения не сохранены');
    }
  };

  const { fields, insert, remove } = useFieldArray<{ timecodes: Timecode[] }, 'timecodes', 'key'>({
    control: methods.control,
    name: 'timecodes',
    keyName: 'key',
  });

  const handleAddNewTimecodes = (index: number) => {
    insert(index + 1, { start: '', text: '', title: '' });
  };

  return (
    <FormProvider {...methods}>
      {timecodes && (
        <TimecodesFormWrapper onSubmit={methods.handleSubmit(onFormSubmit)}>
          <TimecodesButtonWrapper>
            {isUpdating && <FullScreenLoader />}
            <TimecodesWrapper>
              {fields.map((timecode, index) => (
                <Fragment key={timecode.key}>
                  <TitleTime index={index} remove={() => remove(index)} goToTime={goToTime} />
                  <Button
                    style={{
                      padding: '13px',
                      alignSelf: 'center',
                      backgroundColor: '#3B2A7E',
                      border: 'none',
                    }}
                    onClick={() => handleAddNewTimecodes(index)}
                    disabled={isUpdating}
                  >
                    <PlusBigIconBlack />
                  </Button>
                </Fragment>
              ))}
            </TimecodesWrapper>
          </TimecodesButtonWrapper>
          <FormButtons
            disabled={isUpdating || timecodes.length === 0 || (methods.formState.errors.timecodes?.length || 0) > 0}
            onClose={onClose}
          />
        </TimecodesFormWrapper>
      )}
    </FormProvider>
  );
};
