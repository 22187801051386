import {useCallback} from 'react';

import {useParams, useSearchParams} from 'react-router-dom';

import {
  useGetFullSearchVideosAndFragmentsQuery,
  useGetSearchInFoldersQuery,
  useLazyGetFullSearchInVideoQuery
} from '@/api';
import {useAppSelector, useHandlingError} from '@/hooks/index';

export const useSearch = () => {
  const [params, setParams] = useSearchParams();
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const searchQueryValue = params.get('search');
  const {catchError} = useHandlingError();
  const {parentId, id} = useParams();

  //поиск фрагментов и видео внутри папки
  const {data: searchInFolder, isLoading: searchInFolderLoading} = useGetSearchInFoldersQuery(
    {query: searchQueryValue, publicId: parentId || ''},
    {skip: !searchQueryValue || !isAuth || !parentId},
  );

  //поиск фрагметов внутри видео
  const [
    getSearchInVideo,
    {
      data: searchInVideo,
      isLoading: isSearchInVideoLoading,
      error: searchInVideoError,
      isSuccess: searchInVideoSuccess
    },
  ] = useLazyGetFullSearchInVideoQuery();

  //поиск видео и фрагментов
  const {
    data: searchVideos,
    isLoading: isSearchVideosLoading,
    isError: isSearchVideosError,
  } = useGetFullSearchVideosAndFragmentsQuery(
    {query: searchQueryValue || ''},
    {skip: !searchQueryValue || !isAuth},
  );

  const getSearchInVideoHandler = useCallback(
    async (query: string) => {
      await getSearchInVideo({query, videoId: id ?? ''});
    },
    [id],
  );

  const handleSearch = async (query: string) => {
    try {
      const searchQuery = query || params.get('search') || '';
      setParams(searchQuery ? {search: searchQuery} : {});
    } catch (error) {
      catchError(error);
    }
  };

  const makeSearch = useCallback(
    (query: string) => {
      handleSearch(query);
    },
    [handleSearch],
  );

  return {
    searchVideos,
    isSearchVideosLoading,
    isAuth,
    setParams,
    params,
    searchQueryValue,
    handleSearch,
    makeSearch,
    catchError,
    isSearchVideosError,
    searchInFolder,
    parentId,
    searchInFolderLoading,
    getSearchInVideo,
    searchInVideo,
    isSearchInVideoLoading,
    searchInVideoError,
    searchInVideoSuccess,
    getSearchInVideoHandler
  };
};
