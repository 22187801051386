import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreHorizontalIcon } from 'lucide-react';
import MenuItem from '@mui/material/MenuItem';
import { StyledMenu } from './ItemDropDown.styled';

export type DropDownItem = {
  id: number;
  title: string;
  styles: { color: string };
  onClick?: () => void;
};

export const ItemDropDown = ({ items }: { items: DropDownItem[] }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (onClick: any) => {
    setAnchorEl(null);
    onClick && onClick();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizontalIcon color={'#FFFFFF'} />
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {items.map((item) => (
          <MenuItem
            style={item.styles}
            key={item.id}
            selected={item.title === ''}
            onClick={() => handleClose(item.onClick)}
          >
            {item.title}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};
