import { TabInterfaceItem, TabsInterface } from './VideoInterfaceTabs.styled';

import { useEditModeContext, useMaterials } from '../../../../model';

import { useRef, useState } from 'react';

import { VideoInterfaceDropDownMenu, VideoInterfaceDropDownWrapper } from '@/components';
import { EditMaterialIcon } from '@/components/SvgIcons/EditMaterialIcon';
import { ExportIcon } from '@/components/SvgIcons/ExportIcon';
import { useAppSelector, useIframe } from '@/hooks';

export const VideoInterfaceTabs = () => {
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const dropdownVideoInterfaceRef = useRef<HTMLUListElement>(null);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const { isEditMode, toggleEditMode } = useEditModeContext();
  const { isIframe, isLandingPage, copyIframe, iframeTheme } = useIframe();
  const { copyCurrentLink, copyContent, exportContent } = useMaterials();

  return (
    <TabsInterface>
      {!isIframe && !isLandingPage && isAuth && !isEditMode && (
        <TabInterfaceItem onClick={toggleEditMode}>
          <EditMaterialIcon />
        </TabInterfaceItem>
      )}
      <TabInterfaceItem onClick={() => setOpenedDropdown(!openedDropdown)}>
        <ExportIcon isIframe={isIframe} iframeTheme={iframeTheme} />
        <VideoInterfaceDropDownWrapper>
          <VideoInterfaceDropDownMenu
            isIframe={isIframe}
            iframeTheme={iframeTheme}
            exportContent={exportContent}
            onIframeCopy={copyIframe}
            copyLink={copyCurrentLink}
            copyContent={copyContent}
            ref={dropdownVideoInterfaceRef}
            isOpened={openedDropdown}
            onClose={() => setOpenedDropdown(false)}
          />
        </VideoInterfaceDropDownWrapper>
      </TabInterfaceItem>
    </TabsInterface>
  );
};
