import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  background-color: #251a56;
  border-radius: 8px;
  //padding: 11px 34px;
  padding: 11px 7px;
  cursor: pointer;

  display: flex;
  gap: 10px;
  align-items: center;

  :hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
    padding: 6px 21px;
  }
`;
