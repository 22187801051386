import { DropDownMenuProps } from './DropDownMenu.props';
import { Item, List, Wrapper } from './DropDownMenu.styled';

import { useEffect } from 'react';

import { useMount } from '@/hooks';

const DropDownMenu = ({ items, close, wrapperRef, open, ...props }: DropDownMenuProps) => {
  const { mounted } = useMount(open);

  useEffect(() => {
    if (!open) return;

    const handelClickOutside = (event: Event) => {
      if (!wrapperRef.current?.contains(event.target as HTMLElement)) {
        close();
      }
    };
    document.addEventListener('click', handelClickOutside);
    return () => {
      document.removeEventListener('click', handelClickOutside);
    };
  }, [wrapperRef, open]);

  if (mounted) {
    return (
      <Wrapper open={open} {...props}>
        <List>
          {items?.map((item) => (
            <Item key={item.key}>{item}</Item>
          ))}
        </List>
      </Wrapper>
    );
  }
  return null;
};

export default DropDownMenu;
