import {
  AgreementWrapper,
  FieldsWrapper,
  FormInnerStyled,
  HrStyled,
  LineWrapper,
  RegisterGradientText,
  SubmitButton,
} from './B2CForm.styled';
import { MyInput } from './MyInput';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRegisterUserMutation } from '@/api';
import { RegisterOrLoginWithSocial } from '@/components/Shared/RegisterOrLoginWithSocial/RegisterOrLoginWithSocial';
import { REFERRAL_CODE } from '@/constants';
import { useActions, useHandlingError } from '@/hooks';
import { RegisterB2CUser } from '@/types';

export const B2CForm = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const { openModal } = useActions();
  const { catchError } = useHandlingError();
  const code = localStorage.getItem(REFERRAL_CODE) || '';

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    // setError,
  } = useForm<RegisterB2CUser & { agreement: boolean }>();

  const [userRegister, { isLoading }] = useRegisterUserMutation({ fixedCacheKey: 'registerUser' });

  const onSubmit = async ({ password, email, username, notifyChanges }: RegisterB2CUser & { agreement: boolean }) => {
    try {
      await userRegister({
        firstName: 'firstName',
        lastName: 'lastName',
        surname: 'surname',
        // telegram: '',
        companyProfile: 'companyProfile',
        employeeAmount: 1,
        status: 'commercial',
        ...(code && { utm_ref_token: code }),
        password,
        email,
        username,
        notifyChanges,
      }).unwrap();

      reset();
      closeModal();

      openModal({ active: 'verifyinfo', data: { email } });
    } catch (error) {
      catchError(error);
    }
  };

  return (
    <form name="registration_b2c" onSubmit={handleSubmit(onSubmit)}>
      <FormInnerStyled>
        <FieldsWrapper>
          <MyInput
            required
            placeholder={'Имя пользователя'}
            {...register('username', {
              required: true,
              minLength: 3,
              maxLength: 256,
            })}
            error={errors.username}
          />

          <MyInput
            required
            placeholder={'Еmail'}
            {...register('email', {
              required: true,
              minLength: 5,
              maxLength: 256,
              pattern: /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-z]{2,6}$/,
            })}
            error={errors.email}
          />

          <MyInput
            required
            placeholder={'Пароль'}
            type="password"
            {...register('password', {
              required: true,
              minLength: 6,
              maxLength: 128,
              pattern: {
                value: /^(?=.*[a-zA-Z])(?=.*\d).{6,128}$/,
                message: 'Пароль должен содержать буквы и цифры',
              },
            })}
            error={errors.password}
          />
          <MyInput type="checkbox" label={'Подписаться на обновления'} {...register('notifyChanges')} />
        </FieldsWrapper>

        <SubmitButton type="submit" disabled={isLoading}>
          {t('reg', { ns: 'modal' })}
        </SubmitButton>
        <LineWrapper>
          <HrStyled />
          <span>или</span>
          <HrStyled />
        </LineWrapper>

        <RegisterOrLoginWithSocial />

        <AgreementWrapper>
          Регистрируясь, я соглашаюсь с{' '}
          <RegisterGradientText to="/policy/personal" target="_blank" rel="noopener noreferrer">
            условиями использования
          </RegisterGradientText>{' '}
          и{' '}
          <RegisterGradientText to="/policy/privacy" target="_blank" rel="noopener noreferrer">
            политикой конфиденциальности
          </RegisterGradientText>
        </AgreementWrapper>
      </FormInnerStyled>
    </form>
  );
};
