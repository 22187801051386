import styled from 'styled-components';

import { FragmentTimeV2 } from '@/components/Card/VideoFragmentCard/VideoFragmentCard.styled';

export const TitleTranscript = styled(FragmentTimeV2)`
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light' ? 'rgb(26 17 69)' : 'white'}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
    }
`;

export const TitleTranscriptWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const ButtonTranscript = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light' ? 'rgb(26, 17, 69)' : '#d8b4fe'};

  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgb(228, 228, 255)'
      : isIframe && iframeTheme.current === 'cintera'
      ? 'rgba(148, 54, 89, 0.5)'
      : '#0b0330'};
  border: 1px solid
    ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.3)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgba(148, 54, 89, 0.3)'
        : 'rgba(168, 85, 247, 0.3)'};
  border-radius: 6px;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: color 0.3s, background-color 0.3s;

  :hover {
    color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light' ? 'rgb(26, 17, 69)' : 'white'};
    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.2)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgba(148, 54, 89, 0.8)'
        : 'rgba(168, 85, 247, 0.2)'};
  }

  :focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px rgba(168, 85, 247, 0.2);
  }

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const TextBlockScroll = styled.div`
  overflow-y: scroll;
  padding-right: 15px;

  @media (max-width: 480px) {
    padding-right: 8px;
  }

  ::-webkit-scrollbar {
    width: 6px; /* ширина scrollbar */
  }

  ::-webkit-scrollbar-track {
    background-color: #1a1145ff; /* цвет дорожки */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: rgb(59 42 126); /* цвет плашки */
  }
`;

export const TextTranscript = styled.span`
  font-size: 14px;
  color: ${({ theme: { iframeTheme, isIframe } }) =>
    isIframe && iframeTheme.current === 'light'
      ? '#1a1145ff'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : '#d8b4fe'};
  white-space: pre-line;
  line-height: 18px;
`;
