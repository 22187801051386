import styled from 'styled-components';
import Menu from '@mui/material/Menu';

export const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    background-color: #251a56;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    border: 1px solid #62548b;
    width: 200px;

    .MuiMenuItem-root {
      :hover {
        background-color: rgb(98 84 139 / 22%);
      }
    }
  }
`;
