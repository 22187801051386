import {ItemWrapper, StyledList, StyledListItemButton, StyledListItemText} from './FoldersDropDownItem.styled';

import {useState} from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';
import {FolderTree} from '@/types';

export const FoldersDropDownItem = ({
                                      folder,
                                      selectedFolder,
                                      setSelectedFolder,
                                    }: {
  folder: FolderTree;
  selectedFolder: string | null;
  setSelectedFolder: (id: string | null) => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = async (id: string) => {
    setOpen(!open);
    if (!open) {
      setSelectedFolder(id);
    }
  };

  return (
    <ItemWrapper>
      <StyledListItemButton style={{padding: folder.folders.length !== 0 ? '8px 16px 8px 8px' : '8px 16px'}}
                            sx={{
                              '&.Mui-selected': {
                                borderRadius: '11px',
                                backgroundColor: 'rgb(37, 26, 86)',
                                '&:hover': {
                                  backgroundColor: 'rgb(37, 26, 86)',
                                },
                              },
                            }}
                            selected={selectedFolder === folder.publicId}
                            onClick={() => handleClick(folder.publicId)}
                            onDoubleClick={() => setSelectedFolder('')}
      >
        {folder.folders.length !== 0 ? (
          <>
            {open ? <KeyboardArrowDownIcon fontSize="medium"/> :
              <KeyboardArrowRightIcon fontSize="medium"/>}
          </>
        ) : null}
        <StyledListItemText primary={folder.title}/>
      </StyledListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <StyledList sx={{pl: 4, maxHeight: '100%'}} disablePadding>
          {folder.folders.map((subFolder) => (
            <FoldersDropDownItem
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              key={subFolder.publicId}
              folder={subFolder}
            />
          ))}
        </StyledList>
      </Collapse>
    </ItemWrapper>
  );
};
