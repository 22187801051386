import { api } from '@/api';
import {
  type BaseTimecodesRequest,
} from '@/types';

const PATH = 'videos';

export const timecodesAPI = api.injectEndpoints({
  endpoints: (build) => ({

    updatePartialTimecodes: build.mutation<void, BaseTimecodesRequest>({
      query: ({ videoId, publicId, body }) => ({
        url: `${PATH}/${videoId}/timecodes/${publicId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, { videoId }) => [{ type: 'timecodes', id: videoId }],
    }),

  }),
});

export const {
  useUpdatePartialTimecodesMutation,
} = timecodesAPI;
