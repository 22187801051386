import {Header} from './components/Header';
import {ContentWrapper, Wrapper} from './HomePage.styled';

import {PlusIcon} from 'lucide-react';

import {useGetFoldersQuery, useGetVideosQuery} from '@/api';
import {DeleteFolder, EmptyContent, FullScreenLoader, VideoCard, VideoFragmentCard} from '@/components';
import {FolderCard} from '@/components/Card/FolderCard';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import {useActions, useSearch} from '@/hooks';
import {FoldersWrapper, NotVideoText, VideosWrapper} from '@/styles';
import {Link} from "react-router-dom";

export const HomePage = () => {
  const {openModal} = useActions();
  const {searchVideos, makeSearch, searchQueryValue, isSearchVideosLoading, isAuth} = useSearch();
  const {data: folders, isLoading: foldersLoading} = useGetFoldersQuery(
    {
      params: {
        sorted: 'date',
        count: 4,
      },
    },
    {skip: !isAuth},
  );
  const lastFolders = folders && folders.folders?.length > 4 ? folders.folders.slice(0, 4) : folders?.folders;
  const {data: videos, isLoading: videosLoading} = useGetVideosQuery({});
  const lastVideos = videos && videos?.results.length > 12 ? videos.results.slice(0, 12) : videos?.results;

  const renderSearchResults = () => {
    if (!searchQueryValue) {
      return (
        <>
          {foldersLoading || videosLoading || isSearchVideosLoading ? (
            <FullScreenLoader/>
          ) : (
            <>
              {folders?.folders.length !== 0 && (
                <ContentWrapper>
                  <Header
                    buttonText={'Создать'}
                    onClick={() => openModal({active: 'CreateFolder'})}
                    title="Недавние папки"
                  />
                  <FoldersWrapper>
                    {lastFolders?.map((folder) => (
                      <FolderCard folder={folder} to={'/folders/' + folder.publicId} key={folder.publicId + 'folder'}/>
                    ))}
                  </FoldersWrapper>
                </ContentWrapper>
              )}
              {videos?.results.length !== 0 && (
                <ContentWrapper>
                  <Header
                    buttonText={'Добавить'}
                    onClick={() => openModal({active: 'AddVideo'})}
                    title="Недавние видео"
                  />
                  <VideosWrapper>
                    {lastVideos?.map((video) => (
                      <VideoCard key={video.publicId} to={'/videos/' + video.publicId} movie={video}/>
                    ))}
                  </VideosWrapper>
                  <DeleteFolder/>
                </ContentWrapper>
              )}
            </>
          )}
        </>
      );
    }

    return (
      <VideosWrapper>
        {searchVideos &&
          searchVideos.map((fragment) =>
            fragment?.cues.map((cue, i) => (
              <>
                {cue.isVideo ?
                  <VideoCard cue={cue} key={`${fragment.publicId}-${i}`} to={`/videos/${fragment.publicId}`}/>
                  : (
                    <Link to={`/videos/${fragment.publicId}?t=${cue.startsFrom ?? 0}`}
                          key={`${fragment.publicId}-${i}`}>
                      <VideoFragmentCard fragment={cue}/>
                    </Link>
                  )}
              </>
            )),
          )}
      </VideosWrapper>
    );
  };

  if (!folders?.folders && !foldersLoading && !videos?.results && !videosLoading) {
    return (
      <EmptyContent
        onClick={() => openModal({active: 'AddVideo'})}
        text="В вашей библиотеке ещё нет видео"
        buttonText="Добавить"
        description={'Добавьте первое видео, вставив ссылку\nиз Youtube, VK, Kinescope или Rutube'}
        icon={<PlusIcon/>}
      />
    );
  }

  return (
    <Wrapper>
      <SearchInVideoInput placeholder="По видео и фрагментам" getSearch={makeSearch}/>
      {searchVideos && searchVideos.length === 0 && searchQueryValue && !isSearchVideosLoading ? (
        <NotVideoText>Результатов по запросу не найдено</NotVideoText>
      ) : (
        <>{renderSearchResults()}</>
      )}
    </Wrapper>
  );
};
