import { TabsMaterials } from './TabsMaterials/TabsMaterials';
import { VideoInterfaceTabs } from './VideoInterfaceTabs/VideoInterfaceTabs';
import { InfoBlockMaterials, InfoText, Wrapper } from './VideoTabs.styled';

import { useState } from 'react';

type VideoTabsPropsType = {
  transcriptionStatus: string;
  timecodesStatus: string;
  summaryStatus: string;
  quizzStatus: string;
  isIframe?: boolean;
  iframeTheme?: string;
};

export const VideoTabs = ({
  quizzStatus,
  summaryStatus,
  timecodesStatus,
  transcriptionStatus,
  iframeTheme,
  isIframe,
}: VideoTabsPropsType) => {
  const [showInfoTariff, setShowInfoTariff] = useState(false);

  return (
    <Wrapper>
      <TabsMaterials
        quizzStatus={quizzStatus}
        summaryStatus={summaryStatus}
        timecodesStatus={timecodesStatus}
        transcriptionStatus={transcriptionStatus}
        setShowInfoTariff={setShowInfoTariff}
        iframeTheme={iframeTheme}
        isIframe={isIframe}
      />
      {showInfoTariff && (
        <InfoBlockMaterials>
          <InfoText>
            Эти материалы недоступны на вашем тарифе. Попробуйте тариф с расширенными возможностями, чтобы открыть
            таймкоды, конспекты и тесты.
          </InfoText>
        </InfoBlockMaterials>
      )}

      <VideoInterfaceTabs />
    </Wrapper>
  );
};
