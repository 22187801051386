import {EmptyContentFolder} from './components';
import {ContentWrapper, VideoSection} from './FolderPage.styled';

import {Link} from 'react-router-dom';

import {useGetFolderByIdQuery} from '@/api';
import {FullScreenLoader, VideoCard, VideoFragmentCard} from '@/components';
import {FolderCard} from '@/components/Card/FolderCard';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import {useSearch} from '@/hooks';
import {FoldersWrapper, NotVideoText, TitlePage, VideosWrapper} from '@/styles';

export const FolderPage = () => {
  const {searchQueryValue, makeSearch, parentId, searchInFolder, searchInFolderLoading} = useSearch();
  const {data, isLoading, error} = useGetFolderByIdQuery({publicId: parentId || ''}, {skip: !parentId});

  const renderSearchResults = () => {
    if (!searchQueryValue) {
      return (
        <>
          {data?.folders.length !== 0 && (
            <ContentWrapper>
              <TitlePage>{data?.title ?? 'Папки'}</TitlePage>
              <FoldersWrapper>
                {data?.folders?.map((folder) => (
                  <FolderCard key={folder.publicId} to={'/folders/' + folder.publicId} folder={folder}/>
                ))}
              </FoldersWrapper>
            </ContentWrapper>
          )}

          {data?.videos.results.length !== 0 ? (
            <VideoSection>
              <TitlePage>Видео</TitlePage>
              <VideosWrapper>
                {data?.videos.results.map((video) => (
                  <VideoCard key={video.publicId} to={`/videos/${video.publicId}`} movie={video}/>
                ))}
              </VideosWrapper>
            </VideoSection>
          ) : (
            <></>
          )}
        </>
      );
    }

    return (
      <VideosWrapper>
        {searchInFolder &&
          searchInFolder.map((fragment) =>
            fragment.cues.map((cue, i) => (
              <>
                {cue.isVideo ?
                  <VideoCard cue={cue} key={`${fragment.publicId}-${i}`} to={`/videos/${fragment.publicId}`}/>
                  : (
                    <Link to={`/videos/${fragment.publicId}?t=${cue.startsFrom ?? 0}`}
                          key={`${fragment.publicId}-${i}`}>
                      <VideoFragmentCard fragment={cue}/>
                    </Link>
                  )}
              </>
            )),
          )}
      </VideosWrapper>
    );
  };

  if (error) return null;
  if (isLoading) return <FullScreenLoader/>;

  if (!data?.folders.length && !data?.videos.results.length) {
    return <EmptyContentFolder/>;
  }

  return (
    <>
      <SearchInVideoInput placeholder="Какой фрагмент видео ищем в папке?" getSearch={makeSearch}/>
      {searchInFolder && searchInFolder.length === 0 && searchQueryValue && !searchInFolderLoading ? (
        <NotVideoText>Результатов по запросу не найдено</NotVideoText>
      ) : (
        <>{renderSearchResults()}</>
      )}
    </>
  );
};
