import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const FeatureGridFirstStyled = styled.section`
  padding: 96px 0;

  @media screen and (max-width: 768px) {
    padding: 50px 0px;
  }
`;

export const Inner = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 48px;

  font-size: 18px;
  line-height: 28px;
  color: ${theme.colors.text.purple_200};
  text-align: center;
  user-select: none;
`;

export const Title = styled.h2`
  font-size: 48px;
  line-height: 57px;
  color: transparent;
  font-weight: 600;
  background-clip: text;
  user-select: 'none';
  background-image: linear-gradient(to right, #f3e8ff, #ec4899);

  @media screen and (max-width: 1024px) {
    font-size: 38px;
    line-height: 38px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const CardList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
export const BigCardList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 54px;
  justify-content: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 32px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const ListItem = styled(motion.li)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const BulletPoint = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${theme.colors.text.purple_400};

  transition-duration: 300ms;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${ListItem}:hover & {
    background-color: ${theme.colors.text.purple_300};
  }
`;

export const LiText = styled.span`
  font-size: 18px;
  line-height: 28px;
  color: ${theme.colors.text.purple_200};

  transition-duration: 300ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${ListItem}:hover & {
    color: ${theme.colors.text.white_100};
  }
`;
