import {
  ButtonModalTextStyle,
  CanProcessVideoText,
  Container,
  DotItem,
  FooterAddVideo,
  Tarif,
  TarifDetail,
  TarifDetailList,
  TariffsContainerBlock,
  TariffsText,
  TariffsWrapper,
  TitleBlock,
  Wrapper,
} from './Tariffs.styled';

import { MyBalanceBlock } from '../MyBalanceBlock';

import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usersAPI } from '@/api';
import { Button } from '@/components/Shared/Buttons/Button/Button';
import { FormButtonsStyled, theme } from '@/styles';
import { ServiceResponse } from '@/types';
import { VKLinkRegex, youtubeRegex } from '@/utils';

type TariffsProps = {
  saveVideo: (id: string) => void;
  closeHandler: () => void;
  videoName?: string;
  duration: number;
  services: ServiceResponse[];
  videoLinkValue?: string;
  selectedFolder: string | null;
  validLink: string | null;
};

export const Tariffs = ({
  duration,
  services,
  videoName,
  saveVideo,
  closeHandler,
  videoLinkValue,
  selectedFolder,
  validLink,
}: TariffsProps) => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();
  const { data: dataMe } = usersAPI.useGetUserMeQuery();
  const [showBalanceStatus, setShowBalanceStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTariff, setSelectedTariff] = useState<ServiceResponse | null>(null);

  /** @param {number} Стоимость обработки видео */
  const [estimatedCost, setEstimatedCost] = useState(0);

  /** @param {boolean} true - можем обновлять видео */
  const [canProcessVideo, setCanProcessVideo] = useState(false);

  const isTariffWithDisabled = useMemo(() => {
    if (videoLinkValue) {
      const isYouTube = youtubeRegex.test(videoLinkValue);
      const isVK = VKLinkRegex.test(videoLinkValue);

      if (isYouTube || isVK) {
        return false;
      } else {
        return true;
      }
    }
    if (videoName) {
      return true;
    }
  }, [videoLinkValue, videoName]);

  const chooseTariff = (tariff: ServiceResponse) => {
    const price = tariff.data.price;

    setSelectedTariff(tariff);

    if (price) {
      const estimatedCost = price * duration;

      const balance = dataMe?.balance ?? 0;
      setEstimatedCost(estimatedCost);
      balance >= estimatedCost ? setCanProcessVideo(true) : setCanProcessVideo(false);
      setShowBalanceStatus(true);
    } else {
      setEstimatedCost(0);
      setCanProcessVideo(true);
      setShowBalanceStatus(false);
    }
  };

  const addBalance = () => {
    if (selectedTariff && !canProcessVideo) {
      navigate('/balance', {
        state: {
          selectedFolder: selectedFolder,
          duration: duration,
          videoName: videoName,
          link: validLink,
        },
      });
      closeHandler();
    } else {
      setIsLoading(true);
      saveVideo(selectedTariff!.publicId);
    }
  };

  return (
    <Container>
      <TariffsContainerBlock>
        {services &&
          services.map((tariff) => {
            const {
              data: { price, features, title },
              publicId,
            } = tariff;

            const disabled = price === 0 && isTariffWithDisabled;

            return (
              <Wrapper
                selected={selectedTariff?.publicId === publicId}
                isDisabled={disabled}
                aria-disabled={disabled}
                key={publicId}
              >
                <TariffsWrapper isDisabled={disabled} onClick={() => chooseTariff(tariff)}>
                  <Tarif>
                    <TitleBlock>
                      <input
                        disabled={disabled}
                        type="radio"
                        name="tariff"
                        style={{ accentColor: `${theme.colors.modal.purple_500}` }}
                      />
                      <TariffsText>{title}</TariffsText>
                    </TitleBlock>
                    <TariffsText>
                      {price} ₽/{t('balance.minut')}
                    </TariffsText>
                  </Tarif>
                  <TarifDetailList>
                    {features.map((feature, idx) => (
                      <TarifDetail selected={selectedTariff?.publicId === publicId} key={idx}>
                        <DotItem />
                        {feature}
                      </TarifDetail>
                    ))}
                  </TarifDetailList>
                </TariffsWrapper>
              </Wrapper>
            );
          })}
      </TariffsContainerBlock>
      <FooterAddVideo>
        <MyBalanceBlock
          selectedTarif={selectedTariff}
          validLink={validLink || videoLinkValue || ''}
          videoName={videoLinkValue || videoName || ''}
          duration={duration}
          selectedFolder={selectedFolder}
          countBalance={dataMe?.balance ?? 0}
          canProcessVideo={canProcessVideo}
          showBalanceStatus={showBalanceStatus}
          onClose={closeHandler}
          estimatedCost={estimatedCost}
        />
        {selectedTariff && !canProcessVideo && (
          <CanProcessVideoText>
            Недостаточно средств для обработки видео. Пополните баланс или выберите другой тариф
          </CanProcessVideoText>
        )}
        <FormButtonsStyled>
          <Button onClick={closeHandler} themed="pink" variant="pink">
            <ButtonModalTextStyle>Отмена</ButtonModalTextStyle>
          </Button>
          <Button onClick={addBalance} disabled={!selectedTariff || isLoading} themed="colored" variant="colored">
            <ButtonModalTextStyle>
              {selectedTariff && !canProcessVideo ? 'Пополнить баланс' : 'Продолжить'}
            </ButtonModalTextStyle>
          </Button>
        </FormButtonsStyled>
      </FooterAddVideo>
    </Container>
  );
};
