import {
  AnimateElements,
  AnimationWrapper,
  ButtonSuccessColored,
  ButtonSuccessDefault,
  ButtonsWrapperSuccess,
  CheckIcon,
  CheckWrapper,
  ContainerSuccessPayment,
  Description,
  MotionBlock,
  SuccessPaymentWrapper,
  SumInfoBlock,
  SumInfoBlockText,
  Title,
} from './SuccessPaymentPage.styled';

import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLazyGetTransactionInfoQuery } from '@/api';
import FullScreenLoader from '@/components/Shared/FullScreenLoader/FullScreenLoader';
import { useActions } from '@/hooks';
import { TransactionInfo } from '@/types';

export const SuccessPaymentPage = () => {
  const navigate = useNavigate();
  const { openModal } = useActions();
  const [params] = useSearchParams();
  const transactionId = params.get('transaction_id');
  const titleVideo = params.get('title');
  const selectedPlaylist = params.get('playlist');
  const duration = params.get('duration');
  const linkFile = params.get('link');
  const [transactionInfo, setTransactionInfo] = useState<TransactionInfo>();
  const [isLoading, setIsLoading] = useState(true);
  const [, setError] = useState('');
  const [getTransactionInfo] = useLazyGetTransactionInfoQuery();
  useEffect(() => {
    if (!transactionId || !titleVideo || !selectedPlaylist || !duration || !linkFile) {
      setError('Отсутствуют данные о транзакции');
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await getTransactionInfo({ transaction_public_id: transactionId ?? '' }).unwrap();
        setTransactionInfo(response);
        window.scrollTo(0, 0);
      } catch (err) {
        setError('Ошибка при загрузке данных транзакции');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [transactionId]);

  const openAddVideoModal = () => {
    openModal({ active: 'AddVideo' });
  };

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <SuccessPaymentWrapper>
      <ContainerSuccessPayment>
        <MotionBlock
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <AnimationWrapper initial={{ scale: 0 }} transition={{ duration: 0.7, ease: 'easeOut' }} />

          <CheckWrapper
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 200,
              damping: 15,
              delay: 0.2,
            }}
          >
            <CheckIcon />
          </CheckWrapper>

          <Title initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }}>
            Оплата прошла успешно!
          </Title>

          <SumInfoBlock initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.5 }}>
            <SumInfoBlockText>
              {transactionInfo?.amount} {transactionInfo?.currencySign}
            </SumInfoBlockText>
          </SumInfoBlock>

          <Description
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="text-purple-300 mb-8 max-w-md mx-auto"
          >
            Средства уже зачислены на ваш баланс. Теперь вы можете использовать их для обработки видео.
          </Description>

          <ButtonsWrapperSuccess
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7 }}
          >
            <ButtonSuccessDefault
              onClick={() => navigate('/balance')}
              themed="colored-default"
              variant="colored-default"
            >
              <span style={{ fontWeight: '500', fontSize: '14px' }}>Вернуться к балансу</span>
            </ButtonSuccessDefault>
            <ButtonSuccessColored onClick={openAddVideoModal} themed="colored" variant="colored">
              <span style={{ fontWeight: '500', fontSize: '14px' }}>Добавить видео</span>
              {/*<ArrowRightSuccess />*/}
            </ButtonSuccessColored>
          </ButtonsWrapperSuccess>

          {/* Анимированные частицы */}
          {[...Array(3)].map((_, i) => (
            <AnimateElements
              key={i}
              initial={{ scale: 0, x: 0, y: 0 }}
              animate={{
                scale: [1, 0],
                x: Math.random() * 200 - 100,
                y: Math.random() * -200 - 100,
              }}
              transition={{
                duration: 2,
                delay: i * 0.2 + 0.5,
                repeat: Infinity,
                repeatDelay: 3,
              }}
            />
          ))}
        </MotionBlock>
      </ContainerSuccessPayment>
    </SuccessPaymentWrapper>
  );
};
