import { forwardRef, useImperativeHandle, useRef } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { FileCode, Link2 } from 'lucide-react';

import { DropdownContainer } from '@/components';
import { CopyIcon } from '@/components/SvgIcons/CopyIcon';
import { DocIcon } from '@/components/SvgIcons/DocIcon';
import { IframeIcon } from '@/components/SvgIcons/IframeIcon';
import { PdfIcon } from '@/components/SvgIcons/PdfIcon';
import { TxtIcon } from '@/components/SvgIcons/TxtIcon';
import { useMount } from '@/hooks';
import { useOutsideClick } from '@/hooks/useOutsideClick';

interface VideoInterfaceDropDownProps {
  onClose: () => void;
  isOpened: boolean;
  onIframeCopy: () => void;
  copyLink: () => void;
  copyContent: (content: string) => void;
  exportContent: (type: string) => void;
  isIframe: boolean;
  iframeTheme: string;
}

export const VideoInterfaceDropDownMenu = forwardRef<HTMLUListElement, VideoInterfaceDropDownProps>(
  ({ onClose, isOpened, onIframeCopy, copyLink, copyContent, exportContent, isIframe, iframeTheme }, ref) => {
    const { mounted } = useMount(isOpened);
    const refDropdown = useRef<HTMLUListElement>(null);

    useImperativeHandle(ref, () => refDropdown.current!);

    useOutsideClick({
      ref: refDropdown,
      handler: onClose,
    });

    const listSubheaderStyle = {
      backgroundColor: 'inherit',
      color: isIframe && iframeTheme === 'light' ? 'rgb(26, 17, 69)' : iframeTheme === 'cintera' ? '#E4E4FF' : 'white',
      lineHeight: '35px',
    };
    const listItemStyle = {
      paddingTop: '2px',
      paddingBottom: '2px',
      gap: '10px',
      '&:hover': {
        backgroundColor:
          isIframe && iframeTheme === 'light'
            ? 'rgb(228, 228, 255)'
            : iframeTheme === 'cintera'
            ? 'rgb(92 65 75 / 37%)'
            : 'rgb(59 42 126 / 45%)',
      },
    };

    const listItemTextStyle = {
      color:
        isIframe && iframeTheme === 'light'
          ? 'rgb(26, 17, 69)'
          : iframeTheme === 'cintera'
          ? '#E4E4FF'
          : 'rgb(216, 180, 254)',
    };

    const COPY_ITEMS = [
      {
        icon: <Link2 width={20} height={20} />,
        text: 'Ссылка',
        id: 'link',
        onClick: () => copyLink(),
      },
      {
        icon: <CopyIcon />,
        text: 'Текст',
        id: 'text',
        onClick: () => copyContent('text'),
      },
      {
        icon: <IframeIcon />,
        text: 'Код для встраивания',
        id: 'iframe',
        onClick: () => onIframeCopy(),
      },
      {
        icon: <FileCode width={20} height={20} />,
        text: 'HTML код',
        id: 'html',
        onClick: () => copyContent('html'),
      },
    ];

    const DOWNLOAD_ITEMS = [
      {
        icon: (
          <TxtIcon
            color={
              isIframe && iframeTheme === 'light'
                ? 'rgb(26, 17, 69)'
                : iframeTheme === 'cintera'
                ? '#E4E4FF'
                : 'rgb(228, 228, 255)'
            }
          />
        ),
        text: 'Txt',
        id: 'txt',
        onClick: () => exportContent('txt'),
      },
      {
        icon: (
          <DocIcon
            color={
              isIframe && iframeTheme === 'light'
                ? 'rgb(26, 17, 69)'
                : iframeTheme === 'cintera'
                ? '#E4E4FF'
                : 'rgb(228, 228, 255)'
            }
          />
        ),
        text: 'Doc',
        id: 'docx',
        onClick: () => exportContent('docx'),
      },
      {
        icon: (
          <PdfIcon
            color={
              isIframe && iframeTheme === 'light'
                ? 'rgb(26, 17, 69)'
                : iframeTheme === 'cintera'
                ? '#E4E4FF'
                : 'rgb(228, 228, 255)'
            }
          />
        ),
        text: 'PDF',
        id: 'pdf',
        onClick: () => exportContent('pdf'),
      },
    ];

    if (mounted) {
      return (
        <DropdownContainer sx={{ paddingTop: '3px', paddingBottom: '3px' }} ref={refDropdown}>
          {!isIframe && <ListSubheader sx={listSubheaderStyle}>КОПИРОВАТЬ</ListSubheader>}
          {!isIframe &&
            COPY_ITEMS.map((item) => (
              <ListItem key={item.id} sx={listItemStyle} onClick={item.onClick}>
                {item.icon}
                <ListItemText sx={listItemTextStyle} id={item.id} primary={item.text} />
              </ListItem>
            ))}
          <ListSubheader sx={listSubheaderStyle}>СКАЧАТЬ</ListSubheader>
          {DOWNLOAD_ITEMS.map((item) => (
            <ListItem key={item.id} sx={listItemStyle} onClick={item.onClick}>
              {item.icon}
              <ListItemText sx={listItemTextStyle} id={item.id} primary={item.text} />
            </ListItem>
          ))}
        </DropdownContainer>
      );
    }
    return null;
  },
);

VideoInterfaceDropDownMenu.displayName = 'VideoInterfaceDropDownMenu';
