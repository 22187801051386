import { Bonus, BonusIndicator, BonusLabel, BonusWrapper, Container, SliderContainer } from './PriceRange.styled';

import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { BonuseResponse } from '@/types';

export const PriceRange = ({
  bonuses,
  setPaymentAmount,
  paymentAmount,
}: {
  bonuses: BonuseResponse[];
  paymentAmount: number;
  setPaymentAmount: (value: number) => void;
}) => {
  const handleSliderChange = (e: Event, newValue: number | number[]) => {
    e && setPaymentAmount(Array.isArray(newValue) ? newValue[0] : newValue);
  };

  const getMarks = useMemo(
    () =>
      bonuses
        .map(({ data: { minCount, currencySign, maxCount } }, index, arr) =>
          index === arr.length - 1
            ? [
                { value: minCount, label: `${minCount} ${currencySign}` },
                { value: maxCount, label: `${maxCount} ${currencySign}` },
              ]
            : { value: minCount, label: `${minCount} ${currencySign}` },
        )
        .flat(),
    [bonuses],
  );

  return (
    <Container>
      <div>
        <SliderContainer initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}>
          <div>
            <BonusWrapper>
              {bonuses.map((bonus, index) => {
                return (
                  <>
                    {bonus.data.bonusPercent !== 0 && (
                      <Bonus
                        key={bonus.publicId}
                        initial={{ opacity: 0, y: 0 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.2 }}
                      >
                        <BonusLabel bonus={index}>+{bonus.data.bonusPercent}%</BonusLabel>
                        <BonusIndicator bonus={index} />
                      </Bonus>
                    )}
                  </>
                );
              })}
            </BonusWrapper>

            <Box sx={{ width: '100%' }}>
              <Slider
                value={paymentAmount}
                onChange={handleSliderChange}
                min={100}
                max={bonuses?.length > 0 ? bonuses[bonuses.length - 1].data.maxCount : 0}
                step={100}
                marks={getMarks}
                sx={{
                  '& .MuiSlider-track': {
                    background: 'linear-gradient(90deg, #9333ea, #ec4899)',
                    height: '10px',
                  },
                  '& .MuiSlider-thumb': {
                    background: 'linear-gradient(90deg, #9333ea, #ec4899)',
                    height: '24px',
                    width: '24px',
                  },
                  '& .MuiSlider-rail': {
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: '#8161ff',
                  },
                  '& .MuiSlider-mark': {
                    height: 0,
                  },
                  '& .MuiSlider-markLabel': {
                    color: 'rgb(216, 180, 254)',
                    fontSize: '16px',

                    marginTop: '35px',
                  },
                }}
              />
            </Box>
          </div>
        </SliderContainer>
      </div>
    </Container>
  );
};
