import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
