import styled from "styled-components";

export const FormButtonsStyled = styled.div`
display: flex;
gap: 10px;
align-items: center;
justify-content: space-between;
padding-top: 16px;

> button {
  flex: 1;
}

@media screen and (max-width: 768px) {
  position: sticky;
  bottom: 0;
  z-index: 999;
  padding: 8px 0;
}
`;