import { DropDownMenuProps } from './DropDownMenu.props';

import styled from 'styled-components';

export const Wrapper = styled.div<Pick<DropDownMenuProps, 'open'>>`
  position: absolute;
  top: 100%;
  right: 0;
  padding: 16px 0;
  min-width: 280px;
  background: ${({ theme: { isIframe, colors } }) => (isIframe ? 'rgb(228, 228, 255)' : colors.blue.blue_5)};
  box-shadow: 0 0 24px rgba(31, 30, 254, 0.2);
  border-radius: 10px;
  z-index: 1;
  transform: translateY(8px);
  animation: ${(props) => (props.open ? 'visible' : 'hide')} 0.3s forwards;

  @keyframes visible {
    0% {
      opacity: 0;
      top: 50%;
      display: none;
    }
    100% {
      opacity: 1;
      top: 100%;
      display: block;
    }
  }
  @keyframes hide {
    100% {
      opacity: 0;
      top: 50%;
      display: none;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: transparent;
  transition: background 0.3s ease;

  :hover {
    background: ${({ theme: { isIframe, iframeTheme, colors } }) =>
      isIframe && iframeTheme.current === 'light' ? 'rgba(190, 190, 241, 0.64)' : colors.blue.blue_20};
  }

  :active {
    background: ${({ theme: { isIframe, iframeTheme, colors } }) =>
      isIframe && iframeTheme.current === 'light' ? 'rgb(228, 228, 255)' : colors.blue.blue_55};
  }
`;
