import { useTranslation } from 'react-i18next';

import FullScreenLoader from '@/components/Shared/FullScreenLoader/FullScreenLoader';
import { ContentTitle } from '@/pages/BalancePage/BalancePage.styled';
import {
  Container,
  ContentCardBlockHistory,
  CountTitleHistory,
  CountTitleHistoryRed,
  ScrollBlockHistory,
  TitleHistory,
} from '@/pages/BalancePage/HistoryTab/HistoryTab.styled';
import { TitleDesc } from '@/pages/BalancePage/TariffsTab/TarifItem/TarifItem.styled';
import { OrdersResponse } from '@/types';

export const HistoryTab = ({ history, isLoading }: { history: OrdersResponse[]; isLoading: boolean }) => {
  const { t } = useTranslation('pages');

  return (
    <Container style={{ minHeight: '500px' }}>
      {isLoading && <FullScreenLoader />}
      <div style={{ marginBottom: '23px' }}>
        <ContentTitle>
          {t('balance.history_title')}
          {/*<Info />*/}
        </ContentTitle>
        <TitleDesc>{t('balance.history_info')}</TitleDesc>
      </div>
      <ScrollBlockHistory>
        {history &&
          history.length !== 0 &&
          history.map((item, index) => {
            return (
              <>
                {item.data.type === 'DEPOSIT' && item.data.amount > 0 && (
                  <ContentCardBlockHistory
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <div>
                      <TitleDesc>{item.data.created.slice(0, 10)}</TitleDesc>
                      <TitleHistory>{t('balance.buy_plus')}</TitleHistory>
                    </div>
                    <CountTitleHistory>
                      +{item.data.amount} {item.data.currencySign}
                    </CountTitleHistory>
                  </ContentCardBlockHistory>
                )}
                {item.data.type === 'WITHDRAWAL' && item.data.amount > 0 && (
                  <ContentCardBlockHistory
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <div>
                      <TitleDesc>{item.data.created.slice(0, 10)}</TitleDesc>
                      <TitleHistory>{t('balance.buy_minus')}</TitleHistory>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <CountTitleHistoryRed>
                        -{item.data.amount} {item.data.currencySign}
                      </CountTitleHistoryRed>
                    </div>
                  </ContentCardBlockHistory>
                )}
              </>
            );
          })}
      </ScrollBlockHistory>
    </Container>
  );
};
