export const TxtIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16308_13442)">
        <path
          d="M2 15H3V16H2C1.4375 15.9792 0.96875 15.7812 0.59375 15.4062C0.21875 15.0312 0.0208333 14.5625 0 14V2C0.0208333 1.4375 0.21875 0.96875 0.59375 0.59375C0.96875 0.21875 1.4375 0.0208333 2 0H6.875C7.29167 0 7.64583 0.145833 7.9375 0.4375L11.5625 4.0625C11.8542 4.35417 12 4.70833 12 5.125V9H11V6H7.5C7.08333 5.97917 6.72917 5.83333 6.4375 5.5625C6.16667 5.27083 6.02083 4.91667 6 4.5V1H2C1.70833 1 1.46875 1.09375 1.28125 1.28125C1.09375 1.46875 1 1.70833 1 2V14C1 14.2917 1.09375 14.5312 1.28125 14.7188C1.46875 14.9062 1.70833 15 2 15ZM11 5C10.9583 4.91667 10.9062 4.84375 10.8438 4.78125L7.21875 1.15625C7.15625 1.09375 7.08333 1.05208 7 1.03125V4.5C7.02083 4.8125 7.1875 4.97917 7.5 5H11ZM13 11.5C13.0208 11.1875 13.1875 11.0208 13.5 11H14.5H15.5C15.8125 11.0208 15.9792 11.1875 16 11.5C15.9792 11.8125 15.8125 11.9792 15.5 12H15V15.5C14.9792 15.8125 14.8125 15.9792 14.5 16C14.1875 15.9792 14.0208 15.8125 14 15.5V12H13.5C13.1875 11.9792 13.0208 11.8125 13 11.5Z"
          fill={color}
        ></path>
        <path
          d="M13.5 11C13.1875 11.0208 13.0208 11.1875 13 11.5C13.0208 11.8125 13.1875 11.9792 13.5 12H14V15.5C14.0208 15.8125 14.1875 15.9792 14.5 16C14.8125 15.9792 14.9792 15.8125 15 15.5V12H15.5C15.8125 11.9792 15.9792 11.8125 16 11.5C15.9792 11.1875 15.8125 11.0208 15.5 11H14.5H13.5Z"
          fill={color}
        ></path>
        <path
          d="M5 11C4.6875 11.0208 4.5208 11.1875 4.5 11.5C4.5208 11.8125 4.6875 11.9792 5 12H5.5V15.5C5.5208 15.8125 5.6875 15.9792 6 16C6.3125 15.9792 6.4792 15.8125 6.5 15.5V12H7C7.3125 11.9792 7.4792 11.8125 7.5 11.5C7.4792 11.1875 7.3125 11.0208 7 11H6H5Z"
          fill={color}
        ></path>
        <path
          d="M10.1875 12.125C10.0625 11.9375 10 11.7292 10 11.5C9.97917 11.1875 9.8125 11.0209 9.5 11C9.1875 11.0209 9.02084 11.1875 9 11.5C9 11.9375 9.125 12.3334 9.375 12.6875L9.90625 13.5L9.375 14.3125C9.125 14.6667 9 15.0625 9 15.5C9.02084 15.7917 9.1875 15.9584 9.5 16C9.8125 15.9584 9.97917 15.7917 10 15.5C10 15.2709 10.0625 15.0625 10.1875 14.875L10.5 14.4063L10.8125 14.875C10.9375 15.0625 11 15.2709 11 15.5C11.0208 15.8125 11.1875 15.9792 11.5 16C11.8125 15.9792 11.9792 15.8125 12 15.5C12 15.0625 11.875 14.6667 11.625 14.3125L11.0938 13.5L11.625 12.6875C11.875 12.3334 12 11.9375 12 11.5C11.9792 11.1875 11.8125 11.0209 11.5 11C11.1875 11.0209 11.0208 11.1875 11 11.5C11 11.7292 10.9375 11.9375 10.8125 12.125L10.5 12.5938L10.1875 12.125Z"
          fill={color}
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16308_13442">
          <rect width="16" height="16" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
