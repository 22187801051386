import { InputProps } from './MyInput.props';
import { ErrorText, Input, Label, MyInputWrapper, MyLabelText, PasswordIconWrapper } from './MyInput.styled';

import EyeHiddenIcon from '../../SvgIcons/EyeHiddenIcon';
import EyeShowIcon from '../../SvgIcons/EyeShowIcon';

import { forwardRef, useReducer } from 'react';

import { useIframe } from '@/hooks';

const MyInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      error,
      onChange,
      name,
      label,
      children,
      icon,
      type,
      required = false,
      showErrorMessage = true,
      ...props
    },
    ref,
  ) => {
    const [showPassword, toggle] = useReducer((prev: boolean) => !prev, false);

    const { isIframe, iframeTheme } = useIframe();

    return (
      <MyInputWrapper error={error} className={className}>
        <Label htmlFor={name} itemType={type}>
          {label && (
            <MyLabelText required={required} error={error}>
              {label}
            </MyLabelText>
          )}
          <Input
            {...props}
            name={name}
            id={name}
            type={showPassword ? 'text' : type}
            ref={ref}
            error={error}
            isIframe={isIframe}
            iframeTheme={iframeTheme}
            onChange={onChange}
          />
          {children}
          {icon}
          {type === 'password' && (
            <PasswordIconWrapper onClick={toggle}>
              {showPassword ? <EyeShowIcon /> : <EyeHiddenIcon />}
            </PasswordIconWrapper>
          )}
        </Label>
        {error && showErrorMessage && <ErrorText>{error.message}</ErrorText>}
      </MyInputWrapper>
    );
  },
);
MyInput.displayName = 'MyInput';

export default MyInput;
