import styled from 'styled-components';

import { LabelText, SecondaryText } from '@/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
`;

export const SecondaryTextErrorFormat = styled(SecondaryText)`
  font-size: 13px;
  line-height: 16px;
  color: #000046;
`;

export const VideoFileErrorBlock = styled.div`
  background-color: #ef7a80;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextAddVideo = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
`;

export const TextCenter = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  width: max-content;
  align-self: center;
  padding-bottom: 8px;
`;

export const InputIcon = styled.img<{ isError?: boolean }>`
  cursor: pointer;
  position: absolute;
  right: 3%;
  top: ${({ isError }) => (!isError ? '35%' : '33%')};
`;

export const ButtonModalTextStyle = styled.span`
  font-size: 14px;
  color: white;
  line-height: 24px;
  font-weight: normal;
`;

export const LabelTextPurple = styled(LabelText)`
  font-size: 15px;
  font-weight: normal;
  color: #d8b4fe;
  text-align: center;
  margin-bottom: 5px;
`;

export const AddVideoWrapper = styled.div<{ videoLinkValue?: string }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 196px;
  height: max-content;
  justify-content: center;
  align-items: center;
  border: 1px dashed #792d9c;
  border-radius: 12px;
  background-color: #1a1145;

  padding: 30px;
  cursor: ${({ videoLinkValue }) => (videoLinkValue !== '' ? 'default' : 'pointer')};
  opacity: ${({ videoLinkValue }) => (videoLinkValue !== '' ? 0.5 : '')};

  &:hover {
    border: ${({ videoLinkValue }) => (videoLinkValue !== '' ? '1px dashed #792d9c' : '1px dashed #b03ee5')};
  }
`;

export const AddVideoText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;

  @media (max-width: 1028px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const AddVideoText1 = styled(AddVideoText)<{ videoLinkValue?: string }>`
  cursor: ${({ videoLinkValue }) => (videoLinkValue !== '' ? 'default' : 'pointer')};
  position: relative;
  background-image: linear-gradient(to right, #792d9c, #d33e8b);
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
`;

export const SelectedFileWrapper = styled.div`
  background-color: #a855f71a;
  width: 100%;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #251a56;
  border-radius: 20px;
`;

export const TextBlock = styled.div`
  position: relative;
  text-align: center;
`;
