import { Box, LinearProgress } from '@mui/material';
import styled from 'styled-components';

import { LabelText } from '@/styles';

export const UploadContainer = styled.div`
  position: relative;
  height: 524px;
  display: flex;
  flex-direction: column;
  background-color: rgb(37, 26, 86);
  padding: 2rem;
  align-items: stretch;
  justify-content: space-between;
  width: 492px;

  @media screen and (max-width: 645px) {
    width: 100%;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 51px;
  margin: 25% auto 0;
  width: 340px;

  @media screen and (max-width: 645px) {
    width: 100%;
  }
`;

export const ProgressInfoBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ProgressBox = styled(Box)`
  padding: 1px;
  border-radius: 8px;
  width: 100%;
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const OuterContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
`;

export const GradientBackground = styled.div`
  position: absolute;
  left: -4px;
  top: -4px;
  background: linear-gradient(to right, #792d9c, #d33e8b);
  border-radius: 24px;
  filter: blur(15px);
  animation: pulse 2s infinite;
  width: 84px;
  height: 84px;

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.7;
    }
  }
`;

export const InnerContainer = styled.div`
  position: relative;
  background-color: #251a56;
  border-radius: 24px;
  padding: 16px;
  height: 76px;
  width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LabelTextPurple = styled(LabelText)`
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
`;

export const LabelTextPurple2 = styled(LabelTextPurple)`
  font-size: 16px;
  font-weight: 400;
  color: #bbb6da;
`;

export const LabelTextPurple3 = styled(LabelTextPurple)`
  font-size: 14px;
  font-weight: 400;
  color: #bbb6da;
  display: block;
`;
export const StyledLinearProgress = styled(LinearProgress)(() => ({
  borderRadius: '5px',
  background: '#1a1145 !important',
  height: '10px !important',
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px',
    background: 'linear-gradient(to right, rgba(107, 70, 193) 0%, rgba(255, 105, 180) 100%)',
  },
}));
