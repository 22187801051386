import { TitleTranscript } from '../../TranscriptTab/TranscriptVideo.styled';

import styled, { css } from 'styled-components';

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 75%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const QuestionHeader = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const QuestionBody = styled(QuestionHeader)``;

export const ControlWrapper = styled.div`
  display: flex;
  min-height: 30px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ArrowsWrapper = styled.div`
  display: flex;
  gap: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const QuestionInfo = styled.div`
  padding: 0 12px;
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgb(228, 228, 255)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#2e2e30'
      : 'rgb(37 26 86)'};
  border-radius: 25px;
  height: 24px;
  color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? '#140150'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : '#d8b4fe'};
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding: 5px 8px;
    font-size: 12px;
  }
`;

export const ButtonQuestionTab = styled.button`
  align-items: center;
  background: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'linear-gradient(to right, #9333ea, #ec4899)'
      : isIframe && iframeTheme.current === 'cintera'
      ? 'linear-gradient(to right, rgb(148, 54, 89), rgb(236 72 153 / 37%))'
      : 'linear-gradient(to right, #9333ea, #ec4899)'};
  font-weight: 500;
  font-size: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1rem;
  height: 48px;
  color: white;
  border-radius: 10px;
  cursor: pointer;

  @media screen and (max-width: 568px) {
    height: auto;
  }

  :hover {
    background: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'linear-gradient(to right, #6b21a8, #be185d)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'linear-gradient(to right, rgb(236 72 153 / 73%), rgb(236 72 153 / 40%))'
        : ' linear-gradient(to right, #6b21a8, #be185d)'};
  }

  :disabled {
    background: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'linear-gradient(to right, #9333ea, #ec4899)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'linear-gradient(to right, rgb(148, 54, 89), rgb(236 72 153 / 37%))'
        : 'linear-gradient(to right, #9333ea, #ec4899)'};
    opacity: 0.5;
  }
`;

export const QuestionTitle = styled(TitleTranscript)`
  line-height: 22px;
  font-weight: 700;
  white-space: normal;
  color: ${({ theme: { isIframe, iframeTheme } }) => isIframe && iframeTheme.current === 'light' && 'rgb(26, 17, 69)'};

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const IncorrectInfo = styled.div`
  padding: 16px;
  border-radius: 12px;
  background-color: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.3);
  color: #f87171;
`;

export const IncorrectInfoText1 = styled.p`
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 8px;
`;
export const IncorrectInfoText2 = styled(IncorrectInfoText1)`
  font-size: 14px;
  line-height: 1.25rem;
  margin-bottom: 0;
`;

export const QuestionBlock = styled.label<{
  answerType?: 'correct' | 'incorrect';
  disabled: boolean;
}>`
  border-radius: 0.75rem;
  border: 1px solid
    ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.3)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgba(148, 54, 89, 0.3)'
        : 'rgba(168, 85, 247, 0.3)'};
  padding: 16px;
  width: 100%;
  justify-content: start;
  margin-bottom: 0;
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light' ? 'transparent' : 'rgb(37 26 86)'};
  color: ${({ theme: { isIframe, iframeTheme } }) => isIframe && iframeTheme.current === 'light' && 'rgb(26 17 69)'};

  cursor: pointer;
  display: flex;
  gap: 10px;

  @media screen and (max-width: 480px) {
    padding: 12px;
  }

  ${({ answerType }) => {
    switch (answerType) {
      case 'correct':
        return css`
          border-color: rgb(34 197 94) !important;
          background-color: rgb(34 197 94 / 0.1) !important;
        `;
      case 'incorrect':
        return css`
          border-color: rgb(239 68 68) !important;
          background-color: rgb(239 68 68 / 0.1) !important;
        `;
      default:
        return css`
          border: 1px solid
            ${({ theme: { isIframe, iframeTheme } }) =>
              isIframe && iframeTheme.current === 'light'
                ? 'rgba(168, 85, 247, 0.3)'
                : isIframe && iframeTheme.current === 'cintera'
                ? 'rgba(148, 54, 89, 0.3)'
                : 'rgba(168, 85, 247, 0.3)'};
          background-color: transparent;
        `;
    }
  }}
  &:hover {
    transition: all 200ms ease-in-out;
    border: 1px solid
      ${({ theme: { isIframe, iframeTheme } }) =>
        isIframe && iframeTheme.current === 'light'
          ? 'rgb(168 85 247)'
          : isIframe && iframeTheme.current === 'cintera'
          ? 'rgba(148, 54, 89, 0.3)'
          : 'rgb(168 85 247)'};
    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgb(228, 228, 255)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgb(92 65 75 / 37%)'
        : 'rgb(26 17 69)'};
  }

  ${({ disabled, answerType }) =>
    disabled &&
    answerType === undefined &&
    css`
      cursor: auto;

      &:hover {
        border: 1px solid
          ${({ theme: { isIframe, iframeTheme } }) =>
            isIframe && iframeTheme.current === 'light'
              ? 'rgba(168, 85, 247, 0.3)'
              : isIframe && iframeTheme.current === 'cintera'
              ? 'rgba(148, 54, 89, 0.3)'
              : 'rgba(168, 85, 247, 0.3)'};
        background-color: ${({ theme: { isIframe } }) => (isIframe ? 'transparent' : 'rgb(37 26 86)')};
      }
    `}
`;
