import { Description, StepContainer } from './Steps.styled';

import { Count, IconWrapper, Text } from '../ReferalProgramPage.styled';

import { Copy } from 'lucide-react';

import type { StepItem } from '../ReferalProgramPage';

interface StepsProps {
  stepItem: StepItem;
  onClick?: () => void;
}

export const Steps = ({ stepItem: { text, step, description }, ...props }: StepsProps) => {
  return (
    <StepContainer initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: step * 0.1 }}>
      <IconWrapper>
        <Count style={{ fontWeight: 900 }}>{step}</Count>
      </IconWrapper>
      <Text>{text}</Text>
      {description && (
        <Description step={step}>
          <Text onClick={step === 1 ? props.onClick : undefined} style={{ color: step === 1 ? '#C01DFF' : '#BBB6DA' }}>
            {description}
          </Text>
          {step === 1 && <Copy color="#C01DFF" width={16} height={16} />}
        </Description>
      )}
    </StepContainer>
  );
};
