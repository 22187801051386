import { EditModeContext } from "./EditModeContext";

import { useContext } from "react";

export const useEditModeContext = () => {
  const context = useContext(EditModeContext);
  if (!context) {
    throw new Error('useEditModeContext must be used within an EditModeContextProvider');
  }
  return context;
};