import { useCallback } from 'react';

import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { useActions, useAppSelector, useHandlingError, useIframe } from '@/hooks';

export const useVideos = () => {
  const { isIframe, iframeTheme, iframeLang, vendorId, showTest } = useIframe();
  const { openModal } = useActions();
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const [params, setParams] = useSearchParams();
  const searchQueryValue = params.get('search');
  const { catchError } = useHandlingError();
  const location = useLocation();
  const { playlistId } = useParams();

  const [
    getSearchVideosInPlaylist,
    { data: searchVideosInPlaylist, isLoading: isSearchInPlaylistLoading, error: searchInPlaylistError },
  ] = playlistsAPI.useLazyGetFullSearchInPlaylistQuery();

  const handleSearch = async (query: string, selectedPlaylist: string | null) => {
    try {
      const searchQuery = query || params.get('search') || '';
      if (selectedPlaylist) {
        await getSearchVideosInPlaylist({ query: searchQuery, publicId: selectedPlaylist });
      } else {
        setParams(searchQuery ? { search: searchQuery } : {});
      }
    } catch (error) {
      catchError(error);
    }
  };

  const makeSearch = useCallback(
    (query: string, selectedPlaylist: string | null) => {
      handleSearch(query, selectedPlaylist);
    },
    [handleSearch],
  );

  const createRoute = (id: string, startsFrom: number) => {
    return `${location.pathname}/videos/${id}?t=${startsFrom}${
      isIframe ? `&vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}&show_test=${showTest}` : ''
    }`;
  };

  return {
    isIframe,
    isAuth,
    openModal,
    params,
    setParams,
    searchQueryValue,
    catchError,
    vendorId,
    showTest,
    iframeLang,
    iframeTheme,
    searchVideosInPlaylist,
    getSearchVideosInPlaylist,
    makeSearch,
    handleSearch,
    isSearchInPlaylistLoading,
    searchInPlaylistError,
    playlistId,
    location,
    createRoute,
  };
};
