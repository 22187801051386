import { Inner } from '../Modal';

import styled from 'styled-components';
import { Info } from '@/components/Shared/Modals/Registration/Registration.styled';

export const Form = styled.form``;

export const InnerAuth = styled(Inner)`
  flex-direction: column;

  width: 100vw;
  max-width: 100%;

  @media (min-width: 768px) {
    width: 480px;
    border-radius: 10px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 149px;
  gap: 10px;
  margin-top: 20px;

  @media (max-width: 620px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
  }
`;

export const ToRegistrationButton = styled.div`
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  gap: 8px;

  @media screen and (max-width: 350px) {
    flex-direction: column;
    margin: 20px auto 0;
  }
`;

export const LoginInfo = styled(Info)`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
`;

export const GradientInfoText = styled.span`
  background: linear-gradient(to right, #792d9c, #d33e8b);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  align-self: flex-start;
  cursor: pointer;
  width: max-content;

  :hover {
    opacity: 0.8;
  }
`;
