import { TimecodesForm } from './EditTimecodes';
import { TimecodesDescription } from './TimecodesDescription';
import {
  ScrollBlock,
  Text,
  Timecodes,
  TimecodesContentWrap,
  TimecodesFragmentTimeV2,
  TimecodesTitleTab,
  TimeTitle,
  TitleTimecodesWrapper,
} from './TimecodesTab.styled';

import { MATERIAL_POLLING_INTERVAL, useEditModeContext } from '../../../model';
import { VideoCardV2Wrapper } from '../../VideoPage.styled';
import { TitleTranscript } from '../TranscriptTab/TranscriptVideo.styled';

import { useEffect, useMemo, useRef, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { useGetTimecodesQuery } from '@/api';
import { ShareIconWrapper } from '@/components/Card/VideoFragmentCard/VideoFragmentCard.styled';
import { useHandlingError, useIframeHeight } from '@/hooks';
import { StatusType } from '@/types';
import { parseDuration } from '@/utils';

interface TimecodesProps {
  setTime: (time: number | string) => void;
  videoId: string;
  currentTime?: number | null;
  timecodesStatus: StatusType | undefined;
  titleVideo: string;
  isIframe?: boolean;
  iframeTheme?: string;
}

export const TimecodesTab = ({
  setTime,
  videoId,
  currentTime,
  timecodesStatus,
  titleVideo,
  isIframe,
  iframeTheme,
}: TimecodesProps) => {
  const { catchError } = useHandlingError();

  const { isEditMode, toggleEditMode } = useEditModeContext();

  const [shouldPolling, setShouldPolling] = useState(() => timecodesStatus === 'NOT_STARTED');

  const { data, error, isSuccess } = useGetTimecodesQuery(
    { videoId },
    {
      skip: timecodesStatus === 'NOT_ORDERED' || timecodesStatus === 'FAILED',
      pollingInterval: shouldPolling ? MATERIAL_POLLING_INTERVAL : undefined,
    },
  );
  const { sendTimeToParent } = useIframeHeight();

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.timecodes !== undefined) {
      setShouldPolling(false);
    }

    () => setShouldPolling(false);
  }, [data]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const timecodeTitleRef = useRef<HTMLHeadingElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const timings = useMemo(() => data?.timecodes?.map((array) => array.start as number) ?? [], [data]);

  useEffect(() => {
    if (listRef.current) {
      const children = Array.from(listRef.current.children) as HTMLLIElement[];
      children.forEach((child, index) => {
        if (currentTime && Math.round(currentTime) === timings[index]) {
          wrapperRef.current?.scrollTo({ top: child.offsetTop, behavior: 'smooth' });
        }

        if (
          currentTime &&
          currentTime >= timings[index] &&
          (timings[index + 1] === undefined || currentTime < timings[index + 1]) &&
          timecodeTitleRef.current
        ) {
          timecodeTitleRef.current.style.backgroundColor = '#BE9F4B';
        } else {
          timecodeTitleRef.current && (timecodeTitleRef.current.style.backgroundColor = 'transparent');
        }
      });
    }
  }, [currentTime]);

  if (!isSuccess || timecodesStatus === 'NOT_ORDERED') {
    return null;
  }

  if (data.timecodes === undefined) {
    return (
      <>
        <Text> Видео обрабатывается </Text>
        <div style={{ margin: '0 auto' }}>
          <CircularProgress color="secondary" size={40} />
        </div>
      </>
    );
  }

  const isTarget = (index: number) =>
    Boolean(
      currentTime &&
        currentTime >= timings[index] &&
        (timings[index + 1] === undefined || currentTime < timings[index + 1]),
    );

  if (isEditMode) {
    return (
      <VideoCardV2Wrapper>
        <TimecodesForm
          videoId={videoId}
          timecodes={data.timecodes}
          publicId={data.publicId}
          goToTime={setTime}
          onClose={toggleEditMode}
        />
      </VideoCardV2Wrapper>
    );
  }

  return (
    <VideoCardV2Wrapper ref={wrapperRef}>
      {data.timecodes && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', flex: '1' }}>
          {isIframe && iframeTheme !== 'cintera' && (
            <TitleTimecodesWrapper>
              <TitleTranscript>{titleVideo}</TitleTranscript>
            </TitleTimecodesWrapper>
          )}
          <div style={{ position: 'relative', flex: '1' }}>
            <ScrollBlock style={{ inset: '0' }}>
              <Timecodes ref={listRef}>
                {data.timecodes.map(({ start, text, title }, index) => (
                  <TimecodesContentWrap
                    onClick={() => {
                      isIframe && sendTimeToParent(parseDuration(start as string)); // send time to iframe
                      setTime(parseDuration(start as string));
                    }}
                    key={index}
                  >
                    <TimecodesFragmentTimeV2>{start}</TimecodesFragmentTimeV2>
                    <TimeTitle>
                      <TimecodesTitleTab isIframe={isIframe} iframeTheme={iframeTheme} isTarget={isTarget(index)}>
                        {title}
                      </TimecodesTitleTab>
                      <TimecodesDescription text={text} />
                    </TimeTitle>
                    <ShareIconWrapper style={{ maxHeight: 'max-content' }}></ShareIconWrapper>
                  </TimecodesContentWrap>
                ))}
              </Timecodes>
            </ScrollBlock>
          </div>
        </div>
      )}
    </VideoCardV2Wrapper>
  );
};
