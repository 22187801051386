import {VideoGrid} from './AllVideoList.styled';

import {useVideos} from '../../model';

import {useEffect} from 'react';

import {Pagination} from '@mui/material';
import {PlusIcon} from 'lucide-react';

import {useGetMyVideosQuery} from '@/api';
import {EmptyContent, FullScreenLoader, RemoveVideo, VideoCard, VideoFragmentCard} from '@/components';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import {usePagination, useSearch} from '@/hooks';
import {NotVideoText, PaginationWrapper, VideosWrapper} from '@/styles';
import {Link} from "react-router-dom";

export const AllVideoList = () => {
  const {searchVideos, catchError, makeSearch, searchQueryValue, isSearchVideosLoading} = useSearch();
  const {isAuth, isIframe, openModal} = useVideos();
  const {
    pagination: {pages, page},
    handleChangePage,
    setCount,
  } = usePagination();

  const {
    data: videosResponse,
    isLoading: isGetVideosLoading,
    error,
    isSuccess: videoIsSuccess,
  } = useGetMyVideosQuery({params: {page}}, {skip: !isAuth});
  const videos = videosResponse?.results || [];

  useEffect(() => {
    if (videoIsSuccess) {
      setCount(videosResponse.results.length === 0 ? 1 : videosResponse.count);
    } else {
      catchError(error);
    }
  }, [videosResponse, error]);

  const renderSearchResults = () => {
    if (!searchQueryValue) {
      return videos?.map((video, i) => (
        <VideoCard
          key={i}
          movie={video}
          to={`${isIframe ? 'embed' : ''}/videos/${video.publicId}?t=${video.startsFrom ?? 0}`}
        />
      ));
    }

    return (
      <VideosWrapper>
        {searchVideos?.map((fragment) =>
          fragment?.cues.map((cue, i) => (
            cue.isVideo ? (
              <VideoCard
                cue={cue}
                to={`${isIframe ? 'embed' : ''}/videos/${fragment.publicId}`}
                key={`${fragment.publicId}-${i}`}
              />
            ) : (
              <Link
                to={`/videos/${fragment.publicId}?t=${cue.startsFrom ?? 0}`}
                key={`${fragment.publicId}-${i}`}
              >
                <VideoFragmentCard fragment={cue}/>
              </Link>
            )
          ))
        )}
      </VideosWrapper>
    );
  };

  if (!videos?.length && !isGetVideosLoading) {
    return (
      <EmptyContent
        buttonText="Добавить"
        onClick={() => openModal({active: 'AddVideo'})}
        icon={<PlusIcon/>}
        text="В вашей библиотеке ещё нет видео"
        description={`Добавьте первое видео, вставив ссылку\nиз Youtube, VK, Rutube или Kinescope`}
      />
    );
  }

  return (
    <>
      {isGetVideosLoading && <FullScreenLoader/>}
      <SearchInVideoInput placeholder="По видео и фрагментам" getSearch={makeSearch}/>
      {searchVideos && searchVideos.length === 0 && searchQueryValue && !isSearchVideosLoading && (
        <NotVideoText>Результатов по запросу не найдено</NotVideoText>
      )}
      <VideoGrid>{renderSearchResults()}</VideoGrid>

      {!isGetVideosLoading && !searchQueryValue && pages > 1 && (
        <PaginationWrapper>
          <Pagination
            count={pages}
            page={page}
            color="primary"
            onChange={(e, newPage) => handleChangePage(e, newPage)}
          />
        </PaginationWrapper>
      )}
      <RemoveVideo/>
    </>
  );
};
