import styled from 'styled-components';

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
`;

export const BalanceText = styled.h3`
  color: white;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
`;
