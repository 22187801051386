import { theme } from './theme';

import { motion } from 'framer-motion';
import styled from 'styled-components';

export const TitlePage = styled(motion.h1)`
  color: #ffffff;
  font-size: 28px;

  font-weight: 500;

  user-select: none;
`;

export const TitleH1 = styled(motion.h1)`
  font-size: 24px;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 16px;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const TitleH2 = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 45px;
  @media screen and (max-width: 1024px) {
    font-size: 28px;
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const TitleH3 = styled.h3`
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const TitleH4 = styled.h4`
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 480px) {
    font-size: 17px;
  }
`;

export const TitleH5 = styled.h5`
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: ${theme.colors.text.white_100};
`;

export const Text = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
`;

export const TextCenter = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  width: max-content;
  align-self: center;
`;

export const SecondaryText = styled.p`
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;
  color: ${theme.colors.text.white_80};

  @media (max-width: 1028px) {
    font-size: 15px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const ButtonText = styled.span`
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: ${theme.colors.text.white_100};
`;

export const LabelText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.colors.text.white_100};
`;

export const DescriptionText = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: ${theme.colors.text.white_100};
`;

export const FooterText = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
`;

//---------------------------------Select----------------------------------//
export const SelectWrapper = styled.div`
  width: 100%;

  div {
    div {
      &:focus,
      :hover,
      :active {
        outline-color: white;
        //border-color: white !important;
      }
    }
  }

  #selectId,
  #selectAccess,
  #selectMyPlaylists,
  #selectAddVideo {
    background-color: transparent !important;

    cursor: pointer;

    .select__placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: ${theme.colors.text.white_30};
    }

    .select__control {
      background-color: transparent;
      border: 2px solid #62548b;
      border-radius: 10px;
    }

    .select__single-value {
      font-weight: 400;
      font-size: 17px;
      line-height: 140%;
      color: ${theme.colors.text.white_30};
    }

    .select__value-container {
      padding: 12px 16px;
    }

    .select__input-container {
      padding: 12px 10px 14px 16px;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__menu {
      background-color: ${theme.colors.blue.blue_25};
      border-radius: 20px;
      z-index: 5;

      div {
        background-color: ${theme.colors.blue.blue_5};
        color: ${theme.colors.text.white_100};
        font-weight: 700;
        font-size: 17px;
        line-height: 140%;

        ::-webkit-scrollbar {
          width: 10px; /* ширина scrollbar */
        }

        ::-webkit-scrollbar-track {
          background: transparent; /* цвет дорожки */
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 20px; /* закругления плашки */
          background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
        }

        div {
          padding: 15px 10px;
          cursor: pointer;
        }

        :hover {
          background-color: ${theme.colors.blue.blue_dark};
        }
      }
    }
  }
`;

export const LendingCardList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  grid-auto-rows: 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
//-------------------Pagination-------------------------//

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  margin-top: 40px;

  .MuiPaginationItem-root {
    color: ${theme.colors.white.white_100};
  }

  .Mui-selected {
    color: ${theme.colors.white.white_100};
    background: ${({ theme }) =>
      theme.isIframe && theme.iframeTheme.current === 'light'
        ? 'rgb(141, 141, 236) !important'
        : theme.isIframe && theme.iframeTheme.current === 'cintera'
        ? 'rgb(148 54 89 / 0.5) !important'
        : '#17087b !important'};
  }

  .MuiPaginationItem-text {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;

    text-align: center;
    white-space: nowrap;

    color: ${({ theme }) =>
      theme.isIframe && theme.iframeTheme.current === 'light' ? '#251a56' : theme.colors.white.white_100};
  }

  @media screen and (max-width: 1024px) {
    margin-top: 30px;
    padding: 3px;
  }
  @media screen and (max-width: 360px) {
    & button {
      margin: 0;
    }
  }
`;

export const NotVideoText = styled.span`
  display: flex;
  justify-content: center;
  color: ${({ theme }) =>
    theme.isIframe && theme.iframeTheme.current === 'light'
      ? '#251a56'
      : theme.isIframe && theme.iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : 'rgba(216, 180, 254, 0.5)'};
  font-size: 16px;
  margin-top: 15%;
`;

export const VideosWrapper = styled.div`
  display: grid;
  gap: 16px;
  width: 100%;
  height: max-content;
  grid-template-columns: repeat(4, 1fr);
  //grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 726px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FoldersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 726px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FormButtonsStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  > button {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    position: sticky;
    bottom: 0;
    z-index: 999;
    padding: 8px 0;
    flex-direction: column;
    align-items: stretch;

    > button {
      :first-child {
        display: none;
      }
    }
  }
`;
