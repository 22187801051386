import {ContentWrapper, StyledList, StyledListItemText, Title, Wrapper} from './FoldersDropDown.styled';

import {FoldersDropDownItem} from '../FoldersDropDownItem';

import Collapse from '@mui/material/Collapse';

import {FoldersTreeResponse} from '@/types';

export const FoldersDropDown = ({
                                  data,
                                  title,
                                  setSelectedFolder,
                                  selectedFolder,
                                }: {
  data: FoldersTreeResponse[] | undefined;
  title: string;
  setSelectedFolder: (id: string | null) => void;
  selectedFolder: string | null;
}) => {
  return (
    <Wrapper style={{padding: '16px'}}>
      <ContentWrapper style={{overflowY: data?.length === 0 ? 'auto' : 'scroll'}}>
        {data?.length === 0 ? (
          <Title>
            <StyledListItemText primary={'У вас пока нет папок'}/>
          </Title>
        ) : (
          <Title data={!!data}>
            <StyledListItemText primary={title}/>
          </Title>
        )}
        {data && (
          <Collapse in={true} timeout="auto" unmountOnExit>
            <StyledList disablePadding>
              {data?.map((folder) => {
                return (
                  <FoldersDropDownItem
                    setSelectedFolder={setSelectedFolder}
                    selectedFolder={selectedFolder}
                    key={folder.publicId}
                    folder={folder}
                  />
                );
              })}
            </StyledList>
          </Collapse>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
