export const VkSocialIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.489 0.00397656L10.218 0.000976562H13.782L14.512 0.00397656L15.426 0.0139766L15.859 0.0209766L16.277 0.0319766L16.68 0.0459766L17.068 0.0619766L17.442 0.0829766L17.802 0.107977L18.147 0.137977L18.48 0.170977C20.22 0.366977 21.413 0.786977 22.313 1.68698C23.213 2.58698 23.633 3.77898 23.829 5.51998L23.863 5.85298L23.892 6.19898L23.917 6.55898L23.937 6.93198L23.962 7.51998L23.974 7.92998L23.987 8.57398L23.996 9.48898L24 10.469L23.999 13.782L23.996 14.512L23.986 15.426L23.979 15.859L23.968 16.277L23.954 16.68L23.938 17.068L23.917 17.442L23.892 17.802L23.862 18.147L23.829 18.48C23.633 20.22 23.213 21.413 22.313 22.313C21.413 23.213 20.221 23.633 18.48 23.829L18.147 23.863L17.801 23.892L17.441 23.917L17.068 23.937L16.48 23.962L16.07 23.974L15.426 23.987L14.511 23.996L13.531 24L10.218 23.999L9.488 23.996L8.574 23.986L8.141 23.979L7.723 23.968L7.32 23.954L6.932 23.938L6.558 23.917L6.198 23.892L5.853 23.862L5.52 23.829C3.78 23.633 2.587 23.213 1.687 22.313C0.787 21.413 0.367 20.221 0.171 18.48L0.137 18.147L0.108 17.801L0.083 17.441L0.063 17.068L0.038 16.48L0.026 16.07L0.013 15.426L0.004 14.511L0 13.531L0.001 10.218L0.004 9.48798L0.014 8.57398L0.021 8.14098L0.032 7.72298L0.046 7.31998L0.062 6.93198L0.083 6.55798L0.108 6.19798L0.138 5.85298L0.171 5.51998C0.367 3.77998 0.787 2.58698 1.687 1.68698C2.587 0.786977 3.779 0.366977 5.52 0.170977L5.853 0.136977L6.199 0.107977L6.559 0.0829766L6.932 0.0629766L7.52 0.0379766L7.93 0.0259766L8.574 0.0129766L9.489 0.00397656ZM6.79 7.29998H4.05C4.18 13.54 7.3 17.29 12.77 17.29H13.08V13.72C15.09 13.92 16.61 15.39 17.22 17.29H20.06C19.28 14.45 17.23 12.88 15.95 12.28C17.23 11.54 19.03 9.73998 19.46 7.29998H16.88C16.32 9.27998 14.66 11.08 13.08 11.25V7.29998H10.5V14.22C8.9 13.82 6.88 11.88 6.79 7.29998Z"
        fill="#0177FF"/>
      <path
        d="M6.79005 7.30005H4.05005C4.18005 13.54 7.30005 17.2901 12.77 17.2901H13.08V13.72C15.09 13.92 16.61 15.3901 17.22 17.2901H20.06C19.28 14.4501 17.23 12.88 15.95 12.28C17.23 11.54 19.03 9.74005 19.46 7.30005H16.88C16.32 9.28005 14.66 11.08 13.08 11.25V7.30005H10.5V14.22C8.90005 13.82 6.88005 11.88 6.79005 7.30005Z"
        fill="white"/>
    </svg>

  );
};
