import styled from "styled-components";


export const MaterialWrapper = styled.div`
  flex: 1;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 50%;
  overflow: hidden;

  @media (max-width: 1024px) {
    max-width: unset;
    width: 100%;
    gap: 8px;
  }
`;
