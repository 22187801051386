import {
  Container,
  InvitationFriends,
  NotResultsText,
  ReferralCard,
  ReferralHistoryWrapper,
  ScrollWrapper,
  TitleWrapper,
} from './ReferralTab.styled';

import { ContentTitle } from '../BalancePage.styled';
import { TitleDesc } from '../TariffsTab/TarifItem';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FullScreenLoader } from '@/components';
import Button from '@/components/Shared/Buttons/Button/Button';
import { TitleHistory } from '@/pages/BalancePage/HistoryTab/HistoryTab.styled';
import { OrdersResponse } from '@/types';

export const ReferralTab = ({ history, isLoading }: { history: OrdersResponse[]; isLoading: boolean }) => {
  const countReferral = history.find((el) => el.data.type === 'REFERRAL')?.data.amount || 0;
  const navigate = useNavigate();
  const { t } = useTranslation('pages');

  if (!countReferral) {
    return (
      <Container style={{ gap: '80px' }}>
        <NotResultsText>Вы еще не пригласили друзей</NotResultsText>
        <InvitationFriends initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}>
          <Button
            style={{ height: '44px' }}
            onClick={() => navigate('/referral-program')}
            themed="colored"
            variant="colored"
          >
            Пригласить друзей
          </Button>
        </InvitationFriends>
      </Container>
    );
  }

  return (
    <Container>
      {isLoading && <FullScreenLoader />}
      <ReferralHistoryWrapper>
        <TitleWrapper>
          <ContentTitle>Бонусы</ContentTitle>
          <TitleDesc>История бонусов за приведенных пользователей</TitleDesc>
        </TitleWrapper>
        <ScrollWrapper>
          {history &&
            history.length !== 0 &&
            history.map((item, index) => {
              return (
                <>
                  {item.data.type === 'REFERRAL' && item.data.amount > 0 && (
                    <ReferralCard
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <div>
                        <TitleDesc>{item.data.created.slice(0, 10)}</TitleDesc>
                        <TitleHistory>{t('balance.buy_plus')}</TitleHistory>
                      </div>
                      <TitleHistory
                        style={{
                          color: '#14FC95',
                          fontSize: '20px',
                          fontWeight: '600',
                        }}
                      >
                        +{item.data.amount} ₽
                      </TitleHistory>
                    </ReferralCard>
                  )}
                </>
              );
            })}
        </ScrollWrapper>
      </ReferralHistoryWrapper>
      <InvitationFriends initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}>
        <Button
          style={{ height: '44px' }}
          onClick={() => navigate('/referral-program')}
          themed="colored"
          variant="colored"
        >
          Пригласить больше друзей
        </Button>
      </InvitationFriends>
    </Container>
  );
};
