import styled, {css} from 'styled-components';

export const StyledAside = styled.aside<{ isCollapsed?: boolean }>`
    background: #251a5633;
    transition: all 300ms ease-in-out;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const ContentContainer = styled.div<{ isCollapsed?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 98vh;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    gap: 20px;
    position: relative;
    width: inherit;

    ${({isCollapsed}) =>
            isCollapsed &&
            css`
                align-items: center;
                padding: 16px 0;
            `}
`;
export const NavBarContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const AvatarWrapper = styled.div`
    display: flex;
    gap: 15px;
    flex-direction: column;
    position: fixed;
    bottom: 15px;
    max-width: 235px;
    width: inherit;
    z-index: 1;
    padding-right: 15px;
`;
