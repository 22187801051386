export const EditMaterialIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9708 3.46861L15.5917 4.78777C15.7482 4.94026 15.8359 5.14986 15.8346 5.36836C15.8333 5.58685 15.7432 5.79543 15.585 5.94611L14.3533 7.44611L10.1617 12.5603C10.0891 12.6457 9.99139 12.7061 9.88249 12.7328L7.70916 13.2328C7.42078 13.2447 7.1721 13.032 7.13916 12.7453L7.24083 10.6011C7.24836 10.4916 7.2917 10.3877 7.36416 10.3053L11.375 5.41777L12.7875 3.69444C13.0619 3.32089 13.5781 3.22238 13.9708 3.46861Z"
        stroke="#E4E4FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.1665 15.0761C3.75229 15.0761 3.4165 15.4119 3.4165 15.8261C3.4165 16.2403 3.75229 16.5761 4.1665 16.5761V15.0761ZM15.5832 16.5761C15.9974 16.5761 16.3332 16.2403 16.3332 15.8261C16.3332 15.4119 15.9974 15.0761 15.5832 15.0761V16.5761ZM12.0706 5.13707C11.9156 4.75296 11.4785 4.56727 11.0944 4.7223C10.7103 4.87734 10.5246 5.3144 10.6797 5.69851L12.0706 5.13707ZM14.5188 8.18018C14.9228 8.08891 15.1763 7.68738 15.0851 7.28335C14.9938 6.87932 14.5923 6.62578 14.1882 6.71705L14.5188 8.18018ZM4.1665 16.5761H15.5832V15.0761H4.1665V16.5761ZM10.6797 5.69851C10.879 6.19229 11.2871 6.89456 11.8975 7.43741C12.5211 7.99195 13.4207 8.42824 14.5188 8.18018L14.1882 6.71705C13.7038 6.8265 13.2809 6.66029 12.8943 6.3165C12.4945 5.96101 12.2047 5.46911 12.0706 5.13707L10.6797 5.69851Z"
        fill="#E4E4FF"
      />
    </svg>
  );
};
