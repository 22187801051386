import { ButtonsWrapper, Content, Description, Text, TextWrapper, Wrapper } from './EmptyContentFolder.styled';

import { useParams } from 'react-router-dom';

import { ButtonWithIcon } from '@/components/Shared/Buttons';
import { useActions } from '@/hooks';

export const EmptyContentFolder = () => {
  const { openModal } = useActions();
  const { parentId } = useParams();

  return (
    <Wrapper>
      <Content>
        <TextWrapper>
          <Text>{'В этой папке ещё нет видео\nили вложенных папок'}</Text>
          <Description>{'Добавьте первое видео или создайте папку'}</Description>
        </TextWrapper>
        <ButtonsWrapper>
          <ButtonWithIcon
            onClick={() => openModal({ active: 'AddVideo' })}
            icon={<></>}
            buttonText={'Добавить видео'}
          />
          <ButtonWithIcon
            onClick={() => openModal({ active: 'CreateFolder', data: { parentId } })}
            icon={<></>}
            buttonText={'Создать папку'}
          />
        </ButtonsWrapper>
      </Content>
    </Wrapper>
  );
};
