import { Wrapper } from './EditSummaryPage.styled';

//обязательно должен быть для работы кнопок редактора
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { FormButtons } from '../../components';

import { useState } from 'react';

import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';

import { usePatchSummaryMutation } from '@/api';
import { useActions } from '@/hooks';
import { AdminSummary } from '@/types';
import '../styles/customStyles.css';

export const SummaryEditor = ({
  videoId,
  summary,
  onClose,
}: {
  videoId: string;
  summary: AdminSummary;
  onClose: () => void;
  isEmpty?: boolean;
}) => {
  const { t } = useTranslation('pages');
  const { showNotification } = useActions();
  const [updateSummary, { isLoading }] = usePatchSummaryMutation();

  const blocksFromHtml = htmlToDraft(summary.html || '');
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  const [value, setValue] = useState(() => EditorState.createWithContent(contentState));

  const handleSaveSummary = async () => {
    try {
      const content = value.getCurrentContent();

      const body: Partial<AdminSummary> = {};
      if (content.hasText()) {
        const rawObject = convertToRaw(value.getCurrentContent());
        body.html = draftToHtml(rawObject);

        await updateSummary({
          publicId: summary.publicId,
          body,
          videoId,
        }).unwrap();
        showNotification({
          text: t('save_summary', { ns: 'success' }),
          severity: 'success',
        });
        onClose();
      }
    } catch (e) {
      showNotification({
        text: t('save_summary', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  const toolbarOptions = {
    options: ['inline', 'list', 'textAlign', 'history'],
    textAlign: { options: ['left', 'center', 'right', 'justify'] },
  };

  const editorStyle = {
    border: '1px solid #62548B',
    borderRadius: '10px',
    marginTop: '16px',
    height: '500px',
    marginBottom: '0',
  };

  return (
    <Wrapper>
      <Editor
        toolbarClassName="toolbarClassName"
        editorState={value}
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={setValue}
        editorStyle={editorStyle}
        toolbar={toolbarOptions}
      />

      <FormButtons
        disabled={isLoading || !value.getCurrentContent().hasText()}
        onClose={onClose}
        onSubmit={handleSaveSummary}
      />
    </Wrapper>
  );
};
