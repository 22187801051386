import { Separator, Title, StyledLink } from './BreadCrumbs.styled';

import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';

import { useMatchMedia } from '@/hooks';

export const Breadcrumbs = () => {
  const { state } = useLocation();

  const isMobile = useMatchMedia('(max-width: 480px)');

  const updatedState = (key: string) => {
    const stateToArray = Object.entries(state as Record<string, string>);

    const newMapState = new Map();

    for (const [publicId, title] of stateToArray) {
      newMapState.set(publicId, title);
      if (key === publicId) {
        break;
      }
    }

    return Object.fromEntries(newMapState.entries());
  };

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" maxItems={isMobile ? 1 : 4} separator={<Separator>→</Separator>}>
      <StyledLink to="/folders" state={null} color={state ? '#BBB6DA' : undefined}>
        Папки
      </StyledLink>
      {state &&
        Object.entries(state as Record<string, string>).map(([key, value], index, arr) =>
          index !== arr.length - 1 ? (
            <StyledLink key={key} to={`/folders/${key}`} state={updatedState(key)} color="#BBB6DA">
              {value}
            </StyledLink>
          ) : (
            <Title key={key}>{value}</Title>
          ),
        )}
    </MuiBreadcrumbs>
  );
};
