import { InputProps } from './MyInput.props';

import styled, { css } from 'styled-components';

import { theme } from '@/styles';

export const MyInputWrapper = styled.div<Pick<InputProps, 'error' | 'showErrorMessage'>>`
  position: relative;
  ${({ error, showErrorMessage }) =>
    error &&
    showErrorMessage &&
    css`
      margin-bottom: 15px;
    `};
  width: inherit;
  transition: margin 0.3s ease;
`;

export const Label = styled.label<Pick<InputProps, 'required' | 'error'>>`
  display: flex;
  flex-direction: column;

  gap: 9px;

  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  ${(props) => {
    if (props.itemType === 'checkbox') {
      return css`
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        border-radius: 8px;
        transition: all 0.3s ease;

        :hover {
          cursor: pointer;

          span {
            color: ${theme.colors.text.purple_400};
          }
        }
      `;
    }
  }};

  /* cursor: pointer; */
  @media screen and (max-width: 1024px) {
    font-size: 17px;
  }

  @media screen and (max-width: 360px) {
    gap: 3px;
  }
`;

export const Input = styled.input<Pick<InputProps, 'error'>>`
  display: flex;
  height: 40px;
  width: 100%;
  padding: 4px 12px;

  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  background: rgb(37, 26, 86);

  border-radius: 8px;
  color: ${(props) => (props.disabled ? theme.colors.white.white_10 : theme.colors.text.white_100)};

  border-style: solid;
  border-width: 1px;

  border-color: ${(props) => {
    if (props.error) {
      return theme.colors.red.red_light;
    } else if (props.disabled) {
      return theme.colors.border.purple_500_40;
    } else {
      return theme.colors.border.purple_500_30;
    }
  }};

  ${(props) => {
    if (props.type === 'checkbox') {
      return css`
        width: 14px;
        height: 14px;
        background-color: ${theme.colors.border.purple_500};

        &:checked {
          background-color: ${theme.colors.border.purple_500};
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px ${theme.colors.border.purple_500};
        }
      `;
    }
  }};

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  ::placeholder {
    color: ${(props) => (props.disabled ? theme.colors.white.white_30 : theme.colors.text.white_30)};
  }

  :focus,
  :focus-visible {
    border-color: ${theme.colors.border.purple_500};
    outline: none;
  }
`;

export const ErrorText = styled.span`
  position: absolute;
  color: ${theme.colors.red.red_light};
  font-weight: 400;
  display: block;
  margin-top: 3px;
`;
export const PasswordIconWrapper = styled.span`
  position: absolute;
  top: 23%;
  right: 3%;
  cursor: pointer;
`;

export const MyLabelText = styled.span<Pick<InputProps, 'required' | 'error'>>`
  color: ${theme.colors.white.white_100};

  font-size: 14px;
  line-height: 14px;

  ${(props) =>
    props.required &&
    css`
      &:after {
        content: '*';
        padding: 0 2px;
      }
    `}
  ${(props) =>
    props.error &&
    css`
      color: ${theme.colors.red.red_light};
    `}
    transition: color 0.3s ease;
`;
