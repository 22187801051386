import { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';

type ParamsKey =
  | 'popup'
  | 'id'
  | 'page'
  | 'search'
  | 'limit'
  | 'offset'
  | 'search_str'
  | 'token'
  | 'uidb'
  | 'u'
  | 'sortSt'
  | 'accessor'
  | 'theme'
  | 'lang'
  | 'vendor'
  | 'show_test'
  | 't'
  ;

export const useAppSearchParams = () => {
  const [params, setParams] = useSearchParams();

  const getAppParam = useCallback(<Value = string>(key: ParamsKey) => {

    const data = params.get(key);

    if (data) {
      return data as Value;
    }

    return null
  }, [params]);

  const setAppParams = useCallback(
    (key: ParamsKey, value: string) => {
      setParams((params) => {
        params.set(key, value);
        return params;
      })
    },
    [params, setParams],
  );

  const deleteAppParam = useCallback(
    (key: ParamsKey) => {
      params.delete(key);
      setParams({ ...params });
    },
    [params, setParams],
  );

  return { getAppParam, setAppParams, deleteAppParam, params, setParams };
};
