import { Title, TitleContent, TitleWrapper } from './Header.styled';

import { PlusIcon } from 'lucide-react';

import { ArrowRight, ButtonWithIcon } from '@/components';

export const Header = ({ onClick, title, buttonText }: { onClick: () => void; title: string; buttonText: string }) => {
  return (
    <TitleWrapper>
      <TitleContent>
        <Title>{title}</Title>
        <ArrowRight />
      </TitleContent>
      <ButtonWithIcon icon={<PlusIcon />} buttonText={buttonText} onClick={onClick} />
    </TitleWrapper>
  );
};
