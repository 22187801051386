import { PlaylistItem } from '../PlaylistsList.styled';

import styled from 'styled-components';

export const ActionButtons = styled.div`
  display: none;
  gap: 0;

  button {
    display: flex;
    padding: 0.25rem;
    color: rgba(255, 255, 255, 0.6);
    transition: color 0.2s;
  }

  button:nth-of-type(1) {
    &:hover {
      color: rgb(192 132 252);
    }
  }

  button:nth-of-type(2) {
    &:hover {
      color: rgb(244 114 182);
    }
  }

  ${PlaylistItem}:hover & {
    display: flex;
  }
`;
