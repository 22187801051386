export const ReferralIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4014_13249)">
        <path
          d="M19.5003 9.16717H17.8337V7.50051C17.8337 7.27949 17.7459 7.06753 17.5896 6.91125C17.4333 6.75497 17.2213 6.66718 17.0003 6.66718C16.7793 6.66718 16.5673 6.75497 16.4111 6.91125C16.2548 7.06753 16.167 7.27949 16.167 7.50051V9.16717H14.5003C14.2793 9.16717 14.0673 9.25497 13.9111 9.41125C13.7548 9.56753 13.667 9.77949 13.667 10.0005C13.667 10.2215 13.7548 10.4335 13.9111 10.5898C14.0673 10.746 14.2793 10.8338 14.5003 10.8338H16.167V12.5005C16.167 12.7215 16.2548 12.9335 16.4111 13.0898C16.5673 13.246 16.7793 13.3338 17.0003 13.3338C17.2213 13.3338 17.4333 13.246 17.5896 13.0898C17.7459 12.9335 17.8337 12.7215 17.8337 12.5005V10.8338H19.5003C19.7213 10.8338 19.9333 10.746 20.0896 10.5898C20.2458 10.4335 20.3336 10.2215 20.3336 10.0005C20.3336 9.77949 20.2458 9.56753 20.0896 9.41125C19.9333 9.25497 19.7213 9.16717 19.5003 9.16717Z"
          fill="#E4E4FF"
        />
        <path
          d="M7.83398 9.99994C8.82289 9.99994 9.78959 9.7067 10.6118 9.15729C11.4341 8.60788 12.0749 7.82699 12.4534 6.91336C12.8318 5.99973 12.9308 4.99439 12.7379 4.02449C12.545 3.05458 12.0688 2.16367 11.3695 1.46441C10.6703 0.765145 9.77934 0.288941 8.80944 0.0960148C7.83953 -0.0969113 6.8342 0.00210539 5.92057 0.380544C5.00694 0.758982 4.22604 1.39984 3.67664 2.22209C3.12723 3.04434 2.83398 4.01103 2.83398 4.99994C2.83531 6.32562 3.36252 7.59662 4.29991 8.53401C5.23731 9.47141 6.50831 9.99862 7.83398 9.99994ZM7.83398 1.66661C8.49326 1.66661 9.13772 1.8621 9.68589 2.22838C10.234 2.59465 10.6613 3.11524 10.9136 3.72433C11.1659 4.33342 11.2319 5.00364 11.1033 5.65024C10.9747 6.29685 10.6572 6.89079 10.191 7.35696C9.72483 7.82314 9.13089 8.14061 8.48429 8.26923C7.83768 8.39784 7.16746 8.33183 6.55837 8.07954C5.94929 7.82725 5.42869 7.40001 5.06242 6.85184C4.69615 6.30368 4.50065 5.65921 4.50065 4.99994C4.50065 4.11589 4.85184 3.26804 5.47696 2.64292C6.10208 2.0178 6.94993 1.66661 7.83398 1.66661Z"
          fill="#E4E4FF"
        />
        <path
          d="M7.83398 11.6672C5.84554 11.6694 3.93916 12.4603 2.53312 13.8663C1.12707 15.2724 0.33619 17.1787 0.333984 19.1672C0.333984 19.3882 0.421782 19.6002 0.578062 19.7564C0.734342 19.9127 0.946304 20.0005 1.16732 20.0005C1.38833 20.0005 1.60029 19.9127 1.75657 19.7564C1.91285 19.6002 2.00065 19.3882 2.00065 19.1672C2.00065 17.6201 2.61523 16.1363 3.70919 15.0424C4.80316 13.9484 6.28689 13.3338 7.83398 13.3338C9.38108 13.3338 10.8648 13.9484 11.9588 15.0424C13.0527 16.1363 13.6673 17.6201 13.6673 19.1672C13.6673 19.3882 13.7551 19.6002 13.9114 19.7564C14.0677 19.9127 14.2796 20.0005 14.5007 20.0005C14.7217 20.0005 14.9336 19.9127 15.0899 19.7564C15.2462 19.6002 15.334 19.3882 15.334 19.1672C15.3318 17.1787 14.5409 15.2724 13.1348 13.8663C11.7288 12.4603 9.82243 11.6694 7.83398 11.6672Z"
          fill="#E4E4FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4014_13249">
          <rect width="20" height="20" fill="white" transform="translate(0.333984)" />
        </clipPath>
      </defs>
    </svg>
  );
};
