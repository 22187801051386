const QuizIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4076_24706)">
        <path
          d="M18.3808 4.35818L12.2358 1.43068C10.8831 0.622713 9.19953 0.608377 7.83328 1.39318L1.61914 4.35818C1.59582 4.36986 1.57164 4.38236 1.54914 4.39568C0.0744497 5.23889 -0.437504 7.11791 0.4057 8.5926C0.69152 9.09252 1.11195 9.50213 1.61914 9.77486L3.33332 10.5915V14.6749C3.33433 16.5008 4.52242 18.1139 6.26582 18.6565C7.47906 19.0075 8.73707 19.1794 10 19.1665C11.2627 19.1807 12.5207 19.0103 13.7342 18.6607C15.4776 18.1181 16.6657 16.5049 16.6667 14.679V10.5899L18.3334 9.79318V16.6665C18.3334 17.1267 18.7064 17.4998 19.1667 17.4998C19.6269 17.4998 20 17.1267 20 16.6665V6.6665C20.0055 5.68795 19.2329 4.78396 18.3808 4.35818ZM15 14.679C15.0004 15.7712 14.2918 16.7372 13.25 17.0649C12.1935 17.3667 11.0987 17.5133 9.99996 17.4999C8.90125 17.5133 7.80644 17.3667 6.74996 17.0649C5.70812 16.7372 4.99953 15.7712 4.99996 14.679V11.3857L7.76414 12.7024C8.44617 13.1074 9.22511 13.3202 10.0183 13.3182C10.7733 13.3236 11.5156 13.1237 12.1658 12.7399L15 11.3857V14.679ZM17.6666 8.27068L11.3816 11.2707C10.5053 11.781 9.4191 11.7665 8.55664 11.2332L2.40746 8.30818C1.72203 7.93857 1.46601 7.0833 1.83562 6.39791C1.96062 6.16611 2.14804 5.97396 2.37664 5.84318L8.62246 2.85986C9.49906 2.35072 10.5847 2.36514 11.4475 2.89736L17.5925 5.82486C18.0444 6.0758 18.3271 6.54967 18.3333 7.06654C18.3341 7.55615 18.082 8.01146 17.6666 8.27068Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4076_24706">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuizIcon;
