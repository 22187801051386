import { api } from './api';

import {
  BillingListResponse,
  BonuseResponse,
  OrdersResponse,
  PaymentUrlGeneration,
  PromoCodeRequestBody,
  PromocodeResponseType,
  ServiceResponse,
  TransactionInfo,
  TransactionInfoResponse,
} from '@/types';

const PATH = 'billing';

export const billingAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getServices: build.query<ServiceResponse[], void>({
      query: () => ({
        url: `${PATH}/services/`,
        method: 'GET',
      }),
      transformResponse: (response: BillingListResponse<ServiceResponse>) => response.results,
    }),
    getBonuses: build.query<BonuseResponse[], void>({
      query: () => ({
        url: `${PATH}/bonuses/`,
        method: 'GET',
      }),
      transformResponse: (response: BillingListResponse<BonuseResponse>) => response.results,
    }),
    getOrders: build.query<OrdersResponse[], void>({
      query: () => ({
        url: `${PATH}/orders/`,
        method: 'GET',
      }),
      transformResponse: (response: BillingListResponse<OrdersResponse>) => response.results,
    }),
    checkPromoCode: build.mutation<PromocodeResponseType, PromoCodeRequestBody>({
      query: (body) => ({
        url: `${PATH}/check-promocode/`,
        method: 'POST',
        body,
      }),
      transformResponse: (data: BillingListResponse<PromocodeResponseType>) => data.results[0],
    }),

    generatePaymentUrl: build.mutation<{ paymentUrl: string }, PaymentUrlGeneration>({
      query: (body) => ({
        url: `${PATH}/generate-payment-url/`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response: { status: number; data: any }) => {
        if (response.status === 400 && response.data?.errorCode === '101') {
          return {
            message: `Максимальная сумма пополнения в месяц составляет ${response.data.depositLimit} рублей. Доступно к пополнению ${response.data.maxAvailableDeposit} рублей.`,
          };
        }
        return { message: response.data?.detail || 'Произошла ошибка.' };
      },
    }),

    getTransactionInfo: build.query<TransactionInfo, { transaction_public_id: string }>({
      query: (params) => ({
        url: `${PATH}/transaction-info/`,
        method: 'GET',
        params,
      }),
      transformResponse: (data: BillingListResponse<TransactionInfoResponse>) => data.results[0].data,
      providesTags: ['userMe'],
    }),
  }),
});

export const {
  useGetBonusesQuery,
  useGetOrdersQuery,
  useLazyGetTransactionInfoQuery,
  useCheckPromoCodeMutation,
  useGeneratePaymentUrlMutation,
  useLazyGetServicesQuery,
  useGetServicesQuery,
} = billingAPI;
