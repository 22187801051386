import {api} from './api';

import {BaseParams, Folder, FoldersResponse, FoldersTreeResponse, SearchInFolderResponse} from '@/types';
import {getSearchParamFromURL} from '@/utils';

const PATH = 'folders';

export const foldersAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getFolders: build.query<FoldersResponse, { params?: BaseParams & { sorted?: string; count?: number } }>({
      query: ({params}) => ({
        url: `${PATH}/`,
        method: 'GET',
        params,
      }),
      transformErrorResponse: (error) => {
        return {status: error.status};
      },

      providesTags: (result) =>
        result
          ? [
            ...result.folders.map(({publicId: id}) => ({type: 'folders' as const, id})),
            {type: 'folders'},
          ]
          : [{type: 'folders'}],
    }),

    getFolderById: build.query<FoldersResponse, { publicId: string }>({
      query: ({publicId}) => ({
        url: `${PATH}/${publicId}/`,
        method: 'GET',
      }),
      transformErrorResponse: (error) => {
        return {status: error.status};
      },

      providesTags: (_, __, {publicId}) =>
        parent
          ? [
            {type: 'folder', id: publicId},
            {
              type: 'folder',
              id: 'ITEM',
            },
          ]
          : ['folders'],

      // transformResponse: (data: GetList<Folders>) => data.results,
    }),

    getFoldersTree: build.query<FoldersTreeResponse[], void>({
      query: () => ({
        url: `${PATH}/tree/`,
        method: 'GET',
      }),

      providesTags: ['folders'],
      // transformResponse: (data: FoldersTreeResponse[]) => ({folders: data}),
    }),

    createNewFolder: build.mutation<Folder, { title: string; parent?: string }>({
      query: ({title, parent}) => ({
        url: `${PATH}/`,
        method: 'POST',
        body: {title, parent},
      }),
      transformErrorResponse: (error) => {
        return {status: error.status};
      },
      invalidatesTags: (_, __, {parent}) =>
        parent
          ? [
            {type: 'folder', id: parent},
            {
              type: 'folder',
              id: 'ITEM',
            },
          ]
          : ['folders'],
    }),

    moveFolder: build.mutation<Folder, { publicId: string; parentId?: string | null }>({
      query: ({publicId, parentId}) => ({
        url: `${PATH}/${publicId}/move/`,
        method: 'PATCH',
        body: {parentId},
      }),
      invalidatesTags: (_, __, {parentId}) =>
        parentId
          ? [
            {type: 'folder', id: parentId},
            {
              type: 'folder',
              id: 'ITEM',
            },
            {type: 'folders'},
          ]
          : ['folders'],
    }),

    getSearchInFolders: build.query<SearchInFolderResponse[], { query?: string | null; publicId: string }>({
      query: ({query = '', publicId}) => ({
        url: `${PATH}/${publicId}/full_search/`,
        method: 'GET',
        params: {
          query,
        },
      }),
      providesTags: (result) =>
        result ? result.map(({publicId: id}) => ({type: 'searchInFolder' as const, id})) : ['searchInFolder'],
      transformResponse: (data: SearchInFolderResponse[]) => {
        // const dataWithCues = data.filter((video) => video.cues.length > 0);
        return data.map((video) => ({
          ...video,
          cues: video.cues.map((cue) => ({
            ...cue,
            timestampLink: getSearchParamFromURL(String(cue.startsFrom), 't'),
          })),
        }));
      },
    }),

    updateFolderById: build.mutation<Folder, { publicId: string; title: string; parentId?: string | null }>({
      query: ({publicId, title}) => ({
        url: `${PATH}/${publicId}/`,
        method: 'PATCH',
        body: {title},
      }),
      invalidatesTags: (_, __, {parentId}) => ['folders', {type: 'folder', id: parentId ?? 'ITEM'}],
      // transformResponse: (data: GetList<Folders>) => data.results,
    }),

    deleteFolderById: build.mutation<void, { publicId: string; parentId?: string | null }>({
      query: ({publicId}) => ({
        url: `${PATH}/${publicId}/`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => {
        return {status: error.status};
      },

      invalidatesTags: (_, __, {parentId}) => ['folders', {type: 'folder', id: parentId ?? 'ITEM'}],

      // transformResponse: (data: GetList<Folders>) => data.results,
    }),
  }),
});

export const {
  useGetFoldersQuery,
  useCreateNewFolderMutation,
  useGetFolderByIdQuery,
  useUpdateFolderByIdMutation,
  useDeleteFolderByIdMutation,
  useLazyGetFolderByIdQuery,
  useGetSearchInFoldersQuery,
  useMoveFolderMutation,
  useGetFoldersTreeQuery,
} = foldersAPI;
