import { Complete } from './Complete';
import { EditQuizForm } from './EditQuizzies';
import { QuestionPage } from './QuestionPage/QuestionPage';
import { QuestionInfo } from './QuestionPage/QuestionPage.styled';

import { MATERIAL_POLLING_INTERVAL, useEditModeContext } from '../../../model';
import { ContentWrap, VideoCardV2Wrapper } from '../../VideoPage.styled';
import { Text } from '../TimecodesTab/TimecodesTab.styled';
import { TitleTranscript, TitleTranscriptWrapper } from '../TranscriptTab/TranscriptVideo.styled';

import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import { useGetVideoAllQuizzesQuery } from '@/api';
import { useAppSelector, useHandlingError, useIframeHeight } from '@/hooks';
import { StatusType } from '@/types';

interface QuizProps {
  goToTime: (time: number | string) => void;
  videoId: string;
  isVideoFile?: boolean;
  playlistType?: string;
  quizStatus?: StatusType | undefined;
  isIframe?: boolean;
}

export const QuizTab = ({ goToTime, videoId, quizStatus, isIframe }: QuizProps) => {
  const { t } = useTranslation('components');
  const { isEditMode, toggleEditMode } = useEditModeContext();
  const { sendTimeToParent } = useIframeHeight();
  const [shouldPolling, setShouldPolling] = useState(() => quizStatus === 'NOT_STARTED');

  const { data, error, isSuccess } = useGetVideoAllQuizzesQuery(
    { videoId },
    {
      skip: quizStatus === 'NOT_ORDERED' || quizStatus === 'FAILED',
      pollingInterval: shouldPolling ? MATERIAL_POLLING_INTERVAL : undefined,
    },
  );
  const { catchError } = useHandlingError();

  const [activeQuestionIndex, questions, done] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
    state.quiz.done,
  ]);

  useEffect(() => {
    catchError(error);
  }, [error]);

  useEffect(() => {
    if (data) {
      setShouldPolling(false);
    }

    () => setShouldPolling(false);
  }, [data]);

  if (!isSuccess) {
    return null;
  }

  if (!data) {
    return (
      <>
        <Text> Видео обрабатывается </Text>
        <div style={{ margin: '0 auto' }}>
          <CircularProgress color="secondary" size={40} />
        </div>
      </>
    );
  }

  if (isEditMode) {
    return (
      <VideoCardV2Wrapper>
        <EditQuizForm defaultValues={data} goToTime={goToTime} videoId={videoId} onClose={toggleEditMode} />
      </VideoCardV2Wrapper>
    );
  }

  return (
    <VideoCardV2Wrapper>
      <TitleTranscriptWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TitleTranscript>{t('title_quiz')}</TitleTranscript>
          <QuestionInfo>{`${t('quizPage.question_quiz', {
            ns: 'pages',
            activeQuestionIndex: activeQuestionIndex + 1,
            questionsLength: questions.length,
          })}`}</QuestionInfo>
        </div>
      </TitleTranscriptWrapper>

      <ContentWrap>
        {/*{isLoading && (*/}
        {/*  <div style={{ margin: '0 auto' }}>*/}
        {/*    <CircularProgress color="secondary" size={40} />*/}
        {/*  </div>*/}
        {/*)}*/}
        {data.data.length > 0 && !done && (
          <QuestionPage
            {...questions[activeQuestionIndex]}
            goToTime={() => {
              isIframe && sendTimeToParent(questions[activeQuestionIndex].start as number); // send time to iframe
              goToTime(questions[activeQuestionIndex].start as number);
            }}
          />
        )}
        {done && <Complete />}
      </ContentWrap>
    </VideoCardV2Wrapper>
  );
};
