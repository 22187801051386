import { EditWrapper, TranscriptFormStyled, TranscriptWrapper } from './EditTranscriptPage.styled';
import { TitleTime } from './TitleAndTime';

import { FormButtons } from '../../components';

import { useMemo, useRef } from 'react';

import { useVirtualizer } from '@tanstack/react-virtual';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { usePatchTranscriptsMutation } from '@/api';
import { Button, FullScreenLoader } from '@/components';
import PlusBigIconBlack from '@/components/SvgIcons/PlusBigIconBlack';
import { useActions, useHandlingError } from '@/hooks';
import { Transcript, TranscriptCueToForm } from '@/types';
import { formatDuration, parseDuration } from '@/utils';

export const TranscriptsForm = ({
  transcript,
  goToTime,
  onClose,
  videoId,
}: {
  videoId: string;
  transcript: Transcript;
  goToTime?: (time: number) => void;
  onClose: () => void;
}) => {
  const { showNotification } = useActions();
  const [updateTranscript, { isLoading: isUpdating, error }] = usePatchTranscriptsMutation();

  const { catchError } = useHandlingError();

  const { data, publicId } = transcript;

  const memoCues: TranscriptCueToForm[] = useMemo(
    () => data.cues.map((it) => ({ ...it, start: formatDuration(Math.round(it.start as number)) })),
    [data],
  );

  const methods = useForm<{ data: TranscriptCueToForm[] }>({ defaultValues: { data: memoCues } });
  const onFormSubmit = async ({ data }: { data: TranscriptCueToForm[] }) => {
    try {
      await updateTranscript({
        videoId,
        publicId,
        body: {
          data: {
            cues: data.map((it) => ({ ...it, start: parseDuration(it.start) })),
          },
        },
      }).unwrap();

      showNotification({
        text: 'Изменения сохранены',
        severity: 'success',
      });
      onClose();
    } catch (e) {
      catchError(error, 'Ошибка! Изменения не сохранены');
    }
  };

  const { fields, insert, remove } = useFieldArray<{ data: TranscriptCueToForm[] }, 'data', 'key'>({
    control: methods.control,
    name: 'data',
    keyName: 'key',
  });

  const parentRef = useRef(null);
  console.log(fields);

  const cuesVirtualizer = useVirtualizer({
    count: fields.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 121,
    overscan: 10,
    gap: 16,
  });

  const items = cuesVirtualizer.getVirtualItems();

  const handleAddNewTranscript = (index: number) => {
    insert(index + 1, { start: '', text: '', durationMs: 0 });
  };
  return (
    <FormProvider {...methods}>
      <EditWrapper>
        <TranscriptFormStyled onSubmit={methods.handleSubmit(onFormSubmit)}>
          {isUpdating && <FullScreenLoader />}
          <TranscriptWrapper ref={parentRef}>
            <div
              style={{
                height: cuesVirtualizer.getTotalSize(),
                width: '100%',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                  transform: `translateY(${items[0]?.start ?? 0}px)`,
                }}
              >
                {cuesVirtualizer.getVirtualItems().map((virtualItem) => (
                  <div key={virtualItem.key} style={{ position: 'relative' }}>
                    <TitleTime index={virtualItem.index} remove={() => remove(virtualItem.index)} goToTime={goToTime} />
                    <Button
                      style={{
                        position: 'absolute',
                        padding: '0',
                        background: '#3B2A7E',
                        border: 'none',

                        transform: 'translate(-50%,-25%)',
                        left: '50%',
                        height: '36px',
                        width: '44px',
                        zIndex: 1,
                      }}
                      onClick={() => handleAddNewTranscript(virtualItem.index)}
                      disabled={isUpdating}
                    >
                      <PlusBigIconBlack width={20} height={20} />
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </TranscriptWrapper>
          <FormButtons
            onClose={onClose}
            disabled={
              isUpdating || transcript.data.cues.length === 0 || (methods.formState.errors.data?.length || 0) > 0
            }
          />
        </TranscriptFormStyled>
      </EditWrapper>
    </FormProvider>
  );
};
