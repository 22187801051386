import { LinkDescription, TimecodesDescriptionText } from './TimecodesDescription.styled';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

export const TimecodesDescription = ({ text }: { text: string }) => {
  const { t } = useTranslation('components');
  const [isCollapsedText, setIsCollapsedText] = useState(true);

  const onReadMoreClick = () => {
    setIsCollapsedText((p) => !p);
  };

  return (
    <div>
      <TimecodesDescriptionText>{isCollapsedText ? `${text.slice(0, 100)}...` : text}</TimecodesDescriptionText>
      <LinkDescription onClick={onReadMoreClick}>
        {isCollapsedText ? t('timecodes_desc_more') : t('timecodes_button')}
      </LinkDescription>
    </div>
  );
};
