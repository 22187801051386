import { Gmail } from '@/components/SvgIcons/Gmail';
import { Telegram } from '@/components/SvgIcons/Telegram';
import { Watsapp } from '@/components/SvgIcons/Watsapp';
import { MessengerIconWrapper, MessengersWrapper } from '@/pages/ReferalProgramPage/ShareButtons/ShareButtons.styled';

export const ShareButtons = ({ referralLink }: { referralLink: string }) => {
  const handleShare = (messenger: string) => {
    const link = encodeURIComponent(referralLink);
    const message =
      'Попробуйте ViSaver — сервис, который делает работу с видео удобнее: быстро находите нужные моменты, получайте конспекты, таймкоды и тесты. Регистрируйтесь по моей ссылке и получите дополнительные 100 ₽ на баланс.';
    const text = encodeURIComponent(`${message}\n\n`);
    if (messenger === 'watsapp') {
      const encodedText = encodeURIComponent(`${message}\n\n${link}`);
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedText}`;
      window.open(whatsappUrl, '_blank');
    }
    if (messenger === 'telegram') {
      window.open(`https://t.me/share/url?text=${text}&url=${link}`, '_blank');
    }
    if (messenger === 'gmail') {
      window.open(`mailto:?body=${message + ' ' + link}`);
    }
  };

  return (
    <MessengersWrapper>
      <MessengerIconWrapper>
        <Watsapp onClick={() => handleShare('watsapp')} />
      </MessengerIconWrapper>
      <MessengerIconWrapper style={{ paddingRight: '3px' }}>
        <Telegram onClick={() => handleShare('telegram')} />
      </MessengerIconWrapper>
      <MessengerIconWrapper>
        <Gmail onClick={() => handleShare('gmail')} />
      </MessengerIconWrapper>
    </MessengersWrapper>
  );
};
