import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const FeaturesGridSecondStyled = styled.section`
  padding: 96px 0;

  @media screen and (max-width: 768px) {
    padding: 50px 0px;
  }
`;

export const TitleWrapper = styled(motion.div)`
  margin-bottom: 48px;
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 48px;
  line-height: 57px;
  color: transparent;
  font-weight: 600;
  background-clip: text;
  user-select: 'none';
  background-image: linear-gradient(to right, #f3e8ff, #ec4899);

  @media screen and (max-width: 1024px) {
    font-size: 38px;
    line-height: 38px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const CategoriesList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 32px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CategoriesCard = styled(motion.div)`
  display: flex;
  flex-direction: row;
  gap: 24px;

  justify-content: flex-start;
`;
export const CategoriesCardTitle = styled(motion.h3)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  transition-duration: 300ms;
  transition-property: color;
  color: ${theme.colors.text.white_90};
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${CategoriesCard}:hover & {
    color: ${theme.colors.text.white_100};
  }
`;
export const CategoriesCardDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.text.purple_300};

  transition-duration: 300ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${CategoriesCard}:hover & {
    color: ${theme.colors.text.purple_200};
  }
`;

export const IconTopWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
`;

export const IconTopGradient = styled.div`
  position: absolute;
  inset: 0px;
  border-radius: 12px;
  opacity: 0;
  background-image: linear-gradient(
    to bottom right,
    ${theme.colors.text.purple_500_20},
    ${theme.colors.pink.pink_500_20}
  );
  transition-duration: 500ms;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  filter: blur(24px);

  ${CategoriesCard}:hover & {
    opacity: 1;
  }
`;

export const IconGradient = styled.div`
  position: absolute;
  inset: 0px;
  border-radius: 12px;
  background-image: linear-gradient(
    to bottom right,
    ${theme.colors.text.purple_500_10},
    ${theme.colors.pink.pink_500_10},
    ${theme.colors.pink.pink_500_10}
  );
  opacity: 0;
  transition-duration: 500ms;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${CategoriesCard}:hover & {
    opacity: 1;
  }
`;

export const IconDecoration = styled.div`
  position: absolute;
  inset: 0px;

  background-image: radial-gradient(50% 50% at 50% 50%, rgba(124, 58, 237, 0.1), 70%);
  opacity: 0;
  transition-duration: 500ms;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${CategoriesCard}:hover & {
    opacity: 1;
  }
`;

export const IconCard = styled.div`
  position: relative;
  width: 64px;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(37 26 86);
  border-radius: 12px;
  border: 1px solid ${theme.colors.border.purple_500_20};

  overflow: hidden;

  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${CategoriesCard}:hover & {
    border-color: ${theme.colors.border.purple_500_40};
  }
`;

export const IconWrapper = styled(motion.div)`
  position: relative;
  z-index: 10;

  svg {
    width: 32px;
    height: 32px;
    color: ${theme.colors.text.purple_400};

    transition-duration: 300ms;
    transition-property: color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    ${CategoriesCard}:hover & {
      color: ${theme.colors.text.purple_300};
    }
  }
`;
