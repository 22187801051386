import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 0 15px;
    display: flex;
    flex-direction: column;
`;

export const PagesList = styled.div`
    border-bottom: 1px solid #62548b;
    padding-top: 24px;

    :first-child {
        padding-top: 0;
    }

    :nth-child(3),
    :nth-child(4) {
        border-bottom: none;
    }
`;

export const PageItem = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    padding-bottom: 24px;
`;
