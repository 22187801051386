import { forwardRef, useImperativeHandle, useRef } from 'react';

import KinescopePlayer from '@kinescope/react-kinescope-player';

export const KinescopePlayerVideo = forwardRef<
  { goToTime: (time: number) => void },
  { id: string; title: string; startsFrom?: number; height: string; setCurrentTime: (time: number) => void }
>(({ id, title, startsFrom = 0, height, setCurrentTime }, ref) => {
  const iframe = useRef<KinescopePlayer>(null);

  const goToTimeFunc = async () => {
    iframe.current?.seekTo(startsFrom);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        goToTime: (time: number) => {
          iframe.current?.seekTo(time);
        },
      };
    },
    [],
  );

  const onTimeUpdate = ({ currentTime }: { currentTime: number }) => {
    setCurrentTime(currentTime);
  };

  return (
    <KinescopePlayer
      videoId={id}
      title={title}
      ref={iframe}
      style={{ width: '100%', height }}
      onTimeUpdate={onTimeUpdate}
      onReady={goToTimeFunc}
      height={height}
      width={'100%'}
    />
  );
});

KinescopePlayerVideo.displayName = 'KinescopePlayerVideo';
