const SummaryIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4076_24703)">
        <path
          d="M18.5 1.80248C18.0315 1.41075 17.4826 1.12697 16.8921 0.971281C16.3016 0.815593 15.684 0.791818 15.0833 0.901644L11.8983 1.47998C11.163 1.61501 10.4944 1.99333 10 2.55414C9.5043 1.99236 8.8338 1.61396 8.09667 1.47998L4.91667 0.901644C4.31596 0.791722 3.69844 0.815185 3.10781 0.970373C2.51718 1.12556 1.96789 1.40868 1.4988 1.79969C1.02972 2.1907 0.652322 2.68004 0.393318 3.23307C0.134315 3.7861 4.0677e-05 4.3893 0 4.99998L0 13.9941C4.78799e-05 14.9698 0.342481 15.9145 0.967611 16.6636C1.59274 17.4127 2.46092 17.9187 3.42083 18.0933L8.65917 19.0458C9.5458 19.2069 10.4542 19.2069 11.3408 19.0458L16.5833 18.0933C17.5425 17.9178 18.4097 17.4115 19.034 16.6625C19.6583 15.9134 20.0001 14.9692 20 13.9941V4.99998C20.0004 4.38951 19.8662 3.78648 19.6069 3.2338C19.3476 2.68113 18.9697 2.19242 18.5 1.80248ZM9.16667 17.44C9.09667 17.43 9.02667 17.4183 8.95667 17.4058L3.71917 16.4541C3.14314 16.3493 2.62216 16.0457 2.24707 15.5961C1.87198 15.1466 1.66658 14.5796 1.66667 13.9941V4.99998C1.66667 4.33694 1.93006 3.70105 2.3989 3.23221C2.86774 2.76337 3.50363 2.49998 4.16667 2.49998C4.31758 2.50034 4.46816 2.514 4.61667 2.54081L7.8 3.12414C8.18301 3.19423 8.52939 3.3962 8.77904 3.69501C9.02868 3.99382 9.16582 4.37061 9.16667 4.75998V17.44ZM18.3333 13.9941C18.3334 14.5796 18.128 15.1466 17.7529 15.5961C17.3778 16.0457 16.8569 16.3493 16.2808 16.4541L11.0433 17.4058C10.9733 17.4183 10.9033 17.43 10.8333 17.44V4.75998C10.8333 4.36965 10.9702 3.99169 11.2203 3.69198C11.4703 3.39228 11.8176 3.18984 12.2017 3.11998L15.3858 2.53664C15.7464 2.47104 16.117 2.48552 16.4714 2.57907C16.8258 2.67262 17.1553 2.84294 17.4365 3.07798C17.7178 3.31302 17.9439 3.60702 18.0988 3.93916C18.2538 4.2713 18.3339 4.63346 18.3333 4.99998V13.9941Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4076_24703">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SummaryIcon;
