import { ErrorTextWrapper, LinkVK, LinkYT, TextError } from './ErrorVideoLinkText.styled';

import { useTranslation } from 'react-i18next';

export const ErrorVideoLinkText = ({
  isError,
  isLinkError,
  videoLinkValue,
}: {
  isError: boolean;
  isLinkError: boolean;
  videoLinkValue: string;
}) => {
  const { t } = useTranslation('modal');

  return (
    <ErrorTextWrapper>
      {isError && videoLinkValue !== '' ? (
        <>
          <TextError>{t('incorrect_video_link', { ns: 'error' })}</TextError>
          <TextError>{t('incorrect_video_link1', { ns: 'error' })}</TextError>
        </>
      ) : null}

      {isLinkError && videoLinkValue !== '' ? (
        <>
          <TextError>{t('incorrect_video_link_tiktok', { ns: 'error' })}</TextError>
          <TextError>
            {t('incorrect_video_link_tiktok1', { ns: 'error' })}
            <LinkVK to={'https://vk.com/video'}>VK, </LinkVK>
            <LinkYT to={'https://www.youtube.com'}>YouTube, </LinkYT>
            <LinkYT to={'https://rutube.ru'}>RuTube, </LinkYT>
            <LinkYT to={'https://kinescope.ru'}>Kinescope</LinkYT>
          </TextError>
        </>
      ) : null}
    </ErrorTextWrapper>
  );
};
