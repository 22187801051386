import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContent = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
`;
