import { ButtonQuestionTab } from '../QuestionPage/QuestionPage.styled';

import styled from 'styled-components';

import { TitleH4 } from '@/styles';

export const CompleteStyled = styled.div`
  display: flex;
  min-height: 300px;
  margin: 0 auto;

  align-items: center;
  justify-content: center;
`;

export const CompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const TitleComplete = styled(TitleH4)`
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgb(26, 17, 69)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : ''};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  > button {
    font-size: 14px;
  }

  @media (max-width: 568px) {
    button {
      width: 100%;
    }
  }
`;

export const ButtonComplete = styled(ButtonQuestionTab)``;
