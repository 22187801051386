import { motion } from 'framer-motion';
import styled from 'styled-components';

import { TitleH1 } from '@/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled(TitleH1)`
  margin-bottom: 5px;
`;

export const Text = styled(motion.p)`
  margin-bottom: 0;
  line-height: 21px;
`;

export const TextRefInfo = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #bbb6da;
`;

export const Count = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #e4e4ff;
`;

export const ContentWrapper = styled(motion.div)`
  padding: 24px;
  border-radius: 20px;
  background-color: #251a56;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ReferralInfoWrapper = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media screen and (max-width: 642px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ReferralInfoBlock = styled.div`
  padding: 24px;
  border-radius: 20px;
  background-color: #110b30;
  display: flex;
  flex: 1;
  gap: 12px;
  width: 100%;
`;

export const IconWrapper = styled.div`
  background-color: #251a56;
  border-radius: 9999px;
  padding: 12px 12px 12px 14px;
  width: 64px;
  height: 64px;
  align-content: center;
  text-align: center;
`;

export const ReferralLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ReferralLink = styled.div`
  display: flex;
  gap: 20px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
