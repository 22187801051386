import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';

export const Wrapper = styled(List)`
  max-width: 100%;
  border-radius: 16px;
  background-color: #1a1145;
  padding: 16px;
  height: 100%;
  max-height: 489px;
`;

export const Title = styled.div<{ data?: boolean | undefined }>`
  border-bottom: ${({ data }) => (!data ? 'none' : '1px solid #62548b')};
  padding-bottom: ${({ data }) => (!data ? '0' : '10px')};
  padding-left: 5px;
`;

export const StyledList = styled(List)``;

export const StyledListItemText = styled(ListItemText)`
  font-weight: 400;
  font-size: 16px;
  color: white;
`;

export const ContentWrapper = styled.div`
  max-height: 458px;
  overflow-y: scroll;
  width: 100%;
  padding-right: 15px;
  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #110b30;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #251a56;
    border-radius: 20px;
  }
`;
