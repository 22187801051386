import { TabItem, Tabs } from './TabsMaterials.styled';

import { useEditModeContext } from '../../../../model';

import { CSSProperties } from 'react';

import { useTranslation } from 'react-i18next';

import { Captions } from '@/components/SvgIcons';
import { Clock } from '@/components/SvgIcons/Clock';
import QuizIcon from '@/components/SvgIcons/QuizIcon';
import SummaryIcon from '@/components/SvgIcons/SummaryIcon';
import { useActions, useAppSelector } from '@/hooks';

type TabsMaterialsPropsType = {
  transcriptionStatus: string;
  timecodesStatus: string;
  summaryStatus: string;
  quizzStatus: string;
  setShowInfoTariff: (value: boolean) => void;
  isIframe?: boolean;
  iframeTheme?: string;
};

export const TabsMaterials = ({
  summaryStatus,
  quizzStatus,
  transcriptionStatus,
  timecodesStatus,
  setShowInfoTariff,
  isIframe,
  iframeTheme,
}: TabsMaterialsPropsType) => {
  const openedMaterialTab = useAppSelector((state) => state.materials.openedMaterialTab);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const { t } = useTranslation('components');
  const { setMaterialTab, setMaterialsCount } = useActions();
  const { isEditMode, toggleEditMode } = useEditModeContext();

  const tabsMaterial = [
    { id: 1, status: transcriptionStatus, icon: <Captions />, label: t('menu.transcript') },
    { id: 2, status: timecodesStatus, icon: <Clock />, label: t('menu.timecodes') },
    { id: 3, status: summaryStatus, icon: <SummaryIcon />, label: t('menu.summary') },
    { id: 4, status: quizzStatus, icon: <QuizIcon />, label: t('menu.quiz') },
  ];

  const onTabClickHandler = (isDisabled: boolean, tab: number) => {
    if (!isDisabled) setMaterialTab(tab);
    if (isEditMode) toggleEditMode();
  };

  const getTabStyles = (isActive: boolean, isDisabled: boolean): CSSProperties => {
    const isLightTheme = isIframe && iframeTheme === 'light';
    const isCinteraTheme = isIframe && iframeTheme === 'cintera';

    return {
      opacity: isDisabled ? 0.5 : 1,
      backgroundColor: isLightTheme
        ? isActive
          ? 'rgb(228, 228, 255)'
          : 'transparent'
        : isCinteraTheme
        ? isActive
          ? 'rgba(148, 54, 89, 0.5)'
          : 'transparent'
        : isActive
        ? '#3B2A7E'
        : 'rgb(26 17 69)',
      color: isLightTheme
        ? isActive
          ? 'rgb(26 17 69)'
          : 'rgb(26 17 69)'
        : isCinteraTheme
        ? isActive
          ? '#E4E4FF'
          : '#94A3B8'
        : isActive
        ? 'white'
        : '#94A3B8',
      display: isIframe && isDisabled ? 'none' : 'flex',
    };
  };

  const onMouseOverHandler = (isDisabled: boolean) => {
    if (isDisabled) {
      setShowInfoTariff(true);
    }
  };

  const notOrderedTabs = () => {
    const count = tabsMaterial.filter((tab) => tab.status !== 'NOT_ORDERED');
    setMaterialsCount(count.length);
    return count;
  };

  const frameTabs = isIframe ? notOrderedTabs() : tabsMaterial;

  return (
    <Tabs tabsCount={isIframe ? notOrderedTabs().length : tabsMaterial.length} isAuth={isAuth}>
      {frameTabs.map((item) => (
        <TabItem
          key={item.id}
          onMouseOver={() => onMouseOverHandler(item.status === 'NOT_ORDERED')}
          onMouseOut={() => setShowInfoTariff(false)}
          onClick={() => onTabClickHandler(item.status === 'NOT_ORDERED', item.id)}
          style={getTabStyles(openedMaterialTab === item.id, item.status === 'NOT_ORDERED')}
        >
          {item.icon}
        </TabItem>
      ))}
    </Tabs>
  );
};
