import {Form, Label} from './RenameFolder.styled';

import {Description, Inner, Modal, Title} from '../Modal';

import {useForm} from 'react-hook-form';

import {useUpdateFolderByIdMutation} from '@/api/foldersApi';
import {useActions, useAppSelector, useHandlingError} from '@/hooks';
import {MyInput} from '@/pages/Lending/ui/Header/AvatarMenu/RegistrationModal/B2CForm/MyInput';
import {Button} from '@/components';
import {FormButtonsStyled} from '@/styles';
import {useTranslation} from 'react-i18next';

export const RenameFolder = () => {
  const {t} = useTranslation();
  const {catchError} = useHandlingError();
  const title = useAppSelector((state) => state.modal.data?.title);
  const [renameFolder, {isLoading}] = useUpdateFolderByIdMutation();
  const publicId = useAppSelector((state) => state.modal.data?.id);
  const {register, handleSubmit, reset, watch} = useForm<{ title: string }>({
    defaultValues: {title: title},
  });
  const titleValue = watch('title', '');
  const isTitleEmpty = !titleValue?.trim();
  const {closeModal, showNotification} = useActions();
  const closeHandler = () => {
    reset();
    closeModal();
  };

  const onSubmit = async (data: { title: string }) => {
    try {
      await renameFolder({publicId: publicId ?? '', ...data}).unwrap();

      reset();
      showNotification({severity: 'success', text: `Папка успешно переименована`});
      closeHandler();
    } catch (error) {
      catchError(error, t('409'));
    }
  };

  return (
    <Modal modal={'RenameFolder'} onClose={() => closeHandler()} closeButton>
      <Title>Переименовать папку</Title>
      <Inner modal={'RenameFolder'}>
        <Form name="rename-folder" onSubmit={handleSubmit(onSubmit)}>
          <Label>
            <Description>Название папки</Description>
            <MyInput defaultValue={title} {...register('title', {required: true})} placeholder="Введите название"/>
          </Label>
          <FormButtonsStyled>
            <Button themed="pink" variant="pink" onClick={closeHandler}>
              Отмена
            </Button>
            <Button width="100%" themed="colored" variant="colored" type="submit" disabled={isLoading || isTitleEmpty}>
              Сохранить
            </Button>
          </FormButtonsStyled>
        </Form>
      </Inner>
    </Modal>
  );
};
