import { Label } from './CreateFolder.styled';

import { Button } from '../../Buttons';
import { Description, Inner, Modal, Title } from '../Modal';

import { useForm } from 'react-hook-form';

import { useCreateNewFolderMutation } from '@/api';
import { useActions, useAppSelector } from '@/hooks';
// убрать
import { MyInput } from '@/pages/Lending/ui/Header/AvatarMenu/RegistrationModal/B2CForm/MyInput';
import { FormButtonsStyled } from '@/styles';

export const CreateFolder = () => {
  const modalData = useAppSelector((state) => state.modal.data);

  const [createFolder, { isLoading }] = useCreateNewFolderMutation();
  const { register, handleSubmit, reset, watch } = useForm<{ title: string }>({
    defaultValues: { title: '' },
  });
  const titleValue = watch('title', '');
  const isTitleEmpty = !titleValue?.trim();
  const { closeModal, showNotification } = useActions();
  const closeHandler = () => {
    reset();
    closeModal();
  };

  const onSubmit = async (data: { title: string }) => {
    try {
      await createFolder({ parent: modalData?.parentId ?? '', ...data }).unwrap();

      reset();
      showNotification({ severity: 'success', text: `Папка "${data.title}" успешно создана` });
      closeHandler();
    } catch (error) {
      showNotification({ severity: 'error', text: 'Произошла ошибка' });
    }
  };

  return (
    <Modal modal={'CreateFolder'} onClose={() => closeHandler()} closeButton>
      <Title>Создать папку</Title>
      <Inner modal={'CreateFolder'}>
        <Description>
          Папки помогают организованно хранить видео. Одно видео можно добавить только в одну папку
        </Description>
        <form
          style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          name="create-folder"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Label>
            <Description>Название папки</Description>
            <MyInput {...register('title', { required: true })} placeholder="Рецепты из авокадо" />
          </Label>
          <FormButtonsStyled>
            <Button themed="pink" variant="pink" onClick={closeHandler}>
              Отмена
            </Button>
            <Button width="100%" themed="colored" variant="colored" type="submit" disabled={isLoading || isTitleEmpty}>
              Создать
            </Button>
          </FormButtonsStyled>
        </form>
      </Inner>
    </Modal>
  );
};
