import { ReactNode } from 'react';

import { DropdownWrapper } from '@/components';
import { useAppSelector } from '@/hooks';

interface VideoDropDownWrapperProps {
  children: ReactNode;
}

export const VideoInterfaceDropDownWrapper = ({ children }: VideoDropDownWrapperProps) => {
  const materialCount = useAppSelector((state) => state.materials.materialsCount);
  return <DropdownWrapper materialCount={materialCount}>{children}</DropdownWrapper>;
};
