import CreditCard from '@mui/icons-material/CreditCard';
import History from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';

import { ReferralIcon } from '@/components/SvgIcons/ReferralIcon';
import { TabsList, TabsTriggerButton } from '@/pages/BalancePage/Tabs/Tabs.styled';

export const Tabs = ({ onTabChange, tab }: { onTabChange: (newTab: number) => void; tab: number }) => {
  const { t } = useTranslation('pages');

  const tabItems = [
    {
      id: 1,
      icon: <CreditCard />,
      translationKey: 'balance.tariffs',
    },
    {
      id: 2,
      icon: <History />,
      translationKey: 'balance.history_buys',
    },
    {
      id: 3,
      icon: <ReferralIcon />,
      translationKey: 'balance.referral',
    },
  ];

  return (
    <TabsList>
      {tabItems.map(({ id, icon, translationKey }) => (
        <TabsTriggerButton isActive={tab === id} key={id} onClick={() => onTabChange(id)}>
          {icon}
          {t(translationKey)}
        </TabsTriggerButton>
      ))}
    </TabsList>
  );
};
