import { Button, Wrapper, WrapperSide } from './RegistrationButton.styled';

import PropTypes from 'prop-types';


import { useAppSearchParams } from '@/hooks';

export const RegistrationButton = ({ label = 'Попробовать' }) => {
  const { setAppParams } = useAppSearchParams();

  return (
    <Wrapper
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        delay: 0.8,
        duration: 0.6,
        ease: [0.21, 0.45, 0.32, 0.9],
      }}
    >
      <WrapperSide />
      <Button onClick={() => setAppParams('popup', 'registration')}>{label}</Button>
    </Wrapper>
  );
};

RegistrationButton.propTypes = {
    label: PropTypes.string,
};
