import Check from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';

export const CheckboxItem = ({ checked, onCheckedChange }: { checked: boolean; onCheckedChange?: () => void }) => {
  return (
    <Checkbox
      id="correctQuizVariant"
      checked={checked}
      onChange={onCheckedChange}
      checkedIcon={<Check sx={{ color: '#FFFFFF' }} />}
      sx={{
        width: '24px',
        height: '24px',
        margin: 0,

        '& .MuiSvgIcon-root': {
          backgroundColor: '#1A1145',
          borderRadius: '8px',
          border: '1px solid #62548B',
        },
        '&.Mui-checked': {
          '& .MuiSvgIcon-root': {
            border: 'none',
            borderRadius: '8px',
            background: 'linear-gradient(to left, rgb(236, 72, 153), rgb(147, 51, 234))',
          },
        },
      }}
    />
  );
};

// <Checkbox
//   {...label}
//   defaultChecked
//   sx={{
//     color: pink[800],
//     '&.Mui-checked': {
//       color: pink[600],
//     },
//   }}
// />
