import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { REFERRAL_CODE } from '@/constants';

export const ReferralRedirectPage = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem(REFERRAL_CODE, code || '');
    navigate('/landing', { replace: true });
  }, []);

  return null;
};
