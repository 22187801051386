import {normalize} from './normalize';
import {theme} from './theme';

import {createGlobalStyle} from 'styled-components';
import {isTablet} from 'react-device-detect';

export default createGlobalStyle`

    body {
        font-family: ${theme.fonts.geist}, sans-serif;
        color: ${theme.colors.text.white_100};
        background-color: ${({theme: {isIframe, iframeTheme}}) =>
                isIframe && iframeTheme.current === 'light'
                        ? '#ffffff'
                        : iframeTheme.current === 'cintera'
                                ? '#1e1f21'
                                : 'rgb(15 10 43)'};

        -webkit-font-smoothing: antialiased;
        background-size: cover;
        hyphens: auto;
        position: relative;

        div[id="root"] {
            background-color: ${({theme: {isIframe, iframeTheme}}) =>
                    isIframe && iframeTheme.current === 'light'
                            ? '#ffffff'
                            : iframeTheme.current === 'cintera'
                                    ? '#1e1f21'
                                    : isTablet
                                            ? '#1a1145'
                                            : 'rgb(15 10 43)'};
            margin: ${({theme: {isIframe}}) => isIframe && '15px'};
            padding-bottom: ${({theme: {isIframe}}) => isIframe && '50px'};
        }

        width: 100%;

        textarea::-webkit-scrollbar {
            width: 5px !important;
        }

        textarea::-webkit-scrollbar-track {
            background-color: #251A56 !important;
            margin: 10px 0 !important;
            border-radius: 20px !important;
        }

        textarea::-webkit-scrollbar-thumb {
            border-radius: 20px !important;
            background-color: rgb(98, 84, 139) !important;
        }

        textarea::-webkit-scrollbar-thumb:hover {
            background-color: rgb(128, 110, 172) !important;
        }

        ::-webkit-scrollbar {
            display: ${({theme: {isIframe}}) => (isIframe ? 'none' : '')};
            width: 6px;
            height: 6px;
        }

        ::-webkit-scrollbar-track {
            background-color: #111; /* цвет дорожки */
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 20px; /* закругления плашки */
            background-color: rgb(59 42 126); /* цвет плашки */
        }
    }

    /* normalize */
    ${normalize}
`;
