import {HeaderStyled, Inner} from './Header.styled';

import {usersAPI} from '@/api';
import {Container} from '@/components';
import Avatar from '@/components/Shared/Avatar';
import {BurgerMenu} from '@/components/SvgIcons/BurgerMenu';
import {useAppSelector} from '@/hooks';
import {useNavigate} from "react-router-dom";
import visaver from "../../../public/images/Visaver_Logo.png";

const Header = ({opened, setOpened}: { setOpened?: (value: boolean) => void, opened: boolean }) => {
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const {data} = usersAPI.useGetUserMeQuery(undefined, {skip: !isAuth});
  const navigate = useNavigate();

  const onClickLogo = () => {
    navigate('/');
    setOpened?.(false);
  };

  return (
    <HeaderStyled>
      <Container>
        <Inner>
          <Avatar auth={isAuth} size="s" url={data?.avatar} name={data?.username}/>

          <div onClick={onClickLogo}>
            <img width={160} height={35} src={visaver as string} alt="VisaverLogo"/>
          </div>

          <BurgerMenu opened={opened} onClick={setOpened}/>
        </Inner>
      </Container>
    </HeaderStyled>
  );
};

export default Header;
