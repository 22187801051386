import { useTranslation } from 'react-i18next';

import { TarifDetailList } from '@/components/Shared/Modals/AddVideo/ui/components/Tariffs/Tariffs.styled';
import CheckIcon from '@/components/SvgIcons/CheckIcon';
import {
  BuyBlock,
  BuyBlockTitle,
  ContentCardBlock,
  ContentTitleBlock,
  ContentTitleCard,
  CountText,
  CountTextPrice,
  TarifDetailWrap,
  Unlimited,
} from '@/pages/BalancePage/TariffsTab/TarifItem/TarifItem.styled';
import { ServiceResponse } from '@/types';

type TarifItemProps = {
  tarif: ServiceResponse;
  paymentAmount: number;
};
export const TarifItem = ({ tarif, paymentAmount }: TarifItemProps) => {
  const { t } = useTranslation('pages');

  // Расчет доступных минут для тарифа с учетом бонусов
  const calculateMinutes = (tariff: ServiceResponse): string | number => {
    if (tariff.data.price === 0) return '∞';
    const amount = Number(paymentAmount);
    const cost = Number(tariff.data.price);
    if (isNaN(amount) || isNaN(cost) || cost === 0) return 0;
    return Math.floor(amount / cost);
  };

  return (
    <ContentCardBlock
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: +tarif.publicId * 0.1 }}
    >
      {tarif.data ? (
        <div>
          <ContentTitleCard>{tarif.data.title}</ContentTitleCard>
          <ContentTitleBlock>
            <CountText style={{ fontSize: '36px' }}>{tarif.data.price}</CountText>
            <CountTextPrice>₽/{t('balance.minut')}</CountTextPrice>
          </ContentTitleBlock>

          <BuyBlock whileHover={{ scale: 1.02 }} transition={{ duration: 0.2 }}>
            {tarif.data.price !== 0 ? (
              <BuyBlockTitle>
                {calculateMinutes(tarif)} {t('balance.minut')}
              </BuyBlockTitle>
            ) : (
              <Unlimited>∞</Unlimited>
            )}
          </BuyBlock>

          <TarifDetailList>
            {tarif.data.features.map((feature, index) => (
              <TarifDetailWrap
                key={index}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 + index * 0.1 }}
              >
                <div style={{ width: '24px', height: '24px' }}>
                  <CheckIcon />
                </div>
                {feature}
              </TarifDetailWrap>
            ))}
          </TarifDetailList>
        </div>
      ) : (
        <div></div>
      )}
    </ContentCardBlock>
  );
};
