import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #62548b;
  border-radius: 16px;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleTimeWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(98, 84, 139);
  position: relative;

  :last-child {
    border-bottom: none;
  }
`;
