import {
  AddVideoText,
  AddVideoText1,
  AddVideoWrapper,
  Form,
  FormButtonsStyled,
  IconWrapper,
  Label,
  LabelTextPurple,
  SelectedFileWrapper,
  TextBlock,
} from './EditVideo.styled';

import Button from '../../../Buttons/Button/Button';
import {Description, Inner, Modal, Title} from '../../Modal';

import {ChangeEvent, DragEventHandler, useRef, useState} from 'react';

import {useForm} from 'react-hook-form';

import {useEditVideoMutation, useLazyGetLinkUploadImageQuery} from '@/api';
import {UploadIcon} from '@/components/Shared/Modals/AddVideo/assets/UploadIcon';
import {useActions, useAppSelector} from '@/hooks';
import {MyInput} from '@/pages/Lending/ui/Header/AvatarMenu/RegistrationModal/B2CForm/MyInput';

export const EditVideo = () => {
  const [editVideo, {isLoading}] = useEditVideoMutation();
  const [getLinkUploadImage] = useLazyGetLinkUploadImageQuery();
  const title = useAppSelector((state) => state.modal.data?.title);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const {closeModal, showNotification} = useActions();
  const id = useAppSelector((state) => state.modal.data?.id);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const {register, handleSubmit, reset, watch} = useForm<{ title: string }>({
    defaultValues: {title: title},
  });
  const titleValue = watch('title', '');
  const isTitleEmpty = !titleValue?.trim();

  const closeHandler = () => {
    reset();
    closeModal();
    setSelectedImage(null);
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    const file = event.dataTransfer?.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (data: { title: string }) => {
    try {
      let thumbnailUrl = null;

      if (selectedImage instanceof File) {
        const blob = new Blob([selectedImage], {type: selectedImage.type});
        const response = await getLinkUploadImage({publicId: id || '', thumbnail_url: blob}).unwrap();
        thumbnailUrl = response.thumbnailUrl;
      }

      await editVideo({
        publicId: id,
        title: data.title,
        ...(thumbnailUrl && {thumbnail_url: thumbnailUrl}),
      }).unwrap();

      reset();
      showNotification({severity: 'success', text: `Изменения сохранены`});
      closeHandler();
    } catch (error) {
      showNotification({severity: 'error', text: 'Произошла ошибка'});
    }
  };

  const handleClose = () => {
    closeModal();
    setSelectedImage(null);
  };

  return (
    <Modal modal={'EditVideo'} onClose={() => handleClose()} closeButton>
      <Title>Редактирование видео</Title>
      <Inner modal={'EditVideo'}>
        <Form name="edit-video" onSubmit={handleSubmit(onSubmit)}>
          <Label>
            <Description>Название видео</Description>
            <MyInput defaultValue={title} {...register('title', {required: true})} placeholder="Введите название"/>
          </Label>

          <AddVideoWrapper
            onClick={() => avatarInputRef.current?.click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <IconWrapper>
              <UploadIcon/>
            </IconWrapper>
            <input
              id="image"
              type="file"
              onChange={onFileChange}
              ref={avatarInputRef}
              hidden
              accept="image/jpg, image/jpeg, image/webp, image/png"
            />
            <TextBlock>
              <AddVideoText1>Выберите изображениe</AddVideoText1>
              <AddVideoText>или перетащите его сюда, чтобы изменить превью</AddVideoText>
            </TextBlock>
            {selectedImage && (
              <SelectedFileWrapper>
                <LabelTextPurple style={{marginBottom: '0px'}}>Выбран: {selectedImage.name}</LabelTextPurple>
              </SelectedFileWrapper>
            )}
          </AddVideoWrapper>

          <FormButtonsStyled>
            <Button themed="pink" variant="pink" onClick={closeHandler}>
              Отмена
            </Button>
            <Button width="100%" themed="colored" variant="colored" type="submit" disabled={isLoading || isTitleEmpty}>
              Сохранить
            </Button>
          </FormButtonsStyled>
        </Form>
      </Inner>
    </Modal>
  );
};
