import styled, { css, keyframes } from 'styled-components';

const marqueeAnimation = keyframes`
    100% {
        transform: translateX(calc(-100% - 16px));
    }
`;

export const MarqueePartnersStyled = styled.div<{ fade: boolean }>`
  position: relative;
  overflow: hidden;
  will-change: transform;
  transform: translate3d(0, 0, 0);

  transition-duration: 30s;

  ${({ fade }) => {
    if (fade) {
      return css`
        mask-image: linear-gradient(to right, transparent, white 10%, white 90%, transparent);
        -webkit-mask-image: linear-gradient(to right, transparent, white 10%, white 90%, transparent);
      `;
    }
  }}
`;

export const MarqueePartnersInner = styled.div<{ pauseOnHover: boolean; duration: string; reverse: boolean }>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  gap: 120px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  min-width: 100%;

  animation: ${marqueeAnimation} 40s linear infinite ${({ reverse }) => (reverse ? 'reverse' : 'normal')};

  animation-play-state: running;

  :hover {
    animation-play-state: ${({ pauseOnHover }) => (pauseOnHover ? 'paused' : 'running')};
  }

  @media screen and (max-width: 768px) {
    gap: 50px;
    animation: ${marqueeAnimation} 20s linear infinite ${({ reverse }) => (reverse ? 'reverse' : 'normal')};
  }
`;
