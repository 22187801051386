import { MaterialWrapper } from './Materials.styled';
import { QuizTab } from './QuizTab';
import { SummaryTab } from './SummaryTab';
import { TimecodesTab } from './TimecodesTab';
import { TranscriptTab } from './TranscriptTab';
import { VideoTabs } from './VideoTabs';

import { useVideoContext } from '../../model';

import { useAppSelector, useIframe } from '@/hooks';

export const Materials = ({
  currentTime,
  goToTime,
}: {
  currentTime: number;
  goToTime: (time: number | string) => void;
}) => {
  const video = useVideoContext();
  const { timecodesStatus, summaryStatus, quizzStatus, transcriptionStatus, publicId, title } = video;
  const openedMaterialTab = useAppSelector((state) => state.materials.openedMaterialTab);
  const { isIframe, iframeTheme } = useIframe();

  const tabsContent = [
    <></>,
    <TranscriptTab
      key="1"
      transcriptStatus={transcriptionStatus}
      videoId={publicId}
      goToTime={(time: string | number) => goToTime(time as number)}
    />,
    <TimecodesTab
      key="2"
      timecodesStatus={timecodesStatus}
      videoId={publicId}
      setTime={goToTime}
      currentTime={currentTime}
      titleVideo={title}
      isIframe={isIframe}
      iframeTheme={iframeTheme}
    />,
    <SummaryTab key="3" summaryStatus={summaryStatus} videoId={publicId} />,
    <QuizTab
      key="4"
      quizStatus={quizzStatus}
      videoId={publicId}
      goToTime={(time: string | number) => goToTime(time as number)}
      isIframe={isIframe}
    />,
  ];

  return (
    <MaterialWrapper>
      <VideoTabs
        transcriptionStatus={transcriptionStatus}
        timecodesStatus={timecodesStatus}
        summaryStatus={summaryStatus}
        quizzStatus={quizzStatus}
        isIframe={isIframe}
        iframeTheme={iframeTheme}
      />
      {tabsContent[openedMaterialTab]}
    </MaterialWrapper>
  );
};
