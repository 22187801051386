import { motion } from 'framer-motion';
import styled from 'styled-components';

import { LendingCardWrapper } from '@/components';
import { theme } from '@/styles';

export const ThreeStepsStyled = styled.section`
  padding: 140px 0;
`;

export const Title = styled.h2`
  font-size: 48px;
  line-height: 57px;
  color: transparent;
  font-weight: 600;
  background-clip: text;
  user-select: none;
  background-image: linear-gradient(to right, #f3e8ff, #ec4899);

  @media screen and (max-width: 1024px) {
    font-size: 38px;
    line-height: 38px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const Inner = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 48px;

  font-size: 18px;
  line-height: 28px;
  color: ${theme.colors.text.purple_200};
  text-align: center;
  user-select: none;
`;

export const LastCardWrapper = styled(motion.div)`
  position: relative;

  user-select: 'none';
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`;

export const CardInner = styled.div`
  position: relative;
  background-color: #251a56;
  border-radius: 16px;
  padding: 32px;
  text-align: center;

  height: 100%;

  background: linear-gradient(to left, #ec4899, #9333ea);
`;

export const CardHeader = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  svg {
    width: 64px;
    height: 64px;
    color: ${theme.colors.white.white_100};
  }
`;

export const LendingCardTitle = styled.h3`
  font-size: 20px;
  line-height: 28px;
  color: ${theme.colors.text.white_90};
  font-weight: 600;
  user-select: 'none';
  margin-bottom: 16px;
  transition-duration: 300ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${LendingCardWrapper}:hover & {
    color: ${theme.colors.text.white_100};
  }
`;

export const CardDescription = styled.p`
  opacity: 0.9;
  font-size: 20px;
  line-height: 28px;
`;

export const ButtonWrapper = styled(motion.div)`
  margin-top: 24px;

  button {
    margin: 0 auto;
    background-color: ${theme.colors.white.white_100};
    border-width: 0px;

    P {
      color: ${theme.colors.text.purple_600};
      font-size: 18px;
      line-height: 28px;
    }

    :active {
      background-color: ${theme.colors.white.white_100};
    }
  }
`;

export const LendingCardSide = styled.div`
  position: absolute;
  background-image: linear-gradient(to right, ${theme.colors.text.purple_500_10}, ${theme.colors.pink.pink_500_10});
  transition-duration: 300ms;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  inset: 0px;
  border-radius: 12px;
  transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(0) scaleY(0);

  filter: blur(24px);

  ${LendingCardWrapper}:hover & {
    opacity: 1;
    transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.05) scaleY(1.05);
  }
`;
