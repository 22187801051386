import {FoldersTreeResponse} from "@/types";

export const filterFolders = (folders: FoldersTreeResponse[], excludeId: string): FoldersTreeResponse[] => {
  if (!folders || !Array.isArray(folders)) return [];
  return folders
    .filter(folder => folder.publicId !== excludeId)
    .map(folder => ({
      ...folder,
      folders: filterFolders(folder.folders, excludeId)
    }))
    .filter(folder =>
      folder.folders.length > 0 ||
      Object.keys(folder).some(k => k !== 'folders')
    );
}