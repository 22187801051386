import styled, {css} from 'styled-components';
import {fadeIn} from "@/styles";

type PropsType = {
  isFixed?: boolean;
  isMobile: boolean;
  isSearch?: boolean;
};

export const Wrapper = styled.div<PropsType>`
    min-height: 100vh;
    display: grid;
    grid-template-columns: 18rem auto;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    ////!!!Modal open to correct view
    ${({isFixed, isMobile}) => {
        if (isFixed) {
            if (isMobile) {
                return css`
                    overflow-y: hidden;
                    padding-right: 0;
                    position: fixed;
                    left: 0;
                    right: 0;
                    /// отключение шивтинга на тач устройствах
                    @media (pointer: coarse) {
                        padding-right: 0;
                    }
                `;
            }
            return css`
                overflow-y: hidden;
                padding-right: 10px;
                position: fixed;
                left: 0;
                right: 0;
                /// отключение шивтинга на тач устройствах
                @media (pointer: coarse) {
                    padding-right: 10px;
                }
            `;
        }
    }} /* ${({isSearch}) =>
            isSearch &&
            css`
                background: url('/images/bg/ellipse-5.png') no-repeat 0% 90%;
            `} */ ${({theme: {isIframe}}) =>
            isIframe &&
            css`
                padding-top: 0;
                min-height: unset;
            `}
`;

export const Main = styled.main`
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;

    @media screen and (max-width: 768px) {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    @media screen and (max-width: 480px) {
        min-height: 100vh;
    }
`;

export const ContentWrapper = styled.div<{ isMenuOpen: boolean }>`
    position: relative;
    display: ${({isMenuOpen}) => (!isMenuOpen ? 'block' : 'none')};;
    animation: ${({isMenuOpen}) => (!isMenuOpen && fadeIn)} 0.3s ease-in-out forwards;
`;
