import styled, { css } from 'styled-components';

export const PlaylistSidebarBottomButtons = styled.div`
  display: flex;
  padding: 8px;
  background: rgb(15, 10, 43);
  position: sticky;
  bottom: 0;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const AddButton = styled.button<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
  background-image: linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153));
  border-radius: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 auto;

  &:hover {
    background-image: linear-gradient(to right, rgb(126, 34, 206), rgb(219, 39, 119));
  }

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          width: 2.5rem;
          height: 2.5rem;
        `
      : css`
          width: 100%;
          gap: 0.5rem;
          padding: 10px 16px;
        `}
`;

export const CollapseButton = styled.button<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 0.5rem;
  margin: 0 auto;

  &:hover {
    color: white;
    background: rgba(255, 255, 255, 0.05);
  }

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          width: 2.5rem;
          height: 2.5rem;
        `
      : css`
          width: 100%;
          padding: 0.5rem;
        `}
`;
