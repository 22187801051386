import { api } from '@/api';
import { BaseTimecodesRequest, GetList, Transcript } from '@/types';

const PATH = 'videos';
export const transcriptsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getTranscripts: build.query<Transcript, { videoId: string }>({
      query: ({ videoId }) => ({
        url: `${PATH}/${videoId}/transcripts/`,
        method: 'GET',
      }),
      transformResponse: (response: GetList<Transcript>) => response.results[0],
      providesTags: (result, _, { videoId }) => (result ? [{ type: 'transcript', id: videoId }] : []),
    }),

    getOneTranscript: build.query<Transcript, Omit<BaseTimecodesRequest, 'body'>>({
      query: ({ publicId, videoId }) => ({
        url: `${PATH}/${videoId}/transcripts/${publicId}/`,
        method: 'GET',
      }),
      providesTags: (result, _, { videoId }) => (result ? [{ type: 'transcript', id: videoId }] : []),
    }),
    putTranscripts: build.mutation<Transcript, BaseTimecodesRequest>({
      query: ({ videoId, publicId, body }) => ({
        url: `${PATH}/${videoId}/transcripts/${publicId}/`,
        method: 'PUT',
        body,
      }),
    }),
    patchTranscripts: build.mutation<void, { videoId: string; publicId: string; body: Pick<Transcript, 'data'> }>({
      query: ({ videoId, publicId, body }) => ({
        url: `${PATH}/${videoId}/transcripts/${publicId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, { videoId }) => [{ type: 'transcript', id: videoId }],
    }),
    // deleteTranscripts: build.mutation<void, Omit<BaseTimecodesRequest, 'body'>>({
    //   query: ({ videoPk, publicId }) => ({
    //     url: `${PATH}/videos/${videoPk}/transcripts/${publicId}/`,
    //     method: 'DELETE',
    //   }),
    // }),
  }),
});

export const {
  useGetTranscriptsQuery,
  useGetOneTranscriptQuery,
  usePutTranscriptsMutation,
  usePatchTranscriptsMutation,
  // useDeleteTranscriptsMutation,
} = transcriptsAPI;
