import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #1a114599;
  border-radius: 20px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
`;

export const Text = styled.h3`
  color: white;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
`;

export const Description = styled.p`
  color: #bbb6da;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  white-space: pre-line;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
`;
