import { Content, Description, Text, TextWrapper, Wrapper } from './EmptyContent.styled';

import { ButtonWithIcon } from '@/components/Shared/Buttons';

export const EmptyContent = ({
  text,
  description,
  buttonText,
  icon,
  onClick,
}: {
  text: string;
  description: string;
  buttonText: string;
  icon?: JSX.Element;
  onClick: () => void;
}) => {
  return (
    <Wrapper>
      <Content>
        <TextWrapper>
          <Text>{text}</Text>
          <Description>{description}</Description>
        </TextWrapper>
        <ButtonWithIcon onClick={onClick} icon={icon ?? <></>} buttonText={buttonText} />
      </Content>
    </Wrapper>
  );
};
