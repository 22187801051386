import { FormButtonsStyled } from './FormButtons.styled';

import { useTranslation } from 'react-i18next';

import { Button } from '@/components';

export const FormButtons = ({
  disabled,
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  disabled: boolean;
  onSubmit?: () => void;
}) => {
  const { t } = useTranslation('pages');
  return (
    <FormButtonsStyled>
      <Button themed="pink" variant="pink" onClick={onClose}>
        Отменить
      </Button>
      <Button width="100%" themed="colored" variant="colored" type="submit" disabled={disabled} onClick={onSubmit}>
        {t('admin.save')}
      </Button>
    </FormButtonsStyled>
  );
};
