import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const LendingCardWrapper = styled(motion.div)`
  position: relative;
`;

export const LendingCardSide = styled.div`
  position: absolute;
  background-image: linear-gradient(to right, ${theme.colors.text.purple_500_10}, ${theme.colors.pink.pink_500_10});
  transition-duration: 300ms;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  inset: 0px;
  border-radius: 12px;
  transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(0) scaleY(0);

  ${LendingCardWrapper}:hover & {
    opacity: 1;
    transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.05) scaleY(1.05);
  }
`;
export const LendingCardInner = styled.div`
  position: relative;
  background-color: #251a56;
  border-radius: 16px;
  padding: 32px;
  text-align: start;

  height: 100%;

  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  border: 1px solid ${theme.colors.border.purple_500_20};

  &:hover {
    border-color: ${theme.colors.border.purple_500_40};
  }

  @media screen and (max-width: 768px) {
    padding: 25px;
  }
`;

export const LendingCardHeader = styled.div<{ iconSize: 'lg' | 'md' | 'sm' }>`
  color: ${theme.colors.text.purple_400_30};
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  svg {
    ${({ iconSize }) => {
      switch (iconSize) {
        case 'lg':
          return 'width: 44px; height: 44px;';
        case 'md':
          return 'width: 32px; height: 32px;';
        default:
          return 'width: 24px; height: 24px;';
      }
    }};

    color: ${theme.colors.text.purple_400};

    transition-duration: 300ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    ${LendingCardWrapper}:hover & {
      transform: scale(1.1);
    }
  }
`;

export const LendingCardTitle = styled.h3`
  font-size: 20px;
  line-height: 28px;
  color: ${theme.colors.text.white_90};
  font-weight: 600;
  user-select: none;
  margin-bottom: 16px;
  transition-duration: 300ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${LendingCardWrapper}:hover & {
    color: ${theme.colors.text.white_100};
  }
`;

export const LendingCardDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.text.purple_300};

  transition-duration: 300ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${LendingCardWrapper}:hover & {
    color: ${theme.colors.text.white_100};
  }
`;
