import styled, { css } from 'styled-components';

export const AddButton = styled.button<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
  background-image: linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153));
  border-radius: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 auto;

  &:hover {
    background-image: linear-gradient(to right, rgb(126, 34, 206), rgb(219, 39, 119));
  }

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          width: 2.5rem;
          height: 2.5rem;
        `
      : css`
          width: 100%;
          gap: 0.5rem;
          padding: 10px 16px;
        `}
`;

export const VideoGrid = styled.div`
  display: grid;
  gap: 16px;
  width: 100%;
  height: max-content;
  grid-template-columns: repeat(4, 1fr);
  //grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 726px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
