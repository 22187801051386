import {Library, LibraryRightSide, TitleWrapper} from './LibraryPage.styled';
import {AllVideoList} from '../ui/AllVideoList';

import {PlusIcon} from 'lucide-react';
import {ButtonWithIcon} from '@/components';
import {useActions, useAppSelector, useMatchMedia} from '@/hooks';
import {ModalType} from '@/store/slices/modalsSlice';
import {TitlePage} from '@/styles';

export const LibraryPage = () => {
  const isAuth = useAppSelector((state) => state.user.isAuth);
  // const { catchError } = useHandlingError();
  // const { playlistId } = useParams();
  const {openModal} = useActions();
  const isMobile = useMatchMedia('(max-width: 768px)');

  // const {
  //   data: playlists,
  //   isLoading,
  //   isSuccess,
  //   error: getPlaylistsError,
  // } = playlistsAPI.useGetMyPlaylistsQuery({}, { skip: !isAuth });

  // useEffect(() => {
  //   catchError(getPlaylistsError);
  // }, [getPlaylistsError]);

  const openModalHandler = (modal: ModalType) => {
    if (isAuth) {
      openModal({active: modal});
    } else {
      openModal({active: 'login'});
    }
  };

  // if (isLoading) return <FullScreenLoader />;
  // if (!isSuccess) return null;

  return (
    <Library>
      <TitleWrapper>
        <TitlePage>Все видео</TitlePage>
        <ButtonWithIcon
          onClick={() => openModalHandler('AddVideo')}
          icon={<PlusIcon/>}
          buttonText={isMobile ? 'Добавить' : 'Добавить видео'}
        />
      </TitleWrapper>
      <LibraryRightSide>
        {/*{playlistId ? (*/}
        {/*  <PlaylistVideoList playlistsList={playlists.results} />*/}
        {/*) : (*/}
        <AllVideoList/>
        {/*)}*/}
      </LibraryRightSide>
    </Library>
  );
};
