import {
  AnswersWrapper,
  ArrowsWrapper,
  ButtonQuestionTab,
  ControlWrapper,
  IncorrectInfo,
  IncorrectInfoText1,
  IncorrectInfoText2,
  QuestionBlock,
  QuestionBody,
  QuestionHeader,
  QuestionTitle,
  QuestionWrapper,
} from './QuestionPage.styled';

import { ButtonTranscript } from '../../TranscriptTab/TranscriptVideo.styled';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '@/components/Shared/Buttons/Button/Button';
import ArrowLeft from '@/components/SvgIcons/ArrowLeft';
import ArrowRight from '@/components/SvgIcons/ArrowRight';
import { useActions, useAppSelector, useMatchMedia } from '@/hooks';

interface QuestionProps {
  question: string;
  answers: string[];
  correctAnswer: string;
  answer: string | null;
  goToTime: () => void;
}

export const QuestionPage = ({
  answers = [],
  correctAnswer,
  answer: currentAnswer,
  question,
  goToTime,
}: QuestionProps) => {
  const isMobile = useMatchMedia('(max-width: 768px)');
  const { t } = useTranslation('components');
  const [selectedAnswer, setSelectedAnswer] = useState<'correct' | 'incorrect' | undefined>(undefined);
  const { setQuizAnswer, setActiveQuestionIndex, setQuizDone } = useActions();
  const [activeQuestionIndex, questions] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
  ]);
  const isLastQuestion = activeQuestionIndex === questions.length - 1;

  const handleAnswer = (answer: string) => {
    setQuizAnswer({ question, answer });
    setSelectedAnswer(answer === correctAnswer ? 'correct' : 'incorrect');
  };

  const onNextButtonHandler = () => {
    setSelectedAnswer(undefined);

    if (questions.length > activeQuestionIndex + 1) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
  };

  const goToResults = () => {
    if (!questions.filter((q) => q.answer === null).length) {
      setQuizDone();
    }
  };

  return (
    <QuestionWrapper>
      <QuestionHeader>
        <QuestionTitle>{question}</QuestionTitle>
        <ControlWrapper>
          <ArrowsWrapper>
            <ButtonTranscript
              style={{ width: '85px', height: '100%' }}
              onClick={() => {
                setActiveQuestionIndex(activeQuestionIndex - 1);
              }}
              disabled={!activeQuestionIndex}
            >
              <ArrowLeft />
            </ButtonTranscript>
            <ButtonTranscript
              style={{ width: '85px', height: '100%' }}
              onClick={onNextButtonHandler}
              disabled={isLastQuestion}
            >
              <ArrowRight />
            </ButtonTranscript>
          </ArrowsWrapper>
          <Button
            onClick={goToResults}
            themed="colored"
            variant="colored"
            style={
              !questions.filter((q) => q.answer === null).length
                ? {
                    display: 'block',
                    minHeight: isMobile ? '30px' : '100%',
                    height: isMobile ? '30px' : '100%',
                    padding: isMobile ? '0px' : '',
                  }
                : { display: 'none' }
            }
          >
            <span style={{ fontSize: '14px' }}>Завершить тест</span>
          </Button>
        </ControlWrapper>
      </QuestionHeader>
      <QuestionBody>
        <AnswersWrapper style={{ width: '100%' }}>
          {answers.map((answer, index) => {
            const onChangeHandler = () => {
              if (!selectedAnswer) {
                !(currentAnswer === answer) && handleAnswer(answer);
              }
            };

            return (
              <QuestionBlock
                htmlFor={`answer-${index}`}
                key={index}
                answerType={currentAnswer === answer ? (answer === correctAnswer ? 'correct' : 'incorrect') : undefined}
                disabled={!!selectedAnswer}
                onClick={onChangeHandler}
              >
                <input
                  style={{ cursor: 'pointer' }}
                  onChange={onChangeHandler}
                  id={`answer-${index}`}
                  name="question-group"
                  type="radio"
                  checked={currentAnswer === answer}
                />
                {answer}
              </QuestionBlock>
            );
          })}
        </AnswersWrapper>
        {currentAnswer && correctAnswer !== currentAnswer && (
          <>
            <IncorrectInfo>
              <IncorrectInfoText1>{t('incorrect_answer')}</IncorrectInfoText1>
              <IncorrectInfoText2>
                {t('correct_answer')}: {correctAnswer}
              </IncorrectInfoText2>
            </IncorrectInfo>
            <ButtonQuestionTab onClick={goToTime}>{t('question_watch_video')}</ButtonQuestionTab>
          </>
        )}
      </QuestionBody>
    </QuestionWrapper>
  );
};
