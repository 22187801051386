export const generateCodeVerifier = (length = 64) => {
  const allowedChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  let verifier = '';
  const randomValues = new Uint8Array(length);
  window.crypto.getRandomValues(randomValues);
  for (let i = 0; i < length; i++) {
    verifier += allowedChars.charAt(randomValues[i] % allowedChars.length);
  }
  return verifier;
};
