import styled, { css } from 'styled-components';

import { theme } from '@/styles';

export const Container = styled.div`
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 7px; /* ширина scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: ${theme.colors.modal.purple_300}; /* цвет плашки */
  }
`;

export const TariffsWrapper = styled.div<{ isDisabled?: boolean }>`
  background-color: #2d2166;
  padding: 1rem;
  border-radius: 15px;
  height: 100%;

  &:hover {
    background-color: rgb(52 41 109);
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;

      :hover {
        opacity: 0.5;
      }
    `}
`;

export const TariffsContainerBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(13, 8, 35, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
`;

export const Tarif = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-bottom: 10px;
  border-bottom: 1px solid #62548b;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 5px;
`;

export const UnlimitedText = styled.div`
  border-radius: 25px;
  background: linear-gradient(to right, #c084fc, #f472b6);
  color: white;
  margin-left: 10px;
  padding: 2px 8px;
  font-weight: 100;
`;

export const TariffsText = styled.h4`
  font-size: 20px;
  font-weight: 500;
  color: white;
`;

export const TarifDetailList = styled.ul`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const TarifDetail = styled.li<{ selected?: boolean }>`
  color: ${({ selected }) => (selected ? '#ffffff' : '#BBB6DA')};
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25rem;
  gap: 8px;
`;

export const DotItem = styled.div`
  background-color: ${theme.colors.modal.text.purple};
  width: 6px;
  height: 6px;
  border-radius: 9999px;
`;

export const Wrapper = styled.label<{ isDisabled?: boolean; selected?: boolean }>`
  position: relative;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  border: ${({ selected, isDisabled }) => (selected && !isDisabled ? `1px solid #792D9C` : 'none')};
  border-radius: 16px;
`;

export const ButtonModalTextStyle = styled.span`
  font-size: 14px;
  color: white;
  line-height: 24px;
  font-weight: normal;
`;

export const CanProcessVideoText = styled.span`
  font-size: 14px;
  color: #ef7a80;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
`;

export const FooterAddVideo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 20px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
  }
`;
