import { api } from './api';

import { Access, LoginUserResponse, Refresh, RegisterSocial, RegisterUser } from '@/types';

export const authAPI = api.injectEndpoints({
  endpoints: (build) => ({
    loginUser: build.mutation<LoginUserResponse, RegisterUser>({
      query: (body) => ({
        url: 'auth/login/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'user', id: 'user' }],
    }),

    loginSocial: build.query<LoginUserResponse, RegisterSocial>({
      query: (body) => ({
        url: 'auth/social/',
        method: 'POST',
        body,
      }),
      providesTags: [{ type: 'user', id: 'user' }],
    }),

    logOutUser: build.mutation<void, Refresh>({
      query: (body) => ({
        url: 'auth/logout/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'user', id: 'user' }],
    }),

    refreshToken: build.mutation<Access, Refresh>({
      query: (body) => ({
        url: 'auth/refresh/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'user', id: 'user' }],
    }),
  }),
});

export const { useLazyLoginSocialQuery, useLogOutUserMutation } = authAPI;
