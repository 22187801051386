import styled from 'styled-components';

import { theme } from '@/styles';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
`;

export const InfoBlockMaterials = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  max-width: 446px;
  padding: 20px;
  top: 50px;
  //left: 30px;
  left: 0;
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? '#e4e4ff'
      : isIframe && iframeTheme.current === 'cintera'
      ? 'rgba(148, 54, 89, 0.3)'
      : 'rgb(73, 59, 119)'};
`;

export const InfoText = styled.p`
  color: ${({ theme: { iframeTheme, isIframe } }) =>
    isIframe && iframeTheme.current === 'light'
      ? theme.colors.blue.blue_dark
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : '#d8b4fe'};
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
`;
