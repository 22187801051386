import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { theme } from '@/styles';

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  gap: 20px;
`;

export const SubmitButton = styled.button`
  width: 100%;
  background: linear-gradient(to left, #ec4899, #9333ea);
  border: none;
  border-radius: 8px;
  padding: 12px;
  color: ${theme.colors.white.white_100};
  font-weight: 600;
  font-size: 18px;

  &:hover {
    background: linear-gradient(to left, #db2777, #7e22ce);
    transition: all 0.2s;
  }
`;

export const FormInnerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #62548b;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
`;

export const HrStyled = styled.hr`
  background-color: #62548b;
  width: 100%;
  height: 1px;
`;

export const RegisterGradientText = styled(Link)`
  background: linear-gradient(to right, #792d9c, #d33e8b);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;

  :hover {
    opacity: 0.8;
  }
`;

export const AgreementWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  font-size: 12px;
`;

export const Label = styled.label<{ required: boolean; error: boolean }>`
  color: ${theme.colors.white.white_100};
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;

  ${(props) =>
    props.required &&
    css`
      &:after {
        content: '*';
        padding: 0 2px;
      }
    `}
  ${(props) =>
    props.error &&
    css`
      color: ${theme.colors.red.red_light};
    `}
    transition: color 0.3s ease;

  @media (max-width: 1028px) {
    font-size: 15px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;
