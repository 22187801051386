import {
  AddVideoText,
  AddVideoText1,
  AddVideoWrapper,
  ButtonModalTextStyle,
  Container,
  IconWrapper,
  InputIcon,
  LabelTextPurple,
  SecondaryTextErrorFormat,
  SelectedFileWrapper,
  TextAddVideo,
  TextBlock,
  TextCenter,
  VideoFileErrorBlock,
} from './SelectVideo.styled';

import {UploadIcon} from '../../../assets/UploadIcon';
import {ErrorVideoLinkText} from '../ErrorVideoLinkText';

import {ChangeEvent, DragEvent, RefObject} from 'react';

import InputModal from '@/components/InputModal/InputModal';
import Button from '@/components/Shared/Buttons/Button/Button';
import {FoldersDropDown} from '@/components/Shared/FoldersDropDown';
import clearInputIcon from '@/components/SvgIcons/clearInputIcon.svg';
import {FormButtonsStyled} from '@/styles';
import {FoldersTreeResponse} from '@/types';

type SelectVideoProps = {
  videoLinkValue: string;
  selectedFile: File | null;
  videoLinkHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  addVideo: () => void;
  setVideoLinkValue: (value: string) => void;
  setShowInfo: (value: boolean) => void;
  folders: FoldersTreeResponse[] | undefined;
  handleDrop: (e: DragEvent<HTMLDivElement>) => void;
  fileInputRef: RefObject<HTMLInputElement>;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleDragOver: (e: DragEvent<HTMLDivElement>) => void;
  isLinkError: boolean;
  isError: boolean;
  incorrectVideoFormat: boolean;
  closeHandler: () => void;
  t: any;
  selectedFolder: string | null;
  setSelectedFolder: (value: string | null) => void;
};

export const SelectVideo = ({
                              videoLinkValue,
                              selectedFile,
                              videoLinkHandler,
                              addVideo,
                              setVideoLinkValue,
                              setShowInfo,
                              folders,
                              handleDrop,
                              fileInputRef,
                              onFileChange,
                              handleDragOver,
                              isLinkError,
                              isError,
                              incorrectVideoFormat,
                              closeHandler,
                              selectedFolder,
                              setSelectedFolder,
                              t,
                            }: SelectVideoProps) => {
  return (
    <>
      <Container>
        <TextAddVideo>{t('text_link', {ns: 'modal'})}</TextAddVideo>
        <div style={{position: 'relative'}}>
          <InputModal
            style={{
              borderColor:
                (isError && videoLinkValue !== '') || (isLinkError && videoLinkValue !== '') ? '#EF7A80' : '',
              paddingRight: '38px',
            }}
            placeholder={t('video_insert')}
            value={videoLinkValue}
            onChange={videoLinkHandler}
            disabled={selectedFile !== null}
          />
          {videoLinkValue !== '' && (
            <InputIcon
              isError={isError || isLinkError}
              onClick={() => setVideoLinkValue('')}
              src={clearInputIcon}
              alt="clearInputIcon"
            />
          )}
        </div>
        <ErrorVideoLinkText isError={isError} isLinkError={isLinkError} videoLinkValue={videoLinkValue}/>
        <TextCenter>{t('or_add_video')}</TextCenter>
        <AddVideoWrapper
          onClick={() => fileInputRef.current?.click()}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          videoLinkValue={videoLinkValue}
        >
          <IconWrapper>
            <UploadIcon/>
          </IconWrapper>
          <input
            disabled={videoLinkValue !== ''}
            id="file1"
            type="file"
            onChange={onFileChange}
            ref={fileInputRef}
            hidden
            accept=".mkv, .mp4, .webm, .avi" //audio/ogg
          />
          <TextBlock>
            <AddVideoText1 videoLinkValue={videoLinkValue} onMouseOver={() => setShowInfo(true)}>
              {t('add_video_text1')}
            </AddVideoText1>
            <AddVideoText onMouseOver={() => setShowInfo(true)}>{t('add_video_text2')}</AddVideoText>
          </TextBlock>
          {selectedFile && (
            <SelectedFileWrapper>
              <LabelTextPurple style={{marginBottom: '0px'}}>Выбран: {selectedFile.name}</LabelTextPurple>
            </SelectedFileWrapper>
          )}
        </AddVideoWrapper>
        {incorrectVideoFormat && (
          <VideoFileErrorBlock>
            <SecondaryTextErrorFormat>{t('video_format_invalid1')}</SecondaryTextErrorFormat>
            <SecondaryTextErrorFormat>{t('video_format_invalid2')}</SecondaryTextErrorFormat>
          </VideoFileErrorBlock>
        )}
      </Container>
      <Container>
        <TextAddVideo>Добавить в папку</TextAddVideo>
        <FoldersDropDown
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          title={folders ? 'Все папки' : ''}
          data={folders}
        />
      </Container>
      <div style={{width: '100%'}}>
        <FormButtonsStyled onMouseOver={() => setShowInfo(false)}>
          <Button onClick={closeHandler} themed="colored-default" variant="colored-default">
            <ButtonModalTextStyle>Отмена</ButtonModalTextStyle>
          </Button>
          <Button
            onClick={addVideo}
            disabled={(!selectedFile && !videoLinkValue) || isError || isLinkError}
            themed="colored"
            variant="colored"
          >
            <ButtonModalTextStyle>Добавить видео</ButtonModalTextStyle>
          </Button>
        </FormButtonsStyled>
      </div>
    </>
  );
};
