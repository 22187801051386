import styled, {css} from 'styled-components';
import {theme} from '@/styles';

export const AvatarWrapper = styled.div<{ open: boolean }>`
    border-radius: 16px;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 12px;
    background-color: #251a56;

    // & .arrow {
    //   transition: all 0.3s;
        //   ${({open}) => (open ? 'transform: rotate(180deg);' : '')}
    // }

    @media screen and (max-width: 480px) {
        gap: 5px;
    }
`;

export const AvatarMenuArrow = styled.button`
    align-self: center;
`;

export const UserNameWrapper = styled.div``;

export const Name = styled.h3`
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: white;
`;

export const Email = styled.span`
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #62548b;
`;

export const Avatar = styled.div<{ size?: string; url?: string | null }>`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 9999px;
    background: ${({url}) => (url ? `url(${url})` : theme.colors.blue.blue_5)};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ${(props) => {
        switch (props.size) {
            case 'm':
                return css`
                    min-width: 180px;
                    min-height: 180px;
                    font-weight: 700;
                    font-size: 48px;
                    border-radius: 20px;

                    @media screen and (max-width: 480px) {
                        min-width: 150px;
                        min-height: 150px;
                    }
                `;
            case 's':
                return css`
                    min-width: 40px;
                    min-height: 40px;

                    @media screen and (max-width: 480px) {
                        min-width: 35px;
                        min-height: 35px;
                    }
                `;
            case 'xs':
                return css`
                    min-width: 36px;
                    min-height: 36px;
                `;
        }
    }};
`;

export const AvatarName = styled.span`
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    color: ${theme.colors.white.white_100};
`;
