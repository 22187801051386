import {Materials} from './Materials';
import {ButtonTranscript} from './Materials/TranscriptTab/TranscriptVideo.styled';
import {
  BackButton,
  Container,
  Content,
  ContentBlock,
  Inner,
  Title,
  Video,
  VideoCardV2Wrapper,
  VideoPlayer,
} from './VideoPage.styled';

import {EditModeProvider, VideoContext} from '../model';

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import {useGetVideoByIdQuery} from '@/api';
import {
  FileVideoPlayer,
  KinescopePlayerVideo,
  RutubeVideoPlayer,
  VideoFragmentCard,
  VkVideoPlayer,
  YouTubeVideoPlayer,
} from '@/components';
import {ArrowLeft} from '@/components/SvgIcons';
import {
  useActions,
  useAppSearchParams,
  useAppSelector,
  useHandlingError,
  useIframe,
  useIframeHeight,
  useSearch
} from '@/hooks';
import {theme} from '@/styles';
import SearchInVideoInput from "@/components/Shared/SearchInVideoInput/SearchInVideoInput";

export const VideoPage = ({demoVideoId}: { demoVideoId?: string }) => {
  const {sendTimeToParent} = useIframeHeight();
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const {getAppParam, setAppParams} = useAppSearchParams();
  const {showNotification} = useActions();
  const {id, playlistId} = useParams();
  const [currentTime, setCurrentTime] = useState(0);
  const navigate = useNavigate();
  const videoId = demoVideoId ?? id;
  const {t} = useTranslation();
  const search = getAppParam('search');
  const {catchError} = useHandlingError();
  const {isIframe, isVideoPage} = useIframe();
  const {
    searchInVideo,
    isSearchInVideoLoading,
    searchInVideoError,
    searchInVideoSuccess,
    getSearchInVideoHandler
  } = useSearch();

  const iframe = useRef<{ goToTime: (time: number) => void } | null>(null);

  const iframeWrapper = useRef<HTMLDivElement>(null);

  const {
    data: video,
    isLoading: isVideoLoaging,
    isError: videoError,
  } = useGetVideoByIdQuery({id: videoId}, {skip: !videoId});

  const goToTime = useCallback((time: number | string) => {
    isIframe && sendTimeToParent(time as number); // send time to iframe
    iframe.current?.goToTime(time as number);
    iframeWrapper.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }, []);

  const startsForm = useMemo(() => {
    const time = getAppParam('t');
    return time ? parseInt(time) : 0;
  }, [getAppParam]);

  useEffect(() => {
    if (!isAuth && !theme.iframeTheme) {
      showNotification({
        text: t('w_video', {ns: 'error'}),
        severity: 'error',
      });
      setAppParams('popup', 'login');
    }
  }, [isAuth]);

  // useEffect(() => {
  //   switch (iframeLang) {
  //     case IframeLangType.RU:
  //       i18n.changeLanguage('ru');
  //       break;
  //     case IframeLangType.EN:
  //       i18n.changeLanguage('en');
  //       break;
  //     default:
  //       i18n.changeLanguage('en');
  //   }
  // }, [iframeLang]);

  useEffect(() => {
    catchError(videoError || searchInVideoError);
  }, [videoError, searchInVideoError]);

  // useEffect(() => {
  //   if(video?.source === 'RUTUBE') {
  //     document.body.appendChild()
  //   }
  // }, [video]);

  return (
    <>
      {video && (
        <Container>
          {!isIframe && isVideoPage && (
            <BackButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeft/> Назад
            </BackButton>
          )}
          <Inner>
            {isIframe && playlistId && (
              <ButtonTranscript
                style={{marginBottom: '20px'}}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Назад
              </ButtonTranscript>
            )}
            <Content>
              <Video>
                <VideoPlayer>
                  {video.source === 'YOUTUBE' && (
                    <YouTubeVideoPlayer
                      ref={iframe}
                      id={video.videoId}
                      title={video?.title}
                      startsFrom={startsForm}
                      height={'100%'}
                      setCurrentTime={(time: number) => setCurrentTime(time)}
                    />
                  )}

                  {video.source === 'VK' && (
                    <VkVideoPlayer
                      ref={iframe}
                      title={video?.title}
                      startsFrom={startsForm}
                      originLink={video.originLink}
                      height={'100%'}
                      setCurrentTime={(time: number) => setCurrentTime(time)}
                    />
                  )}
                  {video.source === 'UPLOADED' && (
                    <FileVideoPlayer
                      ref={iframe}
                      height={'100%'}
                      url={video.originLink}
                      startsFrom={startsForm}
                      setCurrentTime={(time: number) => setCurrentTime(time)}
                      convertationStatus={video.convertationStatus}
                    />
                  )}

                  {video.source === 'RUTUBE' && (
                    <RutubeVideoPlayer
                      ref={iframe}
                      height={'100%'}
                      videoId={video.videoId}
                      startsFrom={startsForm}
                      setCurrentTime={(time: number) => setCurrentTime(time)}
                      // goToTime={goToTime}
                    />
                  )}
                  {video.source === 'KINESCOPE' && (
                    <KinescopePlayerVideo
                      ref={iframe}
                      id={video.videoId}
                      title={video?.title}
                      startsFrom={startsForm}
                      height={'100%'}
                      setCurrentTime={(time: number) => setCurrentTime(time)}
                    />
                  )}
                </VideoPlayer>
                <SearchInVideoInput getSearch={getSearchInVideoHandler}/>

                {search && searchInVideo && searchInVideoSuccess && (
                  <>
                    {searchInVideo.length === 0 && (
                      <Title style={{fontSize: '17px'}}>{t('main.no_result', {ns: 'pages'})}</Title>
                    )}
                    {searchInVideo.length > 0 &&
                      searchInVideo.map((fragment) => {
                        return (
                          <VideoCardV2Wrapper style={{marginTop: '8px', padding: '15px'}} key={fragment.publicId}>
                            <ContentBlock>
                              {fragment.cues.map((cue, i) => {
                                if (fragment.publicId === video.publicId) {
                                  return (
                                    <div key={i} style={{height: 'auto'}}>
                                      <VideoFragmentCard
                                        fragment={cue}
                                        key={fragment.publicId + i}
                                        goToTime={goToTime}
                                        videoPreview={fragment.thumbnailUrl}
                                      />
                                    </div>
                                  );
                                }
                              })}
                            </ContentBlock>
                          </VideoCardV2Wrapper>
                        );
                      })}
                    {isSearchInVideoLoading && (
                      <div style={{margin: '0 auto'}}>
                        <CircularProgress color="secondary" size={40}/>
                      </div>
                    )}
                  </>
                )}
              </Video>
              <EditModeProvider>
                <VideoContext.Provider value={video}>
                  <Materials currentTime={currentTime} goToTime={goToTime}/>
                </VideoContext.Provider>
              </EditModeProvider>
            </Content>

            {isVideoLoaging && (
              <div style={{margin: '0 auto'}}>
                <CircularProgress color="secondary" size={50}/>
              </div>
            )}
          </Inner>
        </Container>
      )}
    </>
  );
};
