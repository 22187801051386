export const DocIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 15H3V16H2C0.875 16 0 15.125 0 14V2C0 0.90625 0.875 0 2 0H6.875C7.25 0 7.65625 0.1875 7.9375 0.46875L11.5312 4.0625C11.8125 4.34375 12 4.75 12 5.125V9H11V6H7.5C6.65625 6 6 5.34375 6 4.5V1H2C1.4375 1 1 1.46875 1 2V14C1 14.5625 1.4375 15 2 15ZM10.9688 5C10.9375 4.9375 10.9062 4.84375 10.8438 4.78125L7.21875 1.15625C7.15625 1.09375 7.0625 1.0625 7 1.03125V4.5C7 4.78125 7.21875 5 7.5 5H10.9688ZM5.5 11H6.5C7.3125 11 8 11.6875 8 12.5V14.5C8 15.3438 7.3125 16 6.5 16H5.5C5.21875 16 5 15.7812 5 15.5V11.5C5 11.25 5.21875 11 5.5 11ZM6 15H6.5C6.75 15 7 14.7812 7 14.5V12.5C7 12.25 6.75 12 6.5 12H6V15ZM14.25 11H14.75C15.4375 11 16 11.5625 16 12.25V12.5C16 12.7812 15.75 13 15.5 13C15.2188 13 15 12.7812 15 12.5V12.25C15 12.125 14.875 12 14.75 12H14.25C14.0938 12 14 12.125 14 12.25V14.75C14 14.9062 14.0938 15 14.25 15H14.75C14.875 15 15 14.9062 15 14.75V14.5C15 14.25 15.2188 14 15.5 14C15.75 14 16 14.25 16 14.5V14.75C16 15.4688 15.4375 16 14.75 16H14.25C13.5312 16 13 15.4688 13 14.75V12.25C13 11.5625 13.5312 11 14.25 11ZM9 12.25C9 11.5625 9.53125 11 10.25 11H10.75C11.4375 11 12 11.5625 12 12.25V14.75C12 15.4688 11.4375 16 10.75 16H10.25C9.53125 16 9 15.4688 9 14.75V12.25ZM10.25 12C10.0938 12 10 12.125 10 12.25V14.75C10 14.9062 10.0938 15 10.25 15H10.75C10.875 15 11 14.9062 11 14.75V12.25C11 12.125 10.875 12 10.75 12H10.25Z"
        fill={color}
      ></path>
    </svg>
  );
};
