import { theme } from '../../../../styles/theme';
import { Button } from '../Button';

import styled, { css } from 'styled-components';

export const Wrapper = styled(Button)`
  width: 54px;
  height: 52px;
  transition: all 0.3s linear;

  :hover {
    scale: 1.02;
  }

  :active {
    scale: 0.98;
  }

  ${(props) => {
    switch (props.themed) {
      case 'red':
        return css`
          /* background: ${theme.colors.red.red_light}; */

          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;

      case 'black':
        return css`
          /* background: ${theme.colors.blue.blue_20}; */

          & path {
            stroke: ${theme.colors.white.white_100};
          }
        `;
      case 'white':
        return css`
          /* background: ${theme.colors.white.white_100}; */

          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;
      case 'none':
        return css`
          /* background: transparent; */

          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;
      case 'outline':
        return css`
          border: 2px solid ${theme.colors.white.white_30};

          & path {
            stroke: ${theme.colors.white.white_30};
          }

          :hover {
            border: 2px solid ${theme.colors.white.white_80};

            & path {
              stroke: ${theme.colors.white.white_80};
            }
          }

          :active {
            & path {
              stroke: ${theme.colors.white.white_80};
            }
          }
        `;
      default:
    }
  }}
`;
