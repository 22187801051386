import {NavLink} from 'react-router-dom';
import {ButtonContent, Item, NavItemText} from './NavBarItem.styled';

type NavBarItemPropsType = {
  onClick: () => void;
  to: string;
  title: string;
  icon: JSX.Element;
};

export const NavBarItem = ({to, onClick, title, icon}: NavBarItemPropsType) => {
  return (
    <NavLink to={to} end onClick={onClick}>
      {({isActive}) => (
        <Item isActive={isActive}>
          <ButtonContent>
            {icon}
            <NavItemText>{title}</NavItemText>
          </ButtonContent>
        </Item>
      )}
    </NavLink>
  );
};
