import styled, { css } from 'styled-components';

export const PlaylistsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  gap: 0.5rem;
`;

export const PlaylistTitle = styled.h2<{ isCollapsed: boolean }>`
  font-weight: 600;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border-width: 0;
        `
      : css`
          padding-left: 1rem;
          padding-top: 9px;
          padding-bottom: 5px;
        `}
`;

export const PlaylistList = styled.div`
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const PlaylistItem = styled.div<{ isCollapsed: boolean; isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  color: ${({ isActive }) => (isActive ? '#D8B4FE' : 'rgba(255, 255, 255, 0.8)')};
  background-color: ${({ isActive }) => (isActive ? 'rgba(168, 85, 247, 0.2)' : 'transparent')};

  &:hover {
    background-color: ${({ isActive }) => (isActive ? '' : 'rgba(255, 255, 255, 0.05)')};
    color: ${({ isActive }) => (isActive ? '#D8B4FE' : 'white')};
  }

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          padding: 0.5rem;
        `
      : css`
          padding: 8px 16px;
        `}
`;

export const PlaylistContent = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
  min-width: 0;
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      justify-content: center;
    `}
`;

export const PlaylistImage = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 0.25rem;
  overflow: hidden;
  flex-shrink: 0;
  background: rgba(168, 85, 247, 0.2);

  img {
    max-width: 100%;
    height: -webkit-fill-available;
  }
`;

export const PlaylistName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`;
