import styled from 'styled-components';

export const IframeWrapper = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;
