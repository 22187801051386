import { useAppSearchParams } from './useAppSearchParams';

import { useLocation, useParams } from 'react-router-dom';

import { API_SHARE_URL } from '@/constants';
import { useActions } from '@/hooks/useActions';

export const useIframe = () => {
  const location = useLocation();
  const { getAppParam } = useAppSearchParams();

  const isLandingPage = location.pathname.includes('landing');
  const isVideoPage = location.pathname.includes('videos');
  const { id } = useParams();
  const isIframe = location.pathname.includes('embed');
  const iframeTheme = getAppParam<string>('theme') ?? 'light';
  const iframeLang = getAppParam('lang') ?? 'ru';
  const vendorId = getAppParam('vendor') ?? '';
  const showTest = isLandingPage ? '1' : getAppParam('show_test') ?? '0';
  const { showNotification } = useActions();

  const copyIframe = async (playlistId?: string) => {
    let iframe = '';
    if (playlistId) {
      iframe = `<iframe allowfullscreen height="auto" src='${API_SHARE_URL}/embed/playlist/${playlistId}?vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}'></iframe>`;
    } else {
      iframe = `<iframe allowfullscreen height="auto" src='${API_SHARE_URL}/embed/videos/${id}?t=0&vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}'></iframe>`;
    }
    await navigator.clipboard.writeText(iframe);
    showNotification({
      text: 'Встраиваемый код скопирован!',
      severity: 'success',
    });
  };

  return {
    isIframe,
    iframeTheme,
    iframeLang,
    vendorId,
    showTest,
    copyIframe,
    isLandingPage,
    isVideoPage,
  };
};
