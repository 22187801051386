import {IconWrapper, StyledInput} from './SearchInVideoInput.styled';

import {useEffect, useRef} from 'react';

import {SearchIcon} from 'lucide-react';
import {useTranslation} from 'react-i18next';
import {useParams, useSearchParams} from 'react-router-dom';

import {useDebounce, useIframe} from '@/hooks';

interface SearchInVideoInputProps {
  getSearch: (value: string, selectedPlaylist: string | null) => void;
  disabled?: boolean;
  placeholder?: string;
}

const SearchInVideoInput = ({getSearch, disabled = false, placeholder}: SearchInVideoInputProps) => {
  const {t} = useTranslation('components');
  const [param, setParam] = useSearchParams();
  const search = param.get('search') || '';
  const {playlistId} = useParams();
  const {isIframe, iframeTheme} = useIframe();

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const data = searchInputRef.current?.value || '';
    if (data && playlistId) {
      getSearch(data, playlistId);
    }
  }, [playlistId]);

  //очистка поля поиска при смене плейлиста
  // useEffect(() => {
  //   if (searchInputRef.current) {
  //     searchInputRef.current?.value = '';
  //     setParam((prev) => {
  //       prev.delete('search');
  //       return prev;
  //     });
  //     getSearch('', playlistId ?? null);
  //   }
  // }, [playlistId]);

  const makeSearch = useDebounce(() => {
    const data = searchInputRef.current?.value || '';
    if (data) {
      setParam((prev) => {
        prev.set('search', data);
        return prev;
      });
      getSearch(data, playlistId ?? null);
    } else {
      setParam((prev) => {
        prev.delete('search');
        return prev;
      });
    }
  }, 500);

  const onSearch = () => {
    makeSearch();
  };

  return (
    <StyledInput
      placeholder={placeholder ?? t('search_in_video')}
      ref={searchInputRef}
      onChange={onSearch}
      defaultValue={search}
      disabled={disabled}
      icon={
        <IconWrapper>
          <SearchIcon
            color={isIframe && iframeTheme === 'cintera' ? 'rgba(148, 54, 89, 0.8)' : 'rgba(216, 180, 254, 0.5)'}
            size={20}
          />
        </IconWrapper>
      }
    />
  );
};

export default SearchInVideoInput;
