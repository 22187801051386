import Button from '../../Buttons/Button/Button';
import { Description, Inner, Modal, Title } from '../Modal';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { videosAPI } from '@/api';
import { useActions, useAppSelector } from '@/hooks';
import { FormButtonsStyled } from '@/styles';

export const RemoveVideo = () => {
  const { t } = useTranslation('components');
  const { closeModal, showNotification } = useActions();
  // const playlistId = useAppSelector((state) => state.modal.data?.playlistId);
  const id = useAppSelector((state) => state.modal.data?.id);
  const location = useLocation();
  const playlist = location.pathname.includes('playlist');

  const [deleteMovie, result] = videosAPI.useDeleteVideoMutation();
  // const [removeFromPlaylist, resultPlaylist] = playlistsAPI.useRemoveVideoFromPlaylistMutation();

  const showSuccess = () => {
    closeModal();
    showNotification({
      text: t('del_v', { ns: 'success' }),
      severity: 'success',
    });
  };

  const showError = () => {
    showNotification({
      text: t('del_v', { ns: 'error' }),
      severity: 'error',
    });
  };

  const deleteM = async () => {
    try {
      // if (id && playlistId) {
      //   removeFromPlaylist({ videoPublicId: id, playlistId }).unwrap();
      // } else if (id) {
      deleteMovie({ id: id || '' }).unwrap();
      // }
      showSuccess();
    } catch (e) {
      showError();
    }
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal modal={'RemoveVideo'} onClose={() => handleClose()} closeButton>
      <Title>{playlist ? 'Удалить видео из плейлиста?' : 'Удалить видео?'}</Title>
      <Inner modal={'RemoveVideo'}>
        <Description>
          Вы потеряете доступ к видео и всем связанным с ним материалам, таким как транскрипция, конспект, тест
        </Description>
        <FormButtonsStyled>
          <Button themed="pink" variant="pink" onClick={handleClose}>
            Отмена
          </Button>
          <Button
            width="100%"
            themed="colored"
            variant="colored"
            onClick={deleteM}
            disabled={result.isLoading}
            // disabled={result.isLoading || resultPlaylist.isLoading}
          >
            Удалить
          </Button>
        </FormButtonsStyled>
      </Inner>
    </Modal>
  );
};
