import {PageItem, PagesList, Wrapper} from './BurgerMenu.styled';

import {Gift, RussianRuble} from 'lucide-react';
import {useNavigate} from 'react-router-dom';

import {FolderIcon} from '@/components/SvgIcons/FolderIcon';
import {HomeIcon} from '@/components/SvgIcons/HomeIcon';
import {LogoutIcon} from '@/components/SvgIcons/LogoutIcon';
import SettingsIcon from '@/components/SvgIcons/SettingsIcon';
import {VideosIcon} from '@/components/SvgIcons/VideosIcon';
import {useActions} from '@/hooks';
import {BalanceInfo} from '@/components/BalanceInfo';
import {useCallback} from "react";

export const BurgerMenu = ({setOpened}: { setOpened?: (value: boolean) => void }) => {
  const navigate = useNavigate();
  const {logout} = useActions();

  const handleNavigation = useCallback((path: string) => {
    navigate(path);
    setOpened?.(false);
  }, [navigate]);

  const pages = [
    {
      id: 1,
      icon: <HomeIcon/>,
      text: 'Главная',
      onClick: () => handleNavigation('/'),
    },
    {
      id: 2,
      icon: <VideosIcon/>,
      text: 'Все видео',
      onClick: () => handleNavigation('/videos'),
    },
    {
      id: 3,
      icon: <FolderIcon/>,
      text: 'Все папки',
      onClick: () => handleNavigation('/folders'),
    },
  ];

  const profile_pages = [
    {
      id: 4,
      icon: <Gift/>,
      text: 'Реферальная программа',
      onClick: () => handleNavigation('/referral-program'),
    },
    {
      id: 5,
      icon: <RussianRuble/>,
      text: 'Баланс',
      onClick: () => handleNavigation('/balance'),
    },
    {
      id: 6,
      icon: <SettingsIcon/>,
      text: 'Настройки аккаунта',
      onClick: () => handleNavigation('/profile'),
    },
  ];

  return (
    <Wrapper>
      <PagesList>
        {pages.map((page) => (
          <PageItem key={page.id} onClick={page.onClick}>
            {page.icon}
            {page.text}
          </PageItem>
        ))}
      </PagesList>
      <PagesList>
        {profile_pages.map((profile) => (
          <PageItem key={profile.id} onClick={profile.onClick}>
            {profile.icon}
            {profile.text}
          </PageItem>
        ))}
      </PagesList>
      <PagesList>
        <PageItem onClick={() => logout()}>
          <LogoutIcon/> Выйти
        </PageItem>
      </PagesList>
      <div style={{marginTop: '24px'}}>
        <BalanceInfo onClick={profile_pages[1].onClick}/>
      </div>
    </Wrapper>
  );
};
