import { Button, Text } from './ButtonWithIcon.styled';

export const ButtonWithIcon = ({
  icon,
  buttonText,
  onClick,
}: {
  icon: JSX.Element;
  buttonText: string;
  onClick: () => void;
}) => {
  return (
    <Button onClick={onClick}>
      {icon}
      <Text style={{ fontSize: '18px' }}>{buttonText}</Text>
    </Button>
  );
};
