import { api } from '@/api/api';
import { FileParams, GetLinkUploadVideoFileResponse } from '@/types';

export const videoFilesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getLinkUploadVideoFile: build.query<GetLinkUploadVideoFileResponse, FileParams>({
      query: ({ file_type, file_name }) => ({
        url: `/big-size-files/presigned-url/?file_type=${file_type}&file_name=${file_name}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetLinkUploadVideoFileQuery } = videoFilesAPI;
