import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #251a56;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    padding: 8px;
    justify-content: space-between;
    min-height: 80px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
`;
export const ImagesBlock = styled.div<{ count: number }>`
    max-width: 64px;
    max-height: 64px;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    flex: 1 0 100%;
    position: relative;
`;

export const EmptyImageContent = styled.div`
    width: 64px;
    height: 64px;
    display: flex;
    position: relative;
    background: #1a114599;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

export const Title = styled.h3`
    color: white;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    word-break: break-word;
`;

export const Text = styled.span`
    color: #bbb6da;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
`;
export const VideoImage = styled.img<{ count?: number; index?: number }>`
    width: ${({count}) => (count === 1 ? '64px' : '31px')};
    height: ${({count}) => (count === 1 ? '64px' : '31px')};
    border-radius: 8px;
    max-width: 100%;
    opacity: ${({index}) => (index !== 3 ? '1' : '0.4')};
    object-fit: cover;
`;

export const CounterOverlay = styled.div`
    position: absolute;
    top: 65%;
    left: 65%;
    color: white;
    font-size: 15px;
    font-weight: 500;
    z-index: 2;
`;
