import styled from 'styled-components';

export const LibraryRightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const Library = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  gap: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
