import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLazyLoginSocialQuery } from '@/api';
import { FullScreenLoader } from '@/components';
import { useActions, useAppSelector } from '@/hooks';
import { paramsSocialLogin } from '@/pages/RedirectRegisterSocialPage/model';

export const RedirectRegisterSocialPage = () => {
  const navigate = useNavigate();
  const { showNotification } = useActions();
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const [loginSocial] = useLazyLoginSocialQuery();

  const params = paramsSocialLogin();

  useEffect(() => {
    if (!isAuth) {
      const handleLogin = async () => {
        try {
          await loginSocial({ ...params }).unwrap();
          showNotification({
            text: 'Добро пожаловать!',
            severity: 'success',
          });

          navigate('/', { replace: true });
        } catch (error) {
          showNotification({
            text: 'Произошла ошибка, повторите еще раз',
            severity: 'error',
          });
          navigate('/landing', { replace: true });
        }
      };

      handleLogin();
    }
  }, []);

  return <FullScreenLoader />;
};
