import { EditModeContext } from './EditModeContext';

import { PropsWithChildren, useCallback, useState } from 'react';

export const EditModeProvider = ({ children }: PropsWithChildren) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEditMode = useCallback(() => {
    setIsEditMode((prevMode) => !prevMode);
  }, []);

  return <EditModeContext.Provider value={{ isEditMode, toggleEditMode }}>{children}</EditModeContext.Provider>;
};
