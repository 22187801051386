export const LogoutIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 18.9282C9.21615 19.6304 10.5957 20 12 20C13.4043 20 14.7838 19.6304 16 18.9282C17.2162 18.2261 18.2261 17.2162 18.9282 16C19.6303 14.7838 20 13.4043 20 12C20 10.5957 19.6304 9.21615 18.9282 8C18.2261 6.78385 17.2162 5.77394 16 5.0718C14.7838 4.36965 13.4043 4 12 4C10.5957 4 9.21615 4.36965 8 5.0718"
        stroke="#BBB6DA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M5 8L2 12M2 12L5 16M2 12H11" stroke="#BBB6DA" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
