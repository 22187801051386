import { CopyButtonText } from './CopyIframeButton.styles';

import { useTranslation } from 'react-i18next';

import Button from '@/components/Shared/Buttons/Button/Button';
import { useIframe } from '@/hooks/useIframe';

export type CopyIframeButtonPropsType = {
  playlistId?: string;
};

export const CopyIframeButton = ({ playlistId }: CopyIframeButtonPropsType) => {
  const { t } = useTranslation('components');
  const { copyIframe } = useIframe();

  return (
    <Button themed="colored-default" onClick={() => copyIframe(playlistId ?? '')}>
      <CopyButtonText>{t('copyIframe')}</CopyButtonText>
    </Button>
  );
};
