import {
  Copy,
  FooterStyled,
  GeneralInfoWrapper,
  IconsList,
  Info,
  InfoWrapper,
  List,
  Text,
  TitleNewH3,
  Wrapper,
} from './Footer.styled';
import { ContactIcon } from './images/ContactIcon';
import sber from './images/sber.svg';
import sk from './images/sk.svg';
import { Vk } from './images/VK';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Footer = () => {
  const getYear = new Date().getFullYear();
  const { i18n } = useTranslation();
  // const links =
  //   i18n.language === 'ru'
  //     ? [
  //         { label: 'Программа лояльности', to: '/referral-program' },
  //         { label: 'Обратная связь', to: '/' },
  //       ]
  //     : [
  //         { label: 'Loyalty Program', to: '/referral-program' },
  //         { label: 'Feedback', to: '/' },
  //       ];

  const docunents =
    i18n.language === 'ru'
      ? [
          { label: 'Пользовательское соглашение', to: '/policy/personal' },
          { label: 'Политика конфиденциальности', to: '/policy/privacy' },
        ]
      : [
          { label: 'Public Privacy Policy', to: '/policy/privacy' },
          { label: 'Terms of use', to: '/policy/terms_of_use' },
        ];

  return (
    <FooterStyled>
      <Wrapper>
        <GeneralInfoWrapper>
          <div>
            <TitleNewH3>ViSaver</TitleNewH3>
            <List>
              <li>
                <Text>Интеллектуальный помощник для работы с видеоконтентом</Text>
              </li>
            </List>
          </div>

          {/* Links */}
          {/*<div>*/}
          {/*  <TitleNewH3>Полезные ссылки</TitleNewH3>*/}
          {/*  <List>*/}
          {/*    {links.map(({ label, to }, index) => (*/}
          {/*      <li key={index}>*/}
          {/*        <Link to={to}>{label}</Link>*/}
          {/*      </li>*/}
          {/*    ))}*/}
          {/*  </List>*/}
          {/*</div>*/}

          {/* Legal */}
          <div>
            <TitleNewH3>Документы</TitleNewH3>
            <List>
              {docunents.map(({ label, to }, index) => (
                <li key={index}>
                  <Link to={to}>{label}</Link>
                </li>
              ))}
            </List>
          </div>

          {/* Contact */}
          <div>
            <TitleNewH3>Контакты</TitleNewH3>
            <List>
              <li>
                <ContactIcon />
                <Link to="mailto:support@visaver.com">support@visaver.com</Link>
              </li>
              <Link
                style={{ display: 'flex', alignContent: 'center', gap: '8px' }}
                to="https://t.me/ViSaver_Support"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19-.14.75-.42 1-.68 1.03-.58.05-1.02-.38-1.58-.75-.88-.58-1.38-.94-2.23-1.5-.99-.65-.35-1.01.22-1.59.15-.15 2.71-2.48 2.76-2.69a.2.2 0 00-.05-.18c-.06-.05-.14-.03-.21-.02-.09.02-1.49.95-4.22 2.79-.4.27-.76.41-1.08.4-.36-.01-1.04-.2-1.55-.37-.63-.2-1.12-.31-1.08-.66.02-.18.27-.36.74-.55 2.92-1.27 4.86-2.11 5.83-2.51 2.78-1.16 3.35-1.36 3.73-1.36.08 0 .27.02.39.12.1.08.13.19.14.27-.01.06.01.24 0 .38z" />
                </svg>
                <span>@visaver_support</span>
              </Link>
              <Link
                style={{ display: 'flex', alignContent: 'center', gap: '8px' }}
                to="https://t.me/visaver"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19-.14.75-.42 1-.68 1.03-.58.05-1.02-.38-1.58-.75-.88-.58-1.38-.94-2.23-1.5-.99-.65-.35-1.01.22-1.59.15-.15 2.71-2.48 2.76-2.69a.2.2 0 00-.05-.18c-.06-.05-.14-.03-.21-.02-.09.02-1.49.95-4.22 2.79-.4.27-.76.41-1.08.4-.36-.01-1.04-.2-1.55-.37-.63-.2-1.12-.31-1.08-.66.02-.18.27-.36.74-.55 2.92-1.27 4.86-2.11 5.83-2.51 2.78-1.16 3.35-1.36 3.73-1.36.08 0 .27.02.39.12.1.08.13.19.14.27-.01.06.01.24 0 .38z" />
                </svg>
                <span>https://t.me/visaver</span>
              </Link>

              <Link
                style={{ display: 'flex', alignContent: 'center', gap: '8px' }}
                to="https://vk.com/visavercom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Vk />
                <span>https://vk.com/visavercom</span>
              </Link>
            </List>
          </div>
        </GeneralInfoWrapper>
        <InfoWrapper>
          <Info>
            <Copy>© {getYear} ViSaver. Все права защищены.</Copy>
          </Info>
          <IconsList>
            <img width="100px" height="60px" src={sk} alt="Министерство науки и высшего образования РФ" />
            <img src={sber} alt="СберУниверситет" />
          </IconsList>
        </InfoWrapper>
      </Wrapper>
    </FooterStyled>
  );
};
