import { useCallback } from "react";

import { useParams } from "react-router-dom"

import { useActions } from "@/hooks";

export const useCreateFolder = () => {
  const { parentId } = useParams();
  const { openModal } = useActions();

  const openModalHandler = useCallback(() => openModal({ active: 'CreateFolder', data: { parentId } }), [parentId]);


  return { openModalHandler }
} 