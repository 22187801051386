import styled from 'styled-components';

export const Button = styled.button`
  background-color: #16066c;
  border-radius: 11px;
  padding: 12px 24px;
  display: flex;
  gap: 8px;
  color: white;
  width: max-content;

  :hover {
    opacity: 0.8;
  }
`;

export const Text = styled.h3`
  color: white;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
`;
