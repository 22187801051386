import { useLocation, useSearchParams } from 'react-router-dom';

import { REFERRAL_CODE } from '@/constants';

export const paramsSocialLogin = () => {
  const location = useLocation();
  const [params] = useSearchParams();
  const yandex = location.pathname.includes('yandex') ?? '';
  const vk = location.pathname.includes('vk') ?? '';
  const deviceId = params.get('device_id') || '';
  const codeVerifier = localStorage.getItem('vk_code_verifier') || '';
  const code = params.get('code') || '';
  const refferalCode = localStorage.getItem(REFERRAL_CODE) || '';

  const baseParams = {
    code,
    ...(refferalCode && { utm_ref_token: refferalCode }),
  };

  let paramsRequest;
  if (yandex) {
    paramsRequest = {
      provider: 'yandex',
      ...baseParams,
      redirect_uri: `${import.meta.env.VITE_BASE_URL}/auth/yandex/callback`,
    };
  } else if (vk) {
    paramsRequest = {
      provider: 'vk',
      ...baseParams,
      redirect_uri: `${import.meta.env.VITE_BASE_URL}/auth/vk/callback`,
      device_id: deviceId,
      code_verifier: codeVerifier,
    };
  } else {
    throw new Error('Unknown provider');
  }
  return paramsRequest;
};
