import styled from 'styled-components';

export const InDev = styled.div`
  background-image: url('./images/page-in-dev.png');
  width: 100%;
  height: 100vh;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`;
