export const Watsapp = ({ onClick }: { onClick?: () => void }) => {
  return (
    <svg onClick={onClick} width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8979 18.8501C12.7787 18.8501 8.61371 14.6838 8.6123 9.56449C8.61371 8.2668 9.67036 7.21154 10.9653 7.21154C11.0984 7.21154 11.2301 7.22275 11.3562 7.24517C11.6337 7.29142 11.8972 7.38531 12.1396 7.52685C12.1747 7.54787 12.1985 7.58151 12.2041 7.62075L12.745 11.0303C12.752 11.0696 12.7394 11.1102 12.7128 11.1397C12.4143 11.4704 12.0331 11.7086 11.6085 11.8277L11.4039 11.8852L11.481 12.0828C12.1788 13.8598 13.5999 15.2794 15.3782 15.9801L15.5758 16.0585L15.6333 15.8539C15.7524 15.4293 15.9906 15.0481 16.3214 14.7496C16.3452 14.7272 16.3774 14.716 16.4097 14.716C16.4167 14.716 16.4237 14.716 16.4321 14.7174L19.8417 15.2584C19.8823 15.2654 19.916 15.2878 19.937 15.3228C20.0771 15.5653 20.171 15.8301 20.2187 16.1076C20.2411 16.2309 20.2509 16.3612 20.2509 16.4972C20.2509 17.7935 19.1956 18.8487 17.8979 18.8501Z"
        fill="#E4E4FF"
      />
      <path
        d="M26.9763 11.8768C26.7003 8.75728 25.2708 5.8634 22.9515 3.72907C20.6182 1.58214 17.5912 0.399361 14.4254 0.399361C7.47732 0.399361 1.8241 6.05258 1.8241 13.0007C1.8241 15.3326 2.46733 17.6043 3.68514 19.583L0.969238 25.595L9.6649 24.6687C11.177 25.2881 12.7774 25.602 14.424 25.602C14.8571 25.602 15.3013 25.5796 15.747 25.5334C16.1393 25.4913 16.5359 25.4297 16.9255 25.3512C22.7441 24.1754 26.9917 19.0113 27.0254 13.068V13.0007C27.0254 12.6223 27.0086 12.2439 26.9749 11.8782L26.9763 11.8768ZM9.99983 22.0299L5.18885 22.5428L6.62528 19.3602L6.33799 18.9748C6.31697 18.9468 6.29595 18.9188 6.27213 18.8866C5.02489 17.1642 4.36622 15.1294 4.36622 13.0021C4.36622 7.45538 8.87871 2.94289 14.4254 2.94289C19.6218 2.94289 24.0264 6.99713 24.451 12.1725C24.4734 12.45 24.486 12.7288 24.486 13.0035C24.486 13.082 24.4846 13.1591 24.4832 13.2417C24.3767 17.8818 21.1353 21.8225 16.6004 22.8259C16.2543 22.903 15.8997 22.9618 15.5465 22.9996C15.1794 23.0417 14.8024 23.0627 14.4282 23.0627C13.0955 23.0627 11.8006 22.8049 10.5772 22.2947C10.4413 22.2401 10.3081 22.1812 10.1834 22.121L10.0012 22.0327L9.99983 22.0299Z"
        fill="#E4E4FF"
      />
    </svg>
  );
};
