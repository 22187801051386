import {ContainerStyled} from './Container.styled';

import {ReactNode} from 'react';

interface ContainerProps {
  children?: ReactNode;
}

export const Container = ({children}: ContainerProps) => {
  return <ContainerStyled>{children}</ContainerStyled>;
};
