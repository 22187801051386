import { QuizBlock } from './components/QuizBlock';
import { QuizBlockWrapper, QuizPageStyledForm, QuizScrollBlock } from './EditQuizPage.styled';

import { FormButtons } from '../../components';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { usePatchQuizzesMutation } from '@/api';
import { FullScreenLoader } from '@/components';
import { useActions, useHandlingError } from '@/hooks';
import { QuizApiResponse, QuizFormState, QuizResults } from '@/types';
import { formatDuration, parseDuration } from '@/utils';

const transformToQuizFetchedData = ({ quizzes }: QuizFormState): Pick<QuizApiResponse, 'data'> => ({
  data: quizzes.map(({ chapter, quiz }) => ({
    chapter: { ...chapter, start: parseDuration(chapter.start as string) },
    quiz: quiz.map(({ question, answers }) => ({
      question,
      correctAnswer: answers.find(({ isCorrect }) => isCorrect)?.answer || '',
      wrongAnswers: answers.filter((answer) => !answer.isCorrect).map(({ answer }) => answer),
    })),
  })),
});

const transformToQuizFormData = (data: QuizResults[]): QuizFormState => ({
  quizzes: data.map(({ chapter, quiz }) => ({
    chapter: { ...chapter, start: formatDuration(Math.round(chapter.start as number)) },
    quiz: quiz.map(({ correctAnswer, question, wrongAnswers }) => ({
      question,
      answers: [
        { answer: correctAnswer, isCorrect: true },
        ...wrongAnswers.map((answer) => ({ answer, isCorrect: false })),
      ],
    })),
  })),
});

export const EditQuizForm = ({
  defaultValues: { publicId, data },
  goToTime,
  videoId,
  onClose,
}: {
  defaultValues: QuizApiResponse;
  goToTime: (value: number | string) => void;
  videoId: string;
  onClose: () => void;
}) => {
  // defaultValues: QuizFormState;
  const { t } = useTranslation('pages');
  const { showNotification } = useActions();
  const [updateQuiz, { isLoading: updateStatus }] = usePatchQuizzesMutation();

  const { catchError } = useHandlingError();

  const methods = useForm<QuizFormState>({ defaultValues: transformToQuizFormData(data) });

  const handleSubmitQuiz = async (value: QuizFormState) => {
    try {
      await updateQuiz({
        videoId,
        publicId,
        body: transformToQuizFetchedData(value),
      }).unwrap();

      showNotification({
        text: t('save_quiz', { ns: 'success' }),
        severity: 'success',
      });
      methods.reset();
      onClose();
    } catch (err) {
      catchError(err, t('save_quiz_err', { ns: 'error' }));
    }
  };

  const { fields, remove, insert } = useFieldArray<QuizFormState, 'quizzes', 'key'>({
    name: 'quizzes',
    keyName: 'key',
    control: methods.control,
  });

  const handleAddQuestionBlock = (index: number) => {
    insert(index + 1, {
      chapter: { text: '', title: '', start: '' },
      quiz: [
        {
          question: '',
          answers: [
            { answer: '', isCorrect: false },
            { answer: '', isCorrect: false },
          ],
        },
      ],
    });
  };

  return (
    <FormProvider {...methods}>
      <QuizPageStyledForm onSubmit={methods.handleSubmit(handleSubmitQuiz)}>
        <QuizScrollBlock>
          {fields.map(({ key }, index) => (
            <QuizBlockWrapper key={key}>
              <QuizBlock
                updateStatus={updateStatus}
                removeBlock={() => remove(index)}
                handleAddQuestionBlock={() => handleAddQuestionBlock(index)}
                index={index}
                goToTime={goToTime}
              />
            </QuizBlockWrapper>
          ))}
        </QuizScrollBlock>
        {updateStatus && <FullScreenLoader />}
        {fields.length > 0 && (
          <FormButtons
            disabled={updateStatus || (methods.formState.errors?.quizzes?.length || 0) > 0}
            onClose={onClose}
          />
        )}
      </QuizPageStyledForm>
    </FormProvider>
  );
};
