export const PlaylistsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 14H3M11 18H3" stroke="#E4E4FF" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M18.875 14.118C20.529 15.073 21.355 15.551 21.477 16.239C21.5074 16.4113 21.5074 16.5876 21.477 16.76C21.356 17.45 20.529 17.927 18.875 18.881C17.221 19.836 16.395 20.314 15.737 20.075C15.5726 20.015 15.42 19.9266 15.286 19.814C14.75 19.364 14.75 18.41 14.75 16.5C14.75 14.59 14.75 13.635 15.286 13.186C15.42 13.0737 15.5727 12.9857 15.737 12.926C16.394 12.686 17.221 13.164 18.875 14.118Z"
        stroke="#E4E4FF"
        strokeWidth="1.5"
      />
      <path d="M3 6H13.5M20 6H17.75M20 10H9.5M3 10H5.25" stroke="#E4E4FF" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
