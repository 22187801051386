import {Avatar, AvatarMenuArrow, AvatarWrapper, Email, Name, UserNameWrapper} from './AvatarWithMenu.styled';

import {memo, useMemo, useState} from 'react';

import {Gift, RussianRuble} from 'lucide-react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {authAPI, usersAPI} from '@/api';
import {ArrowRight} from '@/components';
import DropDown from '../DropDown';
import DropDownMenuItem from '@/components/Shared/DropDown/DropDownMenu/DropDownIMenuItem';
import ExitIcon from '@/components/SvgIcons/ExitIcon';
import GuestIcon from '@/components/SvgIcons/GuestIcon';
import SettingsIcon from '@/components/SvgIcons/SettingsIcon';
import {REFRESH_TOKEN} from '@/constants';
import {useActions, useAppDispatch, useAppSelector} from '@/hooks';

const AvatarWithMenu = () => {
  const {t} = useTranslation('header');
  const {logout, showNotification} = useActions();
  const [fetchLogout] = authAPI.useLogOutUserMutation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [opened, setOpened] = useState(false);

  const {isAuth: auth, username, email} = useAppSelector((state) => state.user);

  const {data} = usersAPI.useGetUserMeQuery(undefined, {skip: !auth});

  const exit = async () => {
    try {
      await fetchLogout({refresh: localStorage.getItem(REFRESH_TOKEN) as string}).unwrap();

      logout();

      navigate('/', {replace: true});

      dispatch(authAPI.util.resetApiState());

      showNotification({
        text: t('logout', {ns: 'success'}),
        severity: 'success',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const items = useMemo(() => {
    return [
      {
        text: t('menu.ref'),
        icon: <Gift/>,
        onClick: () => navigate('/referral-program'),
      },
      {
        text: (
          <>
            {t('menu.balance')} {/*<span*/}
            {/*  style={{*/}
            {/*    backgroundColor: 'rgb(73, 59, 119)',*/}
            {/*    borderRadius: '12px',*/}
            {/*    padding: '2px 8px',*/}
            {/*    marginLeft: '3px',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {data?.balance} ₽*/}
            {/*</span>*/}
          </>
        ),
        icon: <RussianRuble/>,
        onClick: () => navigate('/balance'),
      },
      {
        text: t('menu.set'),
        icon: <SettingsIcon/>,
        onClick: () => navigate('/profile'),
      },
      {
        text: t('menu.logOut'),
        icon: <ExitIcon/>,
        onClick: exit,
      },
    ];
  }, [navigate, t]);

  return (
    <DropDown
      isOpen={opened}
      setOpened={() => setOpened(!opened)}
      items={items.map((item, index) => (
        <DropDownMenuItem key={index} icon={item.icon} text={item.text} onClick={item.onClick}/>
      ))}
    >
      <AvatarWrapper open={opened}>
        {data?.avatar ? <Avatar size="s" url={data?.avatar}/> : <GuestIcon/>}
        <UserNameWrapper>
          <Name>{`${username?.length > 20 ? username.slice(0, 20) + '...' : username}`}</Name>
          <Email>{`${email?.length > 20 ? email.slice(0, 20) + '...' : email}`}</Email>
        </UserNameWrapper>
        <AvatarMenuArrow>
          <ArrowRight style={{color: '#ffffff', position: 'relative', top: '3px'}}/>
        </AvatarMenuArrow>
      </AvatarWrapper>
    </DropDown>
  );
};

export default memo(AvatarWithMenu);
