import styled from 'styled-components';

import { theme } from '@/styles';

export const TimecodesDescriptionText = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { iframeTheme, isIframe } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgb(26, 17, 69)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : '#d8b4fe'};
`;

export const TextLink = styled.span`
  margin-left: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  color: ${({ theme: { iframeTheme, isIframe } }) =>
    isIframe && iframeTheme.current === 'light'
      ? theme.colors.blue.blue_dark
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : theme.colors.text.white_100};

  @media (max-width: 1028px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 14px;
  }
  cursor: pointer;

  :hover {
    color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgb(37 26 86)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgba(148,54,89)'
        : theme.colors.text.white_80};
  }

  transition: color 0.1s ease-in-out;
`;

export const LinkDescription = styled(TextLink)`
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: ${({ theme: { isIframe, iframeTheme } }) => isIframe && iframeTheme.current === 'light' && 'rgb(26, 17, 69)'};
`;
