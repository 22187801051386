export const Gmail = ({ onClick }: { onClick?: () => void }) => {
  return (
    <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4059_1189)">
        <path
          d="M19 0.99984H5C3.67441 1.00143 2.40356 1.52872 1.46622 2.46606C0.528882 3.4034 0.00158786 4.67424 0 5.99984L0 17.9998C0.00158786 19.3254 0.528882 20.5963 1.46622 21.5336C2.40356 22.471 3.67441 22.9983 5 22.9998H19C20.3256 22.9983 21.5964 22.471 22.5338 21.5336C23.4711 20.5963 23.9984 19.3254 24 17.9998V5.99984C23.9984 4.67424 23.4711 3.4034 22.5338 2.46606C21.5964 1.52872 20.3256 1.00143 19 0.99984ZM5 2.99984H19C19.5988 3.00102 20.1835 3.18135 20.679 3.51764C21.1744 3.85393 21.5579 4.33079 21.78 4.88684L14.122 12.5458C13.5584 13.1072 12.7954 13.4223 12 13.4223C11.2046 13.4223 10.4416 13.1072 9.878 12.5458L2.22 4.88684C2.44215 4.33079 2.82561 3.85393 3.32105 3.51764C3.81648 3.18135 4.40121 3.00102 5 2.99984ZM19 20.9998H5C4.20435 20.9998 3.44129 20.6838 2.87868 20.1212C2.31607 19.5586 2 18.7955 2 17.9998V7.49984L8.464 13.9598C9.40263 14.8961 10.6743 15.4219 12 15.4219C13.3257 15.4219 14.5974 14.8961 15.536 13.9598L22 7.49984V17.9998C22 18.7955 21.6839 19.5586 21.1213 20.1212C20.5587 20.6838 19.7956 20.9998 19 20.9998Z"
          fill="#E4E4FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4059_1189">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
