import styled from 'styled-components';

import { theme } from '@/styles';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
`;

export const VideoCardV2Wrapper = styled.div`
  max-width: 100%;
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgba(60, 64, 67, 0.07)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#1e1f21'
      : '#1a1145'};

  border-radius: 0.75rem;
  border: 1px solid
    ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.3)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgb(148 54 89 / 0.3)'
        : 'rgba(168, 85, 247, 0.3)'};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  @media (max-width: 480px) {
    padding: 8px !important;
  }
`;

export const Inner = styled.div`
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'white !important'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#2e2e30'
      : '#251a56'};
  border-radius: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? 'none' : '1rem')};
  border: 1px solid
    ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.3)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'transparent'
        : 'rgba(168, 85, 247, 0.3)'};
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 16px;
  position: relative;

  @media (max-width: 480px) {
    padding: 8px;
  }

  @media (min-width: 480px) {
    padding: 16px;
  }
`;

export const Content = styled.div`
  gap: 10px;
  display: flex;
  position: relative;

  @media (min-width: 648px) {
    gap: 28px;
  }

  @media (min-width: 1280px) {
    gap: 48px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const Video = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 50%;
  overflow: hidden;

  @media (max-width: 1024px) {
    max-width: unset;
  }
`;

export const BackButton = styled.button`
  color: ${theme.colors.white.white_100};
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`;

export const VideoPlayer = styled.div`
  display: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? 'none' : 'block')};
  position: relative;
  aspect-ratio: 16 / 9;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid
    ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.3)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgb(148 54 89 / 0.3)'
        : 'rgba(168, 85, 247, 0.3)'};
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'white'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#2e2e30'
      : '#1a1145'};
  margin-bottom: 24px;

  @media (max-width: 480px) {
    margin-bottom: 8px;
  }
`;

export const ContentBlock = styled.div`
  display: flex;
  gap: 10px;
  white-space: nowrap;
  padding-bottom: 8px;
  height: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? '505px' : 'auto')};
  flex-direction: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? 'column' : 'row')};
  overflow-x: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? 'hidden' : 'scroll')};
  overflow-y: ${({ theme }) => (theme.isIframe && theme.iframeTheme.current === 'cintera' ? 'scroll' : 'hidden')};

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(60, 64, 67, 0.07)'
        : isIframe && iframeTheme.current === 'cintera'
        ? '#2e2e30'
        : '#111'}; /* цвет дорожки */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgb(190, 190, 241)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgb(148 54 89 / 0.5)'
        : 'rgb(59 42 126)'}; /* цвет плашки */
  }
`;

export const ContentWrap = styled.div`
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgba(60, 64, 67, 0.07)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#2e2e30'
      : 'rgb(37 26 86)'};
  border-radius: 0.75rem;
  border: 1px solid
    ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.3)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgb(148 54 89 / 0.3)'
        : 'rgba(168, 85, 247, 0.3)'};
  padding: 16px;
  flex: 1;
  position: relative;
  display: flex;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  color: ${({ theme: { iframeTheme, isIframe } }) =>
    isIframe && iframeTheme.current === 'light'
      ? theme.colors.blue.blue_dark
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
