import styled from 'styled-components';

export const CustomStyled = styled.div`
  min-height: 300px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  --theme-color: ${({ theme }) =>
    theme.isIframe && theme.iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : theme.isIframe && theme.iframeTheme.current === 'light'
      ? '#000046'
      : '#D8B4FE'};
  --theme-accept-color: ${({ theme }) =>
    theme.isIframe && theme.iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : theme.isIframe && theme.iframeTheme.current === 'light'
      ? '#000046'
      : '#E4E4FF'};
`;
