import { ContentWrap } from '../../VideoPage.styled';
import { TextBlockScroll } from '../TranscriptTab/TranscriptVideo.styled';

import styled from 'styled-components';

import { FragmentTimeV2 } from '@/components/Card/VideoFragmentCard/VideoFragmentCard.styled';
import { theme, TitleH4 } from '@/styles';

export const TimecodesTitle = styled(TitleH4)<{
  isTarget?: boolean;
}>`
  background-color: ${(props) => (props.isTarget ? '#BE9F4B' : 'transparent')};
  color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light' && theme.colors.blue.blue_dark};
  font-weight: 600;

  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Text = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${({ theme: { iframeTheme, isIframe } }) =>
    isIframe && iframeTheme.current === 'light'
      ? theme.colors.blue.blue_dark
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : theme.colors.text.white_80};

  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const TitleTimecodesWrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const Timecodes = styled.ul``;

export const TimeTitle = styled.span`
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: pointer;
`;

export const TimecodesTitleTab = styled(TimecodesTitle)<{
  isTarget?: boolean;
  isIframe?: boolean;
  iframeTheme?: string;
}>`
  background-color: ${(props) =>
    props.isTarget && !props.isIframe
      ? 'rgb(73,59,119)'
      : props.isTarget && props.isIframe && props.iframeTheme === 'light'
      ? 'rgb(73 59 119 / 0.2)'
      : 'transparent'};
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 4px;
`;

export const ScrollBlock = styled(TextBlockScroll)`
  position: absolute;
  inset: 16px;
  color: ${({ theme: { iframeTheme, isIframe } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgb(26, 17, 69)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#E4E4FF'
      : ''};

  @media (max-width: 480px) {
    inset: 8px;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgb(228, 228, 255)'
        : isIframe && iframeTheme.current === 'cintera'
        ? '#2e2e30'
        : ''};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? '#d8b4fe'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgb(148 54 89 / 0.5)'
        : ''};
  }
`;

export const TimecodesContentWrap = styled(ContentWrap)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 8px;
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgba(60, 64, 67, 0.07)'
      : isIframe && iframeTheme.current === 'cintera'
      ? '#2e2e30'
      : 'rgb(37 26 86)'};

  :hover {
    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgb(228, 228, 255)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgb(92 65 75 / 37%)'
        : 'rgb(26 17 69)'};
  }

  &:hover ${TimecodesTitle} {
    color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgb(37 26 86)'
        : isIframe && iframeTheme.current === 'cintera'
        ? 'rgba(148,54,89)'
        : 'rgb(192 132 252)'};
  }

  @media (min-width: 640px) {
    flex-direction: row;
    gap: 12px;
  }

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

export const TimecodesFragmentTimeV2 = styled(FragmentTimeV2)`
  color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? theme.colors.blue.blue_dark
      : isIframe && iframeTheme.current === 'cintera'
      ? 'rgba(148,54,89)'
      : 'rgb(192 132 252)'};
`;
