import { theme } from '@/styles';
import { Button } from '../Button';

import styled, { css } from 'styled-components';

export const Wrapper = styled(Button)<{ checked: boolean; isError: boolean }>`
  width: 24px;
  height: 24px;
  transition: all 0.3s linear;
  padding: 12px;

  :hover {
    scale: 1.02;
  }

  :active {
    scale: 0.98;
  }

  ${(props) => {
    switch (props.themed) {
      case 'red':
        return css`
          /* background: ${theme.colors.red.red_light}; */

          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;

      case 'black':
        return css`
          /* background: ${theme.colors.blue.blue_20}; */

          & path {
            stroke: ${theme.colors.white.white_100};
          }
        `;
      case 'white':
        return css`
          /* background: ${theme.colors.white.white_100}; */

          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;
      case 'none':
        return css`
          /* background: transparent; */

          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;
      case 'outline':
        return css`
          border: 2px solid ${theme.colors.white.white_30};

          & path {
            stroke: ${theme.colors.white.white_30};
          }

          :hover {
            border: 2px solid ${theme.colors.white.white_80};

            & path {
              stroke: ${theme.colors.white.white_80};
            }
          }

          :active {
            & path {
              stroke: ${theme.colors.white.white_80};
            }
          }
        `;
      default:
    }
  }}

  ${(props) =>
    props.isError &&
    css`
      border: 2px solid ${theme.colors.red.red_light};

      & path {
        stroke: ${theme.colors.red.red_light};
      }
    `}
    ${(props) =>
    props.checked &&
    css`
      background: linear-gradient(to left, rgb(236, 72, 153), rgb(147, 51, 234));
      border: none;
      border-radius: 8px;

      :hover {
        background: linear-gradient(to left, rgb(219, 39, 119), rgb(126, 34, 206));
      }
    `}
`;
