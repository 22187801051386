import styled from 'styled-components';

export const ButtonArrowLeftModal = styled.button`
  width: 36px;
  height: 36px;
  color: #c084fc;

  &:hover {
    background-color: rgb(168 85 247 / 0.1);
    border-radius: 12px;
    color: rgb(216 180 254);
  }
`;

export const TitleAddVideo = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
`;
