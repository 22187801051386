import { useCallback, useEffect } from 'react';

export const useIframeHeight = () => {
  const sendDataToParent = useCallback((payload: Partial<{ frameHeight: number; currentTime: number }>) => {
    const rootElement = document.getElementById('root');
    if (!rootElement) return;
    const data = {
      frameHeight: rootElement.offsetHeight,
      ...payload,
    };

    window.parent.postMessage(data, '*');
  }, []);

  const sendHeight = useCallback(() => {
    sendDataToParent({});
  }, [sendDataToParent]);

  const sendTimeToParent = useCallback(
    (time: number) => {
      sendDataToParent({ currentTime: time });
    },
    [sendDataToParent],
  );

  useEffect(() => {
    const onDomReady = () => {
      sendHeight();
      window.addEventListener('resize', sendHeight);

      const rootElement = document.getElementById('root');
      if (rootElement) {
        const resizeObserver = new ResizeObserver(sendHeight);
        resizeObserver.observe(rootElement);

        return () => {
          resizeObserver.disconnect();
          window.removeEventListener('resize', sendHeight);
        };
      }
    };

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', onDomReady);
    } else {
      onDomReady();
    }
  }, [sendHeight]);

  return { sendTimeToParent };
};
