import { TabItem } from '../TabsMaterials/TabsMaterials.styled';

import styled from 'styled-components';

export const TabsInterface = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  background-color: transparent;
  gap: 4px;
  max-width: max-content;
`;
export const TabInterfaceItem = styled(TabItem)`
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgb(228, 228, 255)'
      : iframeTheme.current === 'cintera'
      ? 'rgb(148 54 89 / 0.5)'
      : '#3b2a7e'};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgb(228, 228, 255)'
        : iframeTheme.current === 'cintera'
        ? 'rgb(148 54 89 / 0.8)'
        : 'rgb(77, 56, 160)'};
  }
`;
