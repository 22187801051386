import {Footer} from './components/Footer';
import {ContentWrapper, Main, Wrapper} from './Main.styled';

import {Suspense, useState} from 'react';

import {isMobile} from 'react-device-detect';
import {Outlet} from 'react-router-dom';
import {BurgerMenu} from 'src/components/BurgerMenu';

import {Container, FullScreenLoader} from '@/components';
import Header from '@/components/Header';
import NavBar from '@/components/NavBar/NavBar';
import {useAppSelector, useMatchMedia} from '@/hooks';

export const MainLayout = () => {
  const activeModal = useAppSelector((state) => state.modal.active);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const isTablet = useMatchMedia('(max-width: 767px)');
  // const [isCollapsed, setIsCollapsed] = useState(isTablet);
  const [opened, setOpened] = useState(false);

  return (
    <Wrapper isFixed={Boolean(activeModal)} isMobile={isMobile}>
      {isAuth && <NavBar/>}
      <Main>
        {isTablet && <Header opened={opened} setOpened={setOpened}/>}
        <Suspense fallback={<FullScreenLoader/>}>
          <Container>
            {isTablet && opened && <BurgerMenu setOpened={setOpened}/>}
            <ContentWrapper isMenuOpen={opened}>
              <Outlet/>
              {!opened && <Footer/>}
            </ContentWrapper>
          </Container>
        </Suspense>
      </Main>
    </Wrapper>
  );
};

export default MainLayout;
