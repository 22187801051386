import {api} from '@/api';
import {
  AdminSummary,
  BaseParams,
  CreateVideoType,
  DurationResponseType,
  EditVideoType,
  GetList,
  ResponseCopyMaterial,
  TimecodesRequest,
  TimecodesResponse,
  TransformedTimecodesResponse,
  Video,
  VideoParams,
  VideoWithFragments,
} from '@/types';
import {formatDuration, getSearchParamFromURL} from '@/utils';

const PATH = 'videos';

export const videosAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getVideos: build.query<GetList<Video>, { params?: BaseParams & VideoParams }>({
      query: ({params}) => ({
        url: `${PATH}/`,
        method: 'GET',
        params,
      }),

      providesTags: (data) =>
        data
          ? [
            ...data.results.map(({publicId: id}) => ({type: 'videos' as const, id})),
            {type: 'videos', id: 'LIST'},
          ]
          : [{type: 'videos', id: 'LIST'}],
    }),

    getMyVideos: build.query<GetList<Video>, { params?: BaseParams & VideoParams } | void>({
      query: ({params} = {}) => ({
        url: `${PATH}/my/`,
        method: 'GET',
        params,
      }),

      providesTags: (data) =>
        data
          ? [
            ...data.results.map(({publicId: id}) => ({type: 'videos' as const, id})),
            {type: 'videos', id: 'LIST'},
          ]
          : [{type: 'videos', id: 'LIST'}],
    }),

    getVideoById: build.query<Video, { id?: string }>({
      query: ({id = ''}) => ({
        url: `${PATH}/${id}/`,
        method: 'GET',
      }),
      providesTags: [{type: 'videos', id: 'ONE'}],
    }),

    //получение таймкодов к видео в библиотеке и в личном кабинете
    getTimecodes: build.query<TransformedTimecodesResponse, TimecodesRequest>({
      query: ({videoId}) => ({
        url: `${PATH}/${videoId}/timecodes/`,
        method: 'GET',
      }),
      transformResponse: (response: TimecodesResponse) => {
        const timecodes =
          response.results[0] &&
          response.results[0].data.timecodes
            .filter(
              (obj, index) =>
                index ===
                response.results[0].data.timecodes.findIndex((t) => t.start === obj.start || t.text === obj.text),
            )
            .map((timecode) => ({...timecode, start: formatDuration(Math.round(timecode.start as number))}));

        const publicId = response.results[0]?.publicId; // Извлекаем publicId из результата

        return {timecodes, publicId}; // Возвращаем как объект с обновленной структурой
      },
      providesTags: (_, __, {videoId}) => [{type: 'timecodes', id: videoId}],
    }),

    //получение конспекта к видео в библиотеке и в личном кабинете
    getSummaries: build.query<AdminSummary, TimecodesRequest & { hash?: string }>({
      query: ({videoId, hash}) => ({
        url: `${PATH}/${videoId}/summaries/`,
        method: 'GET',
        params: {linkHash: hash},
      }),
      transformResponse: (response: GetList<AdminSummary>) => response.results[0],
      providesTags: (_, __, {videoId}) => [{type: 'summaries', id: videoId}],
    }),

    getFullSearchInVideo: build.query<VideoWithFragments[], Pick<Video, 'videoId'> & { query: string }>({
      query: ({videoId, query}) => ({
        url: `${PATH}/${videoId}/full_search/`,
        method: 'GET',
        params: {query},
      }),

      transformResponse: (data: VideoWithFragments[]) => data.filter((video) => video.cues.length > 0),
    }),

    getFullSearchVideosAndFragments: build.query<VideoWithFragments[], { query: string }>({
      query: ({query}) => ({
        url: `${PATH}/full_search/`,
        method: 'GET',
        params: {query},
      }),

      transformResponse: (data: VideoWithFragments[]) => {
        // const dataWithCues = data.filter((video) => video.cues.length > 0);
        return data.map((video) => ({
          ...video,
          cues: video.cues.map((cue) => ({
            ...cue,
            timestampLink: getSearchParamFromURL(String(cue.startsFrom), 't'),
          })),
        }));
      },
    }),

    createVideo: build.mutation<Video, CreateVideoType>({
      query: (body) => ({
        url: `${PATH}/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) =>
        result
          ? [{type: 'videos', id: result.publicId}, {type: 'videos', id: 'LIST'}, 'userMe']
          : [{type: 'videos', id: 'LIST'}, 'userMe'],
    }),

    editVideo: build.mutation<Video, EditVideoType>({
      query: ({publicId, ...body}) => ({
        url: `${PATH}/${publicId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result) =>
        result
          ? [{type: 'videos', id: result.publicId}, {type: 'videos', id: 'LIST'}, 'userMe']
          : [{type: 'videos', id: 'LIST'}, 'userMe'],
    }),

    moveVideo: build.mutation<any, { public_id: string; parent_id: string }>({
      query: ({public_id, parent_id}) => ({
        url: `${PATH}/${public_id}/move/`,
        method: 'POST',
        body: {parent_id},
      }),
      invalidatesTags: (result) =>
        result
          ? [
            {type: 'videos', id: result.publicId},
            {type: 'videos', id: 'LIST'},
            {type: 'folder', id: result.parent_id},
            {type: 'folders'}
          ]
          : [{type: 'videos', id: 'LIST'}],
    }),

    getLinkUploadImage: build.query<{ thumbnailUrl: string }, {
      publicId: string;
      thumbnail_url?: Blob
    }>({
      query: ({publicId, thumbnail_url}) => ({
        url: `${PATH}/${publicId}/upload-thumbnail/`,
        method: 'POST',
        body: thumbnail_url,
      }),
    }),

    deleteVideo: build.mutation<string, { id: string }>({
      query: ({id}) => ({
        url: `${PATH}/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, {id}) =>
        result
          ? [{type: 'playlist'}, {type: 'videos', id}, 'playlists']
          : [{type: 'playlist', id: 'one'}, {type: 'videos', id: 'LIST'}, 'playlists'],
    }),

    getDurationVideo: build.query<number, { url: string }>({
      query: (params) => ({
        url: `${PATH}/get-duration/`,
        method: 'GET',
        params,
      }),
      transformResponse: ({duration}: DurationResponseType) => Math.ceil(duration / 60),
    }),

    copyMaterialVideo: build.query<string, { publicId: string; content: string; type: string }>({
      query: ({publicId, content, type}) => ({
        url: `${PATH}/${publicId}/buffer-content/?content=${content}&type=${type}`,
        method: 'GET',
      }),
      transformResponse: (data: ResponseCopyMaterial) => data.content,
    }),
  }),
});

export const {
  useLazyCopyMaterialVideoQuery,
  useLazyGetVideosQuery,
  useGetVideosQuery,
  useGetTimecodesQuery,
  useGetMyVideosQuery,
  useDeleteVideoMutation,
  useLazyGetFullSearchInVideoQuery,
  useLazyGetDurationVideoQuery,
  useCreateVideoMutation,
  useGetVideoByIdQuery,
  useGetSummariesQuery,
  useLazyGetSummariesQuery,
  useEditVideoMutation,
  useLazyGetLinkUploadImageQuery,
  useMoveVideoMutation,
  useGetFullSearchVideosAndFragmentsQuery,
} = videosAPI;
