import { createSlice } from '@reduxjs/toolkit';

type materialsSliceProps = {
  openedMaterialTab: number;
  materialsCount: number;
};

const initialState: materialsSliceProps = {
  openedMaterialTab: 1,
  materialsCount: 0,
};

export const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setMaterialTab: (state, { payload }: { payload: number }) => {
      state.openedMaterialTab = payload;
    },
    setMaterialsCount: (state, { payload }: { payload: number }) => {
      state.materialsCount = payload;
    },
  },
});

export default materialsSlice.reducer;
