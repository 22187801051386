import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const ReviewsStyled = styled.section`
  padding: 96px 0;

  @media screen and (max-width: 768px) {
    padding: 50px 0px;
  }
`;

export const ReviewsWrapper = styled.div`
  display: flex;
  gap: 48px;
  flex-direction: column;
`;

export const TitleWrapper = styled(motion.div)`
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 48px;
  line-height: 57px;
  color: transparent;
  font-weight: 600;
  background-clip: text;
  user-select: none;
  background-image: linear-gradient(to right, #f3e8ff, #ec4899);

  @media screen and (max-width: 1024px) {
    font-size: 38px;
    line-height: 38px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const CardsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 22px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Card = styled.div`
  position: relative;
  background-color: #251a56;
  border-radius: 16px;
  padding: 22px;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 100%;

  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  border: 1px solid ${theme.colors.border.purple_500_20};

  &:hover {
    border-color: ${theme.colors.border.purple_500_40};
  }
`;

export const Author = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  color: #e4e4ff;
`;

export const JobTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e6ddf0;
`;

export const FeedbackText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e6ddf0;
`;

export const CardContentWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const Description = styled.p`
  font-size: 24px;
  line-height: 32px;
`;

export const AuthorInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
