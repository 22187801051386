import {
  CounterOverlay,
  EmptyImageContent,
  ImagesBlock,
  StyledLink,
  Text,
  Title,
  TitleWrapper,
  VideoImage,
  Wrapper,
} from './FolderCard.styled';

import {useLocation} from 'react-router-dom';

import {ItemDropDown} from '@/components';
import {useActions} from '@/hooks';
import {Folder} from '@/types';
import {FolderIcon} from '@/components/SvgIcons/FolderIcon';

export const FolderCard = ({folder, to}: { folder: Folder; to: string }) => {
  const {openModal} = useActions();
  const {state} = useLocation();
  const itemsDropDown = [
    {
      id: 1,
      title: 'Переименовать',
      styles: {color: '#FFFFFF', fontSize: '16px', fontWeight: '400'},
      onClick: () => {
        openModal({active: 'RenameFolder', data: {id: folder.publicId, title: folder.title}});
      },
    },
    {
      id: 2,
      title: 'Переместить',
      styles: {color: '#FFFFFF', fontSize: '16px', fontWeight: '400'},
      onClick: () => {
        openModal({
          active: 'MoveFolder',
          data: {id: folder.publicId, title: folder.title},
        });
      },
    },
    {
      id: 3,
      title: 'Удалить',
      styles: {color: '#D33E8B', fontSize: '16px', fontWeight: '400'},
      onClick: () => {
        openModal({active: 'DeleteFolder', data: {id: folder.publicId, parentId: folder.parent}});
      },
    },
  ];

  const displayedCount = 4;
  const hiddenCount = folder.videos.length - displayedCount;

  return (
    <Wrapper>
      <StyledLink to={to} state={{...state, [`${folder.publicId}`]: folder.title}}>
        {folder.videos.length !== 0 ? (
          <ImagesBlock count={folder.videos.length}>
            {folder.videos.slice(0, 4).map((video, index) => (
              <div key={video.publicId}>
                <VideoImage index={index} count={folder.videos.length} key={index} src={video.thumbnailUrl}/>
                {index === 3 && folder.videos.length > 4 && <CounterOverlay>+{hiddenCount}</CounterOverlay>}
              </div>
            ))}
          </ImagesBlock>
        ) : (
          <EmptyImageContent>
            <FolderIcon/>
          </EmptyImageContent>
        )}
        <TitleWrapper>
          <Title>{folder.title}</Title>
          <Text>Изменён {folder.modified.slice(0, 10)}</Text>
        </TitleWrapper>
      </StyledLink>
      <ItemDropDown items={itemsDropDown}/>
    </Wrapper>
  );
};
