import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import ReactPlayer from 'react-player';
import { useSearchParams } from 'react-router-dom';

import { StubBlock } from '@/components/VideoPlayer/FileVideoPlayer/FileVideoPlayer.styled';
import { ConvertationStatusType } from '@/types';

export const FileVideoPlayer = forwardRef<
  { goToTime: (time: number) => void },
  {
    url: string;
    startsFrom?: number;
    height: string;
    setCurrentTime?: (time: number) => void;
    convertationStatus: ConvertationStatusType;
  }
>(({ url, height, startsFrom, setCurrentTime, convertationStatus }, ref) => {
  const iframe = useRef<ReactPlayer>(null);
  const [searchParams] = useSearchParams();
  const hasInitialized = useRef(false);

  const goToTimeFunc = useCallback(async (time: number) => {
    if (iframe.current) {
      iframe.current.seekTo(time, 'seconds');
      // const player = iframe.current.getInternalPlayer();
      // if (player?.play) {
      //   player.play();
      // }
    }
  }, []);

  const onPlayerReady = useCallback(() => {
    if (hasInitialized.current) return;

    hasInitialized.current = true;

    const tParam = searchParams.get('t');
    const startTime = tParam ? parseInt(tParam.replace(/\D/g, ''), 10) : startsFrom;

    if (startTime !== undefined && !isNaN(startTime)) {
      goToTimeFunc(startTime);
    }
  }, [searchParams, startsFrom, goToTimeFunc]);

  useImperativeHandle(
    ref,
    () => ({
      goToTime: goToTimeFunc,
    }),
    [goToTimeFunc],
  );

  return (
    <div style={{ height: height }}>
      {convertationStatus !== null && convertationStatus === 'IN_PROGRESS' ? (
        <StubBlock>Запущен процесс конвертации видео</StubBlock>
      ) : (
        <ReactPlayer
          ref={iframe}
          url={url}
          playing={false}
          controls
          width="100%"
          height={height}
          progressInterval={1000}
          onReady={onPlayerReady}
          onProgress={({ playedSeconds }) => {
            setCurrentTime?.(playedSeconds);
          }}
        />
      )}
    </div>
  );
});

FileVideoPlayer.displayName = 'FileVideoPlayer';
