import { TranscriptsForm } from './EditTranscript';
import { TextTranscript, TitleTranscript, TitleTranscriptWrapper } from './TranscriptVideo.styled';

import { MATERIAL_POLLING_INTERVAL, useEditModeContext } from '../../../model';
import { ContentWrap, VideoCardV2Wrapper } from '../../VideoPage.styled';
import { ScrollBlock, Text } from '../TimecodesTab/TimecodesTab.styled';

import { memo, useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import { useGetTranscriptsQuery } from '@/api';
import { useHandlingError } from '@/hooks';
import { StatusType } from '@/types';

export const TranscriptTab = memo(
  ({
    videoId,
    transcriptStatus,
    goToTime,
  }: {
    videoId: string;
    transcriptStatus: StatusType | undefined;
    goToTime?: (time: number | string) => void;
  }) => {
    const { t } = useTranslation('components');
    const { catchError } = useHandlingError();

    const [shouldPolling, setShouldPolling] = useState(() => transcriptStatus === 'IN_PROGRESS');

    const { isEditMode, toggleEditMode } = useEditModeContext();

    const { data, error, isSuccess } = useGetTranscriptsQuery(
      { videoId },
      {
        skip: transcriptStatus === 'NOT_ORDERED' || transcriptStatus === 'FAILED',
        pollingInterval: shouldPolling ? MATERIAL_POLLING_INTERVAL : undefined,
      },
    );

    useEffect(() => {
      if (data) {
        setShouldPolling(false);
      }

      () => setShouldPolling(false);
    }, [data]);

    useEffect(() => {
      if (error) {
        catchError(error);
      }
    }, [error]);

    if (!isSuccess) {
      return null;
    }

    if (!data) {
      return (
        <>
          <Text> Видео обрабатывается </Text>
          <div style={{ margin: '0 auto' }}>
            <CircularProgress color="secondary" size={40} />
          </div>
        </>
      );
    }

    if (isEditMode) {
      return (
        <VideoCardV2Wrapper>
          <TranscriptsForm videoId={videoId} transcript={data} goToTime={goToTime} onClose={toggleEditMode} />
        </VideoCardV2Wrapper>
      );
    }

    return (
      <VideoCardV2Wrapper>
        <TitleTranscriptWrapper>
          <TitleTranscript>{t('title_transcript')}</TitleTranscript>
        </TitleTranscriptWrapper>
        <ContentWrap>
          {/*{isLoading && (*/}
          {/*  <div style={{ margin: '0 auto' }}>*/}
          {/*    <CircularProgress color="secondary" size={40} />*/}
          {/*  </div>*/}
          {/*)}*/}
          {data.data.cues.length > 0 && (
            <ScrollBlock>
              {data.data.cues.map((item) => (
                <TextTranscript key={item.start}> {item.text}</TextTranscript>
              ))}
            </ScrollBlock>
          )}
        </ContentWrap>
      </VideoCardV2Wrapper>
    );
  },
);

TranscriptTab.displayName = 'TranscriptVideo';
