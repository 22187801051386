import { UploadIcon } from '../../../assets/UploadIcon';

import {
  GradientBackground,
  Info,
  InnerContainer,
  LabelTextPurple,
  LabelTextPurple2,
  LabelTextPurple3,
  LoadingWrapper,
  OuterContainer,
  ProgressBox,
  ProgressInfoBlock,
  StyledBox,
  StyledLinearProgress,
  UploadContainer,
} from '@/components/UploadProgress';

export const AnimatedComponent = ({ progress }: { progress: number }) => {
  return (
    <UploadContainer>
      <StyledBox>
        <OuterContainer>
          <GradientBackground />
          <InnerContainer>
            <UploadIcon />
          </InnerContainer>
        </OuterContainer>
        <LoadingWrapper>
          <ProgressInfoBlock>
            <LabelTextPurple2>Загрузка и обработка видео</LabelTextPurple2>
            <LabelTextPurple>{`${Math.round(progress)}%`}</LabelTextPurple>
          </ProgressInfoBlock>
          <ProgressBox>
            <StyledLinearProgress variant="determinate" value={progress} />
          </ProgressBox>
        </LoadingWrapper>
      </StyledBox>
      <Info>
        <LabelTextPurple3>Пожалуйста, не закрывайте окно</LabelTextPurple3>
        <LabelTextPurple3>до завершения загрузки</LabelTextPurple3>
      </Info>
    </UploadContainer>
  );
};
