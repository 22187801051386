import styled, {css} from 'styled-components';

export const Item = styled.button<{ isCollapsed?: boolean; isActive?: boolean }>`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
    color: ${({isActive}) => (isActive ? '#D8B4FE' : 'rgba(255, 255, 255, 0.8)')};
    background-color: ${({isActive}) => (isActive ? 'rgba(168, 85, 247, 0.2)' : 'transparent')};

    &:hover {
        background-color: ${({isActive}) => (isActive ? '' : 'rgba(255, 255, 255, 0.05)')};
        color: ${({isActive}) => (isActive ? '#D8B4FE' : 'white')};
    }

    ${({isCollapsed}) =>
            isCollapsed
                    ? css`
                        padding: 0.5rem;
                    `
                    : css`
                        padding: 8px 16px;
                    `}
`;

export const ButtonContent = styled.div<{ isCollapsed?: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    min-width: 0;
    ${({isCollapsed}) =>
            isCollapsed &&
            css`
                justify-content: center;
            `}
`;

export const NavItemText = styled.span`
    font-size: 16px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
