import { QuizAnswersWrapper, QuizBlockWrapper, QuizTitleEditWrapper } from './QuizBlock.styled';

import { TitleTimeWrapper } from '../../../../components';
import { QuizVariant } from '../QuizVariant';

import { useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';

import { Button } from '@/components';
import MyInput from '@/components/Shared/MyInput/MyInput';
import PlayIcon from '@/components/SvgIcons/PlayIcon';
import PlusBigIconBlack from '@/components/SvgIcons/PlusBigIconBlack';
import TrashIcon from '@/components/SvgIcons/TrashIcon';
import { theme } from '@/styles';
import { QuizFormState } from '@/types';

export const QuizBlock = ({
  index,
  goToTime,
  removeBlock,
  handleAddQuestionBlock,
}: {
  index: number;
  goToTime: (value: number | string) => void;
  updateStatus: boolean;
  removeBlock: () => void;
  handleAddQuestionBlock: () => void;
}) => {
  // const { t } = useTranslation('pages');
  const [mouseDeleteOver, setOnMouseDeleteOver] = useState(false);
  const [mousePlayOver, setOnMousePlayOver] = useState(false);

  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<QuizFormState>();

  const registerWithMask = useHookFormMask(register);

  const { fields } = useFieldArray<QuizFormState, `quizzes.${number}.quiz`, 'key'>({
    name: `quizzes.${index}.quiz`,
    keyName: 'key',
  });

  return (
    <>
      {fields.map(({ key }, ind) => (
        <QuizBlockWrapper
          style={{
            border:
              errors.quizzes?.[index]?.chapter?.start ||
              errors.quizzes?.[index]?.chapter?.title ||
              errors.quizzes?.[index]?.quiz
                ? `${theme.colors.red.red_light} 1px solid`
                : '1px solid #62548b',
            borderRadius: '8px',
          }}
          key={key}
        >
          <TitleTimeWrapper>
            <Button
              onMouseOut={() => setOnMousePlayOver(false)}
              onMouseOver={() => setOnMousePlayOver(true)}
              variant="withIcon"
              themed="transparent"
              onClick={() => goToTime(getValues(`quizzes.${index}.chapter.start`) as string)}
            >
              <PlayIcon width="24" height="24" fill={!mousePlayOver ? '#E4E4FF' : '#9d9def'} />
            </Button>
            <div style={{ display: 'flex', flexShrink: 1, width: '100%' }}>
              <MyInput
                {...registerWithMask(`quizzes.${index}.chapter.start`, '99:99:99', {
                  required: true,
                  pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
                  // validate: (value, formValue) => {
                  //   const previousStart = formValue.quizzes?.[index - 1]?.chapter?.start;
                  //   const nextStart = formValue.quizzes?.[index + 1]?.chapter?.start;
                  //
                  //   if (previousStart && value < previousStart) {
                  //     return false;
                  //   }
                  //   if (nextStart && value > nextStart) {
                  //     return false;
                  //   }
                  //   return true;
                  // },
                })}
                placeholder="00:00:00"
                style={{
                  padding: '12px 0px',
                  overflow: 'hidden',
                  border: 'none',
                  outline: 'none',
                  color: errors.quizzes?.[index]?.chapter?.start ? `${theme.colors.red.red_light}` : '#E4E4FF',
                }}
                error={errors.quizzes?.[index]?.chapter?.start}
                showErrorMessage={true}
              />
            </div>
            <Button
              onMouseOut={() => setOnMouseDeleteOver(false)}
              onMouseOver={() => setOnMouseDeleteOver(true)}
              variant="withIcon"
              themed="transparent"
              onClick={removeBlock}
            >
              <TrashIcon width="24" height="24" fill={!mouseDeleteOver ? '#E4E4FF' : '#9d9def'} />
            </Button>
          </TitleTimeWrapper>
          <QuizBlockWrapper key={key}>
            <QuizTitleEditWrapper key={key}>
              <MyInput
                {...register(`quizzes.${index}.quiz.${ind}.question`, { required: true })}
                error={errors.quizzes?.[index]?.quiz?.[ind]?.question}
                showErrorMessage={true}
                style={{
                  padding: '12px 13px',
                  overflow: 'hidden',
                  border: 'none',
                  outline: 'none',
                  backgroundColor: '#1A1145',
                  color: '#E4E4FF',
                  borderRadius: '0px',
                }}
              />
            </QuizTitleEditWrapper>

            <QuizAnswersWrapper>
              <QuizVariant questionIndex={ind} index={index} />
            </QuizAnswersWrapper>
          </QuizBlockWrapper>
        </QuizBlockWrapper>
      ))}
      <Button
        style={{
          position: 'absolute',
          padding: '0',
          background: '#3B2A7E',
          border: 'none',

          transform: 'translate(-50%,-25%)',
          left: '50%',
          height: '36px',
          width: '44px',
          zIndex: 1,
        }}
        onClick={handleAddQuestionBlock}
        // disabled={updateStatus}
      >
        <PlusBigIconBlack width={20} height={20} />
      </Button>
    </>
  );
};
