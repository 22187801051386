import {TitleAddVideo} from './AddVideo.styled';
import {SelectVideo} from './components';
import {Tariffs} from './components/Tariffs';
import {AnimatedComponent} from './components/Uploading';

import {useAddVideo} from '../model/hooks/useAddVideo';

import {Inner, Modal} from '@/components/Shared/Modals/Modal';

export function AddVideo() {
  const {
    videoLinkValue,
    selectedFile,
    closeHandler,
    services,
    currentStep,
    // setCurrentStep,
    setSelectedFolder,
    progress,
    duration,
    saveVideo,
    selectedFolder,
    validLink,
    folders,
    videoLinkHandler,
    addVideo,
    setVideoLinkValue,
    setShowInfo,
    isLinkError,
    isError,
    incorrectVideoFormat,
    fileInputRef,
    onFileChange,
    handleDrop,
    handleDragOver,
    t,
  } = useAddVideo();

  const renderStepContent = () => {
    switch (currentStep) {
      case 'input':
        return (
          <SelectVideo
            selectedFile={selectedFile}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            videoLinkValue={videoLinkValue}
            addVideo={addVideo}
            setVideoLinkValue={setVideoLinkValue}
            videoLinkHandler={videoLinkHandler}
            setShowInfo={setShowInfo}
            folders={folders}
            handleDrop={handleDrop}
            fileInputRef={fileInputRef}
            onFileChange={onFileChange}
            handleDragOver={handleDragOver}
            isLinkError={isLinkError}
            isError={isError}
            incorrectVideoFormat={incorrectVideoFormat}
            closeHandler={closeHandler}
            t={t}
          />
        );

      case 'uploading':
        return <AnimatedComponent progress={progress}/>;

      case 'select-tariff':
        return (
          <Tariffs
            validLink={validLink ?? videoLinkValue}
            selectedFolder={selectedFolder}
            videoLinkValue={videoLinkValue ?? ''}
            videoName={selectedFile?.name ?? ''}
            duration={duration}
            closeHandler={closeHandler}
            saveVideo={saveVideo}
            services={services}
          />
        );
    }
  };

  return (
    <Modal modal={'AddVideo'} onClose={closeHandler} closeButton>
      <Inner modal={'AddVideo'}>
        <div>
          {/*{currentStep === 'select-tariff' && (*/}
          {/*  <ButtonArrowLeftModal onClick={() => setCurrentStep('input')}>*/}
          {/*    <ArrowLeftModal />*/}
          {/*  </ButtonArrowLeftModal>*/}
          {/*)}*/}
          <TitleAddVideo>
            {currentStep === 'input' && 'Добавить видео'}
            {currentStep === 'select-tariff' && 'Выберите тариф'}
          </TitleAddVideo>
        </div>
        {renderStepContent()}
      </Inner>
    </Modal>
  );
}
