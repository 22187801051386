export const IframeIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9258 4.19466C12.007 3.78849 11.7436 3.39338 11.3375 3.31214C10.9313 3.23091 10.5362 3.49432 10.4549 3.90049L11.9258 4.19466ZM8.07399 15.8053C7.99276 16.2114 8.25617 16.6065 8.66234 16.6878C9.06851 16.769 9.46363 16.5056 9.54486 16.0994L8.07399 15.8053ZM4.71135 14.0554C5.0063 14.3463 5.48117 14.3429 5.77199 14.048C6.06281 13.753 6.05946 13.2782 5.76451 12.9873L4.71135 14.0554ZM1.6665 9.99996L1.13993 9.4659C0.99698 9.60685 0.916504 9.79921 0.916504 9.99996C0.916504 10.2007 0.99698 10.3931 1.13993 10.534L1.6665 9.99996ZM5.76451 7.01258C6.05946 6.72176 6.06281 6.2469 5.77199 5.95195C5.48117 5.657 5.0063 5.65365 4.71135 5.94447L5.76451 7.01258ZM14.2392 12.9336C13.9422 13.2223 13.9354 13.6971 14.2241 13.9941C14.5128 14.2911 14.9877 14.2979 15.2847 14.0092L14.2392 12.9336ZM18.3334 9.99994L18.8561 10.5377C19.0001 10.3978 19.0819 10.206 19.0833 10.0053C19.0848 9.80451 19.0057 9.61157 18.8637 9.46961L18.3334 9.99994ZM15.2923 5.89818C14.9994 5.60529 14.5245 5.60529 14.2316 5.89818C13.9387 6.19108 13.9387 6.66595 14.2316 6.95884L15.2923 5.89818ZM10.4549 3.90049L8.07399 15.8053L9.54486 16.0994L11.9258 4.19466L10.4549 3.90049ZM5.76451 12.9873L2.19308 9.4659L1.13993 10.534L4.71135 14.0554L5.76451 12.9873ZM2.19308 10.534L5.76451 7.01258L4.71135 5.94447L1.13993 9.4659L2.19308 10.534ZM15.2847 14.0092L18.8561 10.5377L17.8106 9.46214L14.2392 12.9336L15.2847 14.0092ZM18.8637 9.46961L15.2923 5.89818L14.2316 6.95884L17.803 10.5303L18.8637 9.46961Z"
        fill="rgb(228, 228, 255)"
      />
    </svg>
  );
};
