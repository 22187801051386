export const VideosIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 8.14286V15.8571C21 18.6975 18.6975 21 15.8571 21H8.14286C5.30254 21 3 18.6975 3 15.8571V8.14286C3 5.30254 5.30254 3 8.14286 3H15.8571C18.6975 3 21 5.30254 21 8.14286Z"
        stroke="#E4E4FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7007 12.148L14.2355 13.9367C14.4014 14.0471 14.5 14.2258 14.5 14.4164C14.5 14.607 14.4014 14.7857 14.2355 14.8961L11.7007 16.8522C11.4867 17.0086 11.1989 17.0437 10.9493 16.944C10.6996 16.8442 10.5275 16.6254 10.5 16.3726V12.6251C10.5284 12.3727 10.7008 12.1547 10.9503 12.0555C11.1998 11.9564 11.4871 11.9918 11.7007 12.148V12.148Z"
        stroke="#E4E4FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 8.89286C21.4142 8.89286 21.75 8.55707 21.75 8.14286C21.75 7.72864 21.4142 7.39286 21 7.39286V8.89286ZM12 7.39286C11.5858 7.39286 11.25 7.72864 11.25 8.14286C11.25 8.55707 11.5858 8.89286 12 8.89286V7.39286ZM3 7.39286C2.58579 7.39286 2.25 7.72864 2.25 8.14286C2.25 8.55707 2.58579 8.89286 3 8.89286V7.39286ZM12 8.89286C12.4142 8.89286 12.75 8.55707 12.75 8.14286C12.75 7.72864 12.4142 7.39286 12 7.39286V8.89286ZM11.25 8.14286C11.25 8.55707 11.5858 8.89286 12 8.89286C12.4142 8.89286 12.75 8.55707 12.75 8.14286H11.25ZM12.75 3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3H12.75ZM21 7.39286H12V8.89286H21V7.39286ZM3 8.89286H12V7.39286H3V8.89286ZM12.75 8.14286V3H11.25V8.14286H12.75Z"
        fill="#E4E4FF"
      />
    </svg>
  );
};
