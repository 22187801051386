import { SummaryEditor } from './EditSummary';
import { CustomStyled } from './SummaryTab.styled';

import { MATERIAL_POLLING_INTERVAL, useEditModeContext } from '../../../model';
import { ContentWrap, VideoCardV2Wrapper } from '../../VideoPage.styled';
import { ScrollBlock, Text } from '../TimecodesTab/TimecodesTab.styled';
import { TitleTranscript, TitleTranscriptWrapper } from '../TranscriptTab/TranscriptVideo.styled';

import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import { useGetSummariesQuery } from '@/api';
import { useHandlingError } from '@/hooks';
import { StatusType } from '@/types';

import './styles/HTMLSummaryStyles.css';
import './styles/customStyles.css';

export const SummaryTab = ({ videoId, summaryStatus }: { videoId: string; summaryStatus: StatusType }) => {
  const { t } = useTranslation('components');

  const [shouldPolling, setShouldPolling] = useState(() => summaryStatus === 'NOT_STARTED');

  const { catchError } = useHandlingError();

  const { data, isSuccess, error } = useGetSummariesQuery(
    { videoId },
    {
      skip: summaryStatus === 'NOT_ORDERED' || summaryStatus === 'FAILED',
      pollingInterval: shouldPolling ? MATERIAL_POLLING_INTERVAL : undefined,
    },
  );

  const { isEditMode, toggleEditMode } = useEditModeContext();

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setShouldPolling(false);
    }

    () => setShouldPolling(false);
  }, [data]);

  if (!isSuccess) {
    return null;
  }

  if (!data) {
    return (
      <>
        <Text> Видео обрабатывается </Text>
        <div style={{ margin: '0 auto' }}>
          <CircularProgress color="secondary" size={40} />
        </div>
      </>
    );
  }

  if (isEditMode) {
    return (
      <VideoCardV2Wrapper>
        <SummaryEditor videoId={videoId} summary={data} onClose={toggleEditMode} />
      </VideoCardV2Wrapper>
    );
  }
  return (
    <VideoCardV2Wrapper>
      <TitleTranscriptWrapper>
        <TitleTranscript>{t('title_summary')}</TitleTranscript>
      </TitleTranscriptWrapper>
      <ContentWrap>
        {/*{isLoading && (*/}
        {/*  <div style={{ margin: '0 auto' }}>*/}
        {/*    <CircularProgress color="secondary" size={40} />*/}
        {/*  </div>*/}
        {/*)}*/}

        <ScrollBlock>
          {data.html && <CustomStyled className="html-summary" dangerouslySetInnerHTML={{ __html: data.html }} />}
        </ScrollBlock>
      </ContentWrap>
    </VideoCardV2Wrapper>
  );
};
