import { api } from '@/api/api';
import { ReferralData } from '@/types/referralTypes';

export const referralAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getReferralData: build.query<ReferralData, void>({
      query: () => ({
        url: '/users/referrals_data/',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetReferralDataQuery } = referralAPI;
