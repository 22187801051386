import { Copy } from 'lucide-react';

import {
  CopyButton,
  ReferralLinkInputWrapper,
  StyledInput,
} from '@/pages/ReferalProgramPage/ReferralLinkInput/ReferralLinkInput.styled';

interface ReferralLinkInputProps {
  referralLink: string;
  onClick?: () => void;
}

export const ReferralLinkInput = ({ referralLink, onClick }: ReferralLinkInputProps) => {
  return (
    <ReferralLinkInputWrapper>
      <StyledInput value={referralLink} />
      <CopyButton onClick={onClick}>
        <Copy width={16} height={16} />
      </CopyButton>
    </ReferralLinkInputWrapper>
  );
};
