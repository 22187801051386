import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;
  background-color: #251a56;
  max-width: 598px;
  width: 100%;
  max-height: 708px;
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;
  gap: 30px;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding: 8px;
    padding-bottom: 0;
  }
`;

export const QuizPageStyledForm = styled.form``;
export const QuizBlockWrapper = styled.div`
  width: 98%;
  border: 1px solid rgb(98, 84, 139);
  border-radius: 12px;
  position: relative;
`;

export const QuizScrollBlock = styled.div`
  overflow-y: scroll;
  height: 588px;
  gap: 20px;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #1a1145;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #62548b;
  }
`;
