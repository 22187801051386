import {NavBarItem} from './components';
import AvatarWithMenu from './components/AvatarMenu/AvatarWithMenu';
import {AvatarWrapper, ContentContainer, NavBarContentWrapper, StyledAside} from './NavBar.styled';

import {FolderIcon} from '../SvgIcons/FolderIcon';
import {HomeIcon} from '../SvgIcons/HomeIcon';
import {VideosIcon} from '../SvgIcons/VideosIcon';

import {Link, useNavigate} from 'react-router-dom';

import {BalanceInfo} from '@/components/BalanceInfo';
import visaver from "../../../public/images/Visaver_Logo.png";

const NavBar = () => {
  const navigate = useNavigate();
  const icons = [
    {id: 1, icon: <HomeIcon/>, title: 'Главный экран', onClick: () => handlePlaylistSelect(), to: '/'},
    {id: 2, icon: <VideosIcon/>, title: 'Все видео', onClick: () => handlePlaylistSelect(), to: '/videos'},
    // { id: 3, icon: <PlaylistsIcon />, title: 'Плейлисты', onClick: () => handlePlaylistSelect(), to: '/playlists' },
    {id: 4, icon: <FolderIcon/>, title: 'Папки', onClick: () => handlePlaylistSelect(), to: '/folders'},
  ];

  const handlePlaylistSelect = (id?: string) => {
    navigate(id ? `/playlists/${id}` : '/');
  };

  return (
    <StyledAside>
      <ContentContainer>
        <NavBarContentWrapper>
          <Link to="/">
            <img width={160} height={35} src={visaver as string} alt="VisaverLogo"/>
          </Link>

          <div>
            {icons.map(({id, icon, title, onClick, to}) => (
              <NavBarItem key={id} onClick={onClick} to={to} title={title} icon={icon}/>
            ))}
          </div>
        </NavBarContentWrapper>

        <AvatarWrapper>
          <BalanceInfo onClick={() => navigate('/balance')}/>
          <AvatarWithMenu/>
        </AvatarWrapper>
      </ContentContainer>
    </StyledAside>
  );
};

export default NavBar;
