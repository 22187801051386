import styled from 'styled-components';

export const HeaderStyled = styled.header`
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: #1a1145;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 70px;

  @media (max-width: 725px) {
    width: 100%;
  }
`;
