import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TextError = styled.span`
  color: #ef7a80;
  font-size: 13px;
  font-weight: 400;
`;

export const LinkVK = styled(Link)`
  text-decoration: underline;
  color: #ef7a80;

  :hover {
    color: #62548b;
  }
`;

export const LinkYT = styled(LinkVK)`
  margin-left: 5px;
  margin-right: 2px;
`;

export const ErrorTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
