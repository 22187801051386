import { ButtonsWrapper, Form, Inner, Title } from './RemoveUser.styled';

import Button from '../../Buttons/Button/Button';
import MyInput from '../../MyInput/MyInput';
import { Modal } from '../Modal';

import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usersAPI } from '@/api';
import { useActions } from '@/hooks';

export const RemoveUser = () => {
  const { t } = useTranslation('components');
  const navigate = useNavigate();

  const { closeModal, logout, showNotification } = useActions();

  const [isConfirmModal, setIsConfirm] = useState(false);

  const [deleteUser, { isLoading }] = usersAPI.useDeleteUserMutation();
  // const [deleteUser, result] = api.useDeleteUserMutation();

  const deletePL = async (currentPassword: string) => {
    try {
      await deleteUser({ currentPassword }).unwrap();

      closeModal();
      logout();
      navigate('/');

      showNotification({
        text: t('del_acc', { ns: 'success' }),
        severity: 'success',
      });
    } catch (e) {
      showNotification({
        text: t('del_acc', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{ currentPassword: string }>();

  const onSubmit = async ({ currentPassword }: { currentPassword: string }) => {
    try {
      await deletePL(currentPassword);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  // comejo8167@fna6.com

  return (
    <Modal modal={'RemoveUser'} closeButton>
      <Inner>
        {isConfirmModal ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Title>{t('del_acc', { ns: 'modal' })}</Title>
            <MyInput
              required
              label={t('form.cur_password')}
              type="password"
              {...register('currentPassword', {
                required: { value: true, message: t('req_field', { ns: 'error' }) },
                minLength: { value: 6, message: t('min_char', { ns: 'error', length: 6 }) },
                maxLength: { value: 128, message: t('max_char', { ns: 'error', length: 128 }) },
              })}
              placeholder={t('form.cur_password')}
              error={errors.currentPassword}
            />
            <ButtonsWrapper style={{ marginTop: '20px' }}>
              <Button themed={isLoading ? 'disabled' : 'red'} type="submit" disabled={isLoading}>
                {t('form.del')}
              </Button>
              <Button onClick={() => closeModal()} themed="inline" type="reset">
                {t('form.cancel')}
              </Button>
            </ButtonsWrapper>
          </Form>
        ) : (
          <>
            <Title>{t('del_acc', { ns: 'modal' })}</Title>
            <ButtonsWrapper>
              <Button
                themed={isLoading ? 'disabled' : 'red'}
                onClick={() => setIsConfirm(true)}
                type="button"
                disabled={isLoading}
              >
                {t('form.del')}
              </Button>
              <Button onClick={() => closeModal()} themed="inline" type="reset">
                {t('form.cancel')}
              </Button>
            </ButtonsWrapper>
          </>
        )}
      </Inner>
    </Modal>
  );
};
