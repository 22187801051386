import App from './App';
import {ErrorPage} from './pages';
import {store} from './store/store';
import {theme} from './styles';
import Global from './styles/global';

import {StrictMode} from 'react';

import ReactDOM from 'react-dom/client';
import {ErrorBoundary} from 'react-error-boundary';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './i18n';
import './sentry';
import {shouldForwardProp} from "@/utils/shouldForwardProp";
import {StyleSheetManager} from 'styled-components';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Provider store={store}>
        <BrowserRouter future={{v7_startTransition: true, v7_relativeSplatPath: true,}}>
          <ErrorBoundary
            fallback={
              <>
                <Global theme={theme}/>
                <ErrorPage/>
              </>
            }
          >
            <App/>
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    </StyleSheetManager>
  </StrictMode>,
);
