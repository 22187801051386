import MyInput from '../MyInput/MyInput';

import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
`;
export const IconWrapper = styled.div`
    position: absolute;
    left: 15px;
    top: 56%;
    transform: translateY(-50%);
`;

export const StyledInput = styled(MyInput)`
    & input {
        width: 100%;
        padding: 16px 16px 16px 48px;
        background-color: ${({theme: {isIframe, iframeTheme}}) =>
                isIframe && iframeTheme.current === 'light'
                        ? 'white'
                        : isIframe && iframeTheme.current === 'cintera'
                                ? '#2e2e30'
                                : '#1a1145'};
        border: 1px solid ${({theme: {isIframe, iframeTheme}}) =>
                isIframe && iframeTheme.current === 'light'
                        ? 'rgba(168, 85, 247, 0.3)'
                        : isIframe && iframeTheme.current === 'cintera'
                                ? 'rgb(148 54 89 / 0.3)'
                                : 'rgba(168, 85, 247, 0.3)'};
        border-radius: 0.75rem;
        color: ${({theme: {iframeTheme, isIframe}}) =>
                isIframe && iframeTheme.current === 'light'
                        ? 'rgba(26,17,69)'
                        : isIframe && iframeTheme.current === 'cintera'
                                ? '#E4E4FF'
                                : 'white'};
        transition: all 0.2s;
        font-size: 16px;
        line-height: 18px;

        &::placeholder {
            color: ${({theme: {iframeTheme, isIframe}}) =>
                    isIframe && iframeTheme.current === 'light'
                            ? 'rgba(26,17,69,0.6)'
                            : isIframe && iframeTheme.current === 'cintera'
                                    ? 'rgba(228,228,255,0.4)'
                                    : 'rgba(216, 180, 254, 0.5)'};
            font-size: 16px;
        }

        &:focus,
        &:hover {
            outline: none;
            border: 1px solid ${({theme: {isIframe, iframeTheme}}) =>
                    isIframe && iframeTheme.current === 'light'
                            ? '#a855f7'
                            : isIframe && iframeTheme.current === 'cintera'
                                    ? 'rgb(148 54 89)'
                                    : '#a855f7'};
        }
    }
`;
