import styled from 'styled-components';

import { FooterText } from '@/styles';

export const FooterStyled = styled.footer`
  & > div {
    padding-top: 3rem;
  }
`;

export const GeneralInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TitleNewH3 = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
`;

export const List = styled.ul`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  a {
    color: #bbb6da;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;

    :hover {
      opacity: 0.7;
    }
  }

  svg {
    color: #bbb6da;
  }
`;

export const Text = styled.p`
  color: #bbb6da;
  user-select: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;

export const IconsList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Copy = styled(FooterText)`
  color: #bbb6da;
  font-size: 12px;
  font-weight: 400;
  user-select: none;
  flex-shrink: 0;
`;

export const InfoWrapper = styled.div`
  border-top: 1px solid #251a56;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
