export const YandexSocialIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4478_110)">
        <path
          d="M0.0400391 12C0.0400391 5.3724 5.41124 0 12.04 0C18.6664 0 24.04 5.3724 24.04 12C24.04 18.6276 18.6664 24 12.04 24C5.41124 24 0.0400391 18.6276 0.0400391 12Z"
          fill="#FC3F1D"
        />
        <path
          d="M13.6835 6.89275H12.6306C10.7002 6.89275 9.68489 7.90395 9.68489 9.39481C9.68489 11.0801 10.3868 11.8698 11.8283 12.8821L13.0191 13.7118L9.59714 19H7.04004L10.1111 14.2693C8.3448 12.9599 7.35341 11.6883 7.35341 9.53742C7.35341 6.8409 9.17096 5 12.618 5H16.04V18.987H13.6835V6.89275Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4478_110">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
