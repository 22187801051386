import { Description, Inner, Modal, Title } from '../Modal';

import { useForm } from 'react-hook-form';

import { useDeleteFolderByIdMutation } from '@/api';
import { Button } from '@/components';
import { useActions, useAppSelector } from '@/hooks';
import { FormButtonsStyled } from '@/styles';

export const DeleteFolder = () => {
  const [deleteFolder, { isLoading }] = useDeleteFolderByIdMutation();
  const publicId = useAppSelector((state) => state.modal.data?.id);
  const parentId = useAppSelector((state) => state.modal.data?.parentId);
  const { handleSubmit } = useForm();
  const { closeModal, showNotification } = useActions();
  const closeHandler = () => {
    closeModal();
  };

  const onSubmit = async () => {
    try {
      await deleteFolder({ publicId: publicId ?? '', parentId }).unwrap();
      showNotification({ severity: 'success', text: `Папка успешно удалена` });
      closeHandler();
    } catch (error) {
      showNotification({ severity: 'error', text: 'Произошла ошибка' });
    }
  };

  return (
    <Modal modal={'DeleteFolder'} onClose={() => closeHandler()} closeButton>
      <Title>Удалить папку</Title>
      <Inner modal={'DeleteFolder'}>
        <Description>Вы потеряете доступ ко всем видео в этой папке и не сможете их восстановить</Description>
        <form name="delete-folder" onSubmit={handleSubmit(onSubmit)}>
          <FormButtonsStyled>
            <Button themed="pink" variant="pink" onClick={closeHandler}>
              Отмена
            </Button>
            <Button width="100%" themed="colored" variant="colored" type="submit" disabled={isLoading}>
              Удалить
            </Button>
          </FormButtonsStyled>
        </form>
      </Inner>
    </Modal>
  );
};
