import List from '@mui/material/List';
import styled from 'styled-components';

export const DropdownContainer = styled(List)`
  background-color: ${({ theme: { isIframe, iframeTheme } }) =>
    isIframe && iframeTheme.current === 'light'
      ? 'rgb(230 230 230)'
      : iframeTheme.current === 'cintera'
      ? '#2e2e30'
      : '#1a1145'};
  border: 1px solid
    ${({ theme: { isIframe, iframeTheme } }) =>
      isIframe && iframeTheme.current === 'light'
        ? 'rgba(168, 85, 247, 0.3)'
        : iframeTheme.current === 'cintera'
        ? 'rgb(148 54 89 / 0.3)'
        : 'rgba(168, 85, 247, 0.3)'};
  border-radius: 0.5rem;
  width: max-content;
`;
