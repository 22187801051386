import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';

export const StyledListItemButton = styled(ListItemButton)`
`;

export const StyledList = styled(List)`
    max-height: 290px;
`;

export const StyledListItemText = styled(ListItemText)`
    font-weight: 400;
    font-size: 16px;
    color: white;
`;

export const ItemWrapper = styled.div``;
