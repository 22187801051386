import styled from 'styled-components';

import { SecondaryText } from '@/styles';
import { ServiceResponse } from '@/types';

export const BalanceWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BalanceBlock = styled.div<{ canProcessVideo?: boolean; selectedTarif?: ServiceResponse | null }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #1a1145;
  padding: 20px 24px;
  border-radius: 12px;
  flex: 1;

  :first-child {
    border: ${({ canProcessVideo, selectedTarif }) => selectedTarif && !canProcessVideo && `1px solid #EF7A80`};
  }
`;

export const TextBalance = styled(SecondaryText)`
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
`;

export const SecondaryTextBalance = styled(SecondaryText)`
  font-size: 14px;
  font-weight: 400;
  color: #bbb6da;
`;

export const SecondaryTextButton = styled(SecondaryText)`
  font-size: 13px;
  font-weight: 400;
  background-clip: text;
  background-image: linear-gradient(to right, #792d9c, #d33e8b);
  color: transparent;
  cursor: pointer;
  width: max-content;

  :hover {
    opacity: 0.7;
  }
`;

export const TextInfo = styled(SecondaryTextButton)`
  color: #62548b;

  :hover {
    opacity: 1;
  }
`;
