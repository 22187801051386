
import { useLocation, useParams } from 'react-router-dom';

import { useLazyCopyMaterialVideoQuery } from '@/api';
import { API_SHARE_URL } from '@/constants';
import { useActions, useAppSelector } from '@/hooks';

export const useMaterials = () => {
  const location = useLocation();
  const { id } = useParams();
  const { showNotification } = useActions();
  const openedMaterialTab = useAppSelector((state) => state.materials.openedMaterialTab);

  const [copyMaterial] = useLazyCopyMaterialVideoQuery();

  const content = () => {
    switch (openedMaterialTab) {
      case 1:
        return { material: 'transcript', ru: 'Транскрипт' };
      case 2:
        return { material: 'timecodes', ru: 'Таймкоды' };
      case 3:
        return { material: 'summary', ru: 'Конспект' };
      case 4:
        return { material: 'quiz', ru: 'Тест' };
      default:
        return { material: '', ru: '' };
    }
  };

  const copyCurrentLink = async () => {
    await navigator.clipboard.writeText(`${API_SHARE_URL}${location.pathname}`);
    showNotification({
      text: 'Ссылка скопирована!',
      severity: 'success',
    });
  };

  const copyContent = async (copyButtonName: string) => {
    try {
      const data = await copyMaterial({
        publicId: id || '',
        content: content().material,
        type: copyButtonName === 'text' ? 'txt' : 'html',
      }).unwrap();
      await navigator.clipboard.writeText(data);
      showNotification({
        text: copyButtonName === 'text' ? `${content().ru} успешно скопирован(ы)!` : `HTML-код успешно скопирован!`,
        severity: 'success',
      });
    } catch (e) {
      showNotification({
        text: 'Произошла ошибка при копировании, повторите позже!',
        severity: 'error',
      });
    }
  };

  const exportContent = async (type: string) => {
    try {
      const a = document.createElement('a');
      a.href = `${API_SHARE_URL}/api/v1/videos/${id}/generate-content-as-file/?content=${content().material
        }&type=${type}`;
      a.target = '_blank';
      a.download = `${content().material}.${type}`;
      a.click();

      showNotification({
        text: 'Материал успешно экспортирован!',
        severity: 'success',
      });
    } catch (e) {
      showNotification({
        text: 'Произошла ошибка, повторите позже!',
        severity: 'error',
      });
    }
  };

  return { copyCurrentLink, copyContent, exportContent, openedMaterialTab };
};
