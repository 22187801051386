import { InnerWrapper, TitleTimeWrapper, Wrapper } from '../../../components';

import { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHookFormMask } from 'use-mask-input';

import { Button } from '@/components';
import Input from '@/components/Shared/Input/Input';
import MyInput from '@/components/Shared/MyInput/MyInput';
import PlayIcon from '@/components/SvgIcons/PlayIcon';
import TrashIcon from '@/components/SvgIcons/TrashIcon';
import { theme } from '@/styles';
import { Timecode } from '@/types';
import { parseDuration } from '@/utils';

type TimecodesProps = {
  index: number;
  remove: () => void;
  goToTime?: (value: number) => void;
};

export const TitleTime = ({ index, remove, goToTime }: TimecodesProps) => {
  const { t } = useTranslation('pages');
  const [mousePlayOver, setOnMousePlayOver] = useState(false);
  const [mouseDeleteOver, setOnMouseDeleteOver] = useState(false);

  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<{ timecodes: Timecode[] }>();
  const registerWithMask = useHookFormMask(register);

  const goTo = (index: number) => goToTime?.(parseDuration(getValues(`timecodes.${index}.start`) as string));

  return (
    <Wrapper
      style={{
        border:
          errors.timecodes?.[index]?.title || errors.timecodes?.[index]?.text
            ? `${theme.colors.red.red_light} 1px solid`
            : '1px solid #62548b',
      }}
    >
      <InnerWrapper>
        <TitleTimeWrapper>
          <Button
            onMouseOut={() => setOnMousePlayOver(false)}
            onMouseOver={() => setOnMousePlayOver(true)}
            variant="withIcon"
            themed="transparent"
            onClick={() => goTo(index)}
          >
            <PlayIcon width="24" height="24" fill={!mousePlayOver ? '#E4E4FF' : '#9d9def'} />
          </Button>
          <div style={{ display: 'flex', flex: 1, width: '100%' }}>
            <MyInput
              {...registerWithMask(`timecodes.${index}.start`, '99:99:99', {
                required: true,
                pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
                // validate: (value, formValue) => {
                //   if (index + 1 === formValue.timecodes?.length && value < formValue.timecodes?.[index - 1]?.start) {
                //     return false;
                //   }
                //   if (value && value > formValue.timecodes?.[index + 1]?.start) {
                //     return false;
                //   }
                //   return true;
                // },
              })}
              placeholder="00:00:00"
              style={{
                padding: '12px 0px',
                overflow: 'hidden',
                border: 'none',
                outline: 'none',
                color: errors.timecodes?.[index]?.start ? `${theme.colors.red.red_light}` : '#E4E4FF',
              }}
              error={errors.timecodes?.[index]?.start}
              showErrorMessage={true}
            />
          </div>
          <Button
            onMouseOut={() => setOnMouseDeleteOver(false)}
            onMouseOver={() => setOnMouseDeleteOver(true)}
            variant="withIcon"
            themed="transparent"
            onClick={remove}
          >
            <TrashIcon width="24" height="24" fill={!mouseDeleteOver ? '#E4E4FF' : '#9d9def'} />
          </Button>
        </TitleTimeWrapper>
        <MyInput
          {...register(`timecodes.${index}.title`, { required: true })}
          placeholder={t('admin.timecodes_title')}
          error={errors.timecodes?.[index]?.title}
          showErrorMessage={true}
          style={{
            padding: '12px 16px',
            overflow: 'hidden',
            border: 'none',
            outline: 'none',
            backgroundColor: '#1A1145',
            color: '#E4E4FF',
            borderRadius: '0px',
            borderBottom: '1px solid #62548B',
          }}
        />
        <Input
          type="textarea"
          {...register(`timecodes.${index}.text`, {
            required: true,
          })}
          placeholder={t('admin.timecodes_text')}
          error={errors.timecodes?.[index]?.text}
          showErrorMessage={true}
          style={{
            padding: '12px 16px',
            overflowY: 'scroll',
            border: 'none',
            outline: 'none',
            color: '#E4E4FF',
            minHeight: '144px',
          }}
        />
      </InnerWrapper>
    </Wrapper>
  );
};
