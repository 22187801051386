export const UploadIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.41602 20C7.41602 19.5858 7.08023 19.25 6.66602 19.25C6.2518 19.25 5.91602 19.5858 5.91602 20H7.41602ZM6.66602 21.3334H5.91602H6.66602ZM26.0827 20C26.0827 19.5858 25.7469 19.25 25.3327 19.25C24.9185 19.25 24.5827 19.5858 24.5827 20H26.0827ZM16.585 7.13521C16.8438 6.81176 16.7913 6.33979 16.4679 6.08104C16.1444 5.82228 15.6725 5.87472 15.4137 6.19817L16.585 7.13521ZM10.0804 12.8648C9.82161 13.1883 9.87405 13.6602 10.1975 13.919C10.5209 14.1778 10.9929 14.1253 11.2517 13.8019L10.0804 12.8648ZM16.585 6.19817C16.3262 5.87472 15.8543 5.82228 15.5308 6.08104C15.2074 6.33979 15.1549 6.81176 15.4137 7.13521L16.585 6.19817ZM20.747 13.8019C21.0058 14.1253 21.4778 14.1778 21.8012 13.919C22.1246 13.6602 22.1771 13.1883 21.9183 12.8648L20.747 13.8019ZM16.7493 6.66669C16.7493 6.25247 16.4136 5.91669 15.9993 5.91669C15.5851 5.91669 15.2493 6.25247 15.2493 6.66669H16.7493ZM15.2493 21.3334C15.2493 21.7476 15.5851 22.0834 15.9993 22.0834C16.4136 22.0834 16.7493 21.7476 16.7493 21.3334H15.2493ZM5.91602 20V21.3334H7.41602V20H5.91602ZM5.91602 21.3334C5.91602 23.9567 8.04266 26.0834 10.666 26.0834V24.5834C8.87109 24.5834 7.41602 23.1283 7.41602 21.3334H5.91602ZM10.666 26.0834H21.3327V24.5834H10.666V26.0834ZM21.3327 26.0834C23.956 26.0834 26.0827 23.9567 26.0827 21.3334H24.5827C24.5827 23.1283 23.1276 24.5834 21.3327 24.5834V26.0834ZM26.0827 21.3334V20H24.5827V21.3334H26.0827ZM15.4137 6.19817L10.0804 12.8648L11.2517 13.8019L16.585 7.13521L15.4137 6.19817ZM15.4137 7.13521L20.747 13.8019L21.9183 12.8648L16.585 6.19817L15.4137 7.13521ZM15.2493 6.66669V21.3334H16.7493V6.66669H15.2493Z"
        fill="#E4E4FF"
      />
    </svg>
  );
};
