import styled from 'styled-components';

export const ReferralLinkInputWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const StyledInput = styled.input`
  height: 45px;
  width: 100%;
  border: 2px solid #62548b;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 40px 12px 12px;

  :focus-visible {
    outline: none;
    outline-offset: initial;
  }
`;

export const CopyButton = styled.div`
    position: absolute;
    right: 12px;
    top: 15px;
    cursor: pointer;

    &:hover {
        svg {
            stroke: #9d9def;
        }
`;
