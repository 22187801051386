import {
  BalanceBlock,
  BalanceWrapper,
  SecondaryTextBalance,
  SecondaryTextButton,
  TextBalance,
  TextInfo,
} from './MyBalanceBlock.styled';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ServiceResponse } from '@/types';

type MyBalanceBlockProps = {
  countBalance: number;
  estimatedCost?: number;
  canProcessVideo?: boolean;
  showBalanceStatus: boolean;
  onClose: () => void;
  videoName: string;
  duration: number;
  validLink: string;
  selectedFolder: string | null;
  selectedTarif?: ServiceResponse | null;
};
export const MyBalanceBlock = ({
  countBalance,
  estimatedCost = 0,
  onClose,
  videoName,
  duration,
  validLink,
  selectedFolder,
  canProcessVideo,
  selectedTarif,
}: MyBalanceBlockProps) => {
  const { t } = useTranslation('modal');
  const navigate = useNavigate();
  const addSumToBalance = () => {
    onClose();
    navigate('/balance', {
      state: {
        selectedFolder: selectedFolder,
        duration: duration,
        videoName: videoName,
        link: validLink,
      },
    });
  };

  return (
    <BalanceWrapper>
      <BalanceBlock selectedTarif={selectedTarif} canProcessVideo={canProcessVideo}>
        <SecondaryTextBalance>{t('my_balance')}</SecondaryTextBalance>
        <TextBalance style={selectedTarif ? { color: canProcessVideo ? '#ffffff' : '#EF7A80' } : {}}>
          {countBalance} ₽
        </TextBalance>
        <SecondaryTextButton onClick={addSumToBalance}>{t('recharge_ViTokens')}</SecondaryTextButton>
      </BalanceBlock>
      <BalanceBlock>
        <SecondaryTextBalance>Длительность видео:</SecondaryTextBalance>
        <TextBalance>{duration} минут</TextBalance>
        <TextInfo>Округляется в большую сторону</TextInfo>
      </BalanceBlock>
      <BalanceBlock>
        <SecondaryTextBalance>Стоимость:</SecondaryTextBalance>
        <TextBalance>{estimatedCost} ₽</TextBalance>
      </BalanceBlock>
    </BalanceWrapper>
  );
};
