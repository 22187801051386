import { api } from './api';

import type {
  BaseParams,
  CreatePlaylistType,
  GetList,
  PartialUpdatePlaylist,
  Playlist,
  PlaylistParams,
  VideoWithFragments,
} from '@/types';

import { getSearchParamFromURL } from '@/utils';

const path = 'playlists';

export const playlistsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getPlaylists: build.query<GetList<Playlist>, { params?: PlaylistParams }>({
      query: ({ params }) => ({
        url: `${path}/`,
        method: 'GET',
        params,
      }),
      extraOptions: { maxRetries: 8 },
      transformErrorResponse: (error) => {
        return { status: error.status };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ publicId: id }) => ({ type: 'playlists' as const, id })),
              { type: 'playlists', id: 'LIST' },
            ]
          : [{ type: 'playlists', id: 'LIST' }],
    }),

    getPlaylistById: build.query<Playlist, { id: string } & { params?: PlaylistParams }>({
      query: ({ id, params }) => ({
        url: `${path}/${id}/`,
        method: 'GET',
        params,
      }),
      providesTags: (result, _, { id }) =>
        result
          ? [
              { type: 'playlist' as const, id },
              { type: 'playlist', id: 'one' },
            ]
          : [{ type: 'playlists', id: 'one' }],
    }),

    getPlaylistsByName: build.query<Playlist[], { name: string }>({
      query: ({ name }) => ({
        url: `${path}/`,
        params: { name },
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ publicId: id }) => ({ type: 'playlists' as const, id })),
              { type: 'playlists', id: 'LIST' },
            ]
          : [{ type: 'playlists', id: 'LIST' }],
    }),

    //-----------------------------------------------------------------------------------------------------------------------------------
    getMyPlaylists: build.query<GetList<Playlist>, { params?: BaseParams & Pick<PlaylistParams, 'title'> }>({
      query: ({ params }) => ({
        url: `${path}/my/`,
        method: 'GET',
        params,
      }),
      // transformResponse: (data: GetList<PersonalPlaylist>) => data.results,
      providesTags: ['playlists'],
    }),

    addVideoToPlaylist: build.mutation<
      Playlist,
      { playlistId: string; videos: { videoPublicId: string; isAiSuggested: boolean }[] }
    >({
      query: ({ playlistId, videos }) => ({
        url: `${path}/${playlistId}/add-video/`,
        method: 'POST',
        body: { videos },
      }),
      invalidatesTags: (_, __, { playlistId }) => [
        'playlists',
        { type: 'playlist', id: playlistId },
        { type: 'videos', id: 'LIST' },
      ],
    }),

    removeVideoFromPlaylist: build.mutation<Playlist, { playlistId: string; videoPublicId: string }>({
      query: ({ playlistId, videoPublicId }) => ({
        url: `${path}/${playlistId}/remove-video/`,
        method: 'POST',
        body: { videoPublicId },
      }),
      invalidatesTags: (_, __, { playlistId, videoPublicId }) => [
        'playlists',
        { type: 'playlist', id: playlistId },
        { type: 'videos', id: videoPublicId },
      ],
    }),

    createPlaylist: build.mutation<Playlist, CreatePlaylistType>({
      query: (body) => ({
        url: `${path}/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'playlists', id: 'LIST' }],
    }),

    updatePartialPlaylistById: build.mutation<Playlist, { id: string; body: PartialUpdatePlaylist }>({
      query: ({ id, body }) => ({
        url: `${path}/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'playlists', id },
        { type: 'playlist', id },
      ],
    }),

    updatePlaylistById: build.mutation<Playlist, { id: string; body: CreatePlaylistType }>({
      query: ({ id, body }) => ({
        url: `${path}/${id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'playlists', id },
        { type: 'playlist', id },
      ],
    }),

    deletePlaylist: build.mutation<string, Pick<Playlist, 'publicId'>>({
      query: ({ publicId }) => ({
        url: `${path}/${publicId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { publicId }) => [{ type: 'playlists', id: publicId }],
    }),

    getFullSearchInPlaylist: build.query<VideoWithFragments[], Pick<Playlist, 'publicId'> & { query: string }>({
      query: ({ publicId, query }) => ({
        url: `${path}/${publicId}/full_search/`,
        method: 'GET',
        params: { query },
      }),

      transformResponse: (data: VideoWithFragments[]) => {
        // const dataWithCues = data.filter((video) => video.cues.length > 0);
        return data.map((video) => ({
          ...video,
          cues: video.cues.map((cue) => ({
            ...cue,
            timestampLink: getSearchParamFromURL(cue.timestampLink, 't'),
          })),
        }));
      },
    }),

    // getSuggestionVideos: build.query<
    //   SuggestVideoType[],
    //   Pick<Playlist, 'publicId'> & { previouslySuggestedVideos: string[] }
    // >({
    //   query: ({ publicId, previouslySuggestedVideos }) => ({
    //     url: `${path}/${publicId}/suggest-video/`,
    //     method: 'POST',
    //     body: { previouslySuggestedVideos },
    //   }),
    //   keepUnusedDataFor: 9999999999,
    // }),

    getGenerate: build.mutation<VideoWithFragments[], void>({
      query: () => ({
        url: `${path}/generate/`,
        method: 'POST',
      }),
    }),
  }),
});
