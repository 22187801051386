// import { useHandlingError } from '../../../../hooks/useErrorHandling';
import { Form } from '../Login/Login.styled';
import { Modal, Title } from '../Modal';
import { FieldsWrapper, SubmitButton } from '../Registration/RegistrationModal/B2CForm/B2CForm.styled';
import { MyInput } from '../Registration/RegistrationModal/B2CForm/MyInput';
import { Inner } from '../RemoveUser/RemoveUser.styled';

import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { usersAPI } from '@/api';
import { useActions } from '@/hooks';
import { isAuthData } from '@/utils/isAuthData';

export const ResendEmail = () => {
  const { t } = useTranslation('components');
  const { closeModal, openModal } = useActions();
  const [, setParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();

  // const { catchError } = useHandlingError();

  const [resendEmail, status] = usersAPI.useResendActivationMutation();

  const mess = t('set_pass_reset_lint', { ns: 'error' });
  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState<boolean>(status.isError);
  const [errorAlertMessage, setErrorAlertMessage] = useState<string>(mess);

  const closeHandler = () => {
    reset();
    setErrorAlertIsOpen(false);
    closeModal();
    setParams();

    if (location.pathname.includes('activate')) {
      navigate('/landing', { replace: true });
    }
  };

  useEffect(() => {
    if (isAuthData(status.error)) {
      const words = status.error.data[0].split(' ');
      const email = getValues('email');
      const message = words.includes('email')
        ? t('inverify_email', { ns: 'error', email })
        : t('incor_email', { ns: 'error' });
      setErrorAlertMessage(message);
      setErrorAlertIsOpen(true);
    }
  }, [status.error]);

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<{ email: string }>({ defaultValues: { email: '' } });

  const onSubmit = async (data: { email: string }) => {
    try {
      await resendEmail(data).unwrap();

      openModal({ active: 'emailverify', data });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal modal={'resendEmail'} onClose={closeHandler} closeButton>
      <Title>{t('res_email', { ns: 'modal' })}</Title>
      <Inner>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Collapse in={errorAlertIsOpen}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlertIsOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <AlertTitle>{t('log_activate', { ns: 'error' })}</AlertTitle>
              {errorAlertMessage}
            </Alert>
          </Collapse>
          <FieldsWrapper>
            <MyInput
              required
              label={t('form.email')}
              {...register('email', {
                required: { value: true, message: t('req_field', { ns: 'error' }) },
                minLength: { value: 5, message: t('min_char', { ns: 'error', length: 5 }) },
                maxLength: { value: 256, message: t('max_char', { ns: 'error', length: 256 }) },
                pattern: {
                  value: /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-z]{2,6}$/,
                  message: t('incor_email', { ns: 'error' }),
                },
              })}
              // placeholder={t('form.email')}
              error={errors.email}
            />
          </FieldsWrapper>
          <SubmitButton type="submit" disabled={status.isLoading}>
            {t('form.send')}
          </SubmitButton>
        </Form>
      </Inner>
    </Modal>
  );
};
