import { FoldersContainer, TitleWrapper } from './FolderLayout.styled';

import { useCreateFolder } from '../../model';
import { Breadcrumbs } from '../components';

import { Outlet, useLocation } from 'react-router-dom';

import { ButtonWithIcon, DeleteFolder } from '@/components';
import { FolderIcon } from '@/components/SvgIcons/FolderIcon';
import { useMatchMedia } from '@/hooks';

export const FolderLayout = () => {
  const isMobile = useMatchMedia('(max-width: 768px)');
  const { openModalHandler } = useCreateFolder();
  const location = useLocation();
  const videoInFolder = location.pathname.includes('folders/videos');
  return (
    <FoldersContainer>
      {!videoInFolder && (
        <TitleWrapper>
          <Breadcrumbs />
          {!isMobile && <ButtonWithIcon icon={<FolderIcon />} buttonText="Создать папку" onClick={openModalHandler} />}
        </TitleWrapper>
      )}
      <Outlet />
      <DeleteFolder />
    </FoldersContainer>
  );
};
