import styled, {css} from 'styled-components';

import {ModalType} from '@/store/slices/modalsSlice';
import {closeModal, closeOverlay, LabelText, openModal, openOverlay, TitleH4} from '@/styles';

export const ModalStyled = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media screen and (max-width: 550px) {
        width: 100%;
        padding: 0;
        align-items: flex-end;
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const Overlay = styled.div<{ open: boolean }>`
    position: fixed;
    inset: 0;
    backdrop-filter: blur(12px);
    background: rgb(8 4 32);
    animation: 0.2s ${({open}) => (open ? openOverlay : closeOverlay)} forwards normal;
`;

export const Content = styled.div<{ open: boolean; modal: ModalType }>`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 24px;
    width: max-content;
    min-width: 300px;
    max-height: 90vh;
    justify-content: center;
    height: max-content;

    background-color: #251a56;
    opacity: 0;
    animation: 0.4s ${({open}) => (open ? openModal : closeModal)} forwards normal;

    @media screen and (max-width: 550px) {
        width: 100%;
        padding: 24px 20px;
        border-radius: 20px 20px 0 0;
    }
    @media screen and (max-width: 359px) {
        padding: 20px 15px;
    }

    ${(props) => {
        switch (props.modal) {
                // case 'AddPlayList':
                // case 'EditPlayList':
                //   return css`
                //     @media screen and (max-height: 800px) {
                //       height: 100%;
                //     }
                //   `;
                // case 'AddPlayListVideo':
                // case 'SuggestVideo':
                //   return css`
                //     /* width: 500px; */
                //     @media screen and (max-height: 765px) {
                //     }
                //   `;
            case 'registration':
                return css`
                    width: 100vw;
                    max-width: 100%;

                    @media (min-width: 768px) {
                        width: 465px;
                        border-radius: 10px;
                    }
                `;
            case 'Video':
                return css`
                    @media screen and (max-height: 645px) {
                        height: 100%;
                    }
                `;
            case 'login':
                return css`
                    width: 465px;
                    @media screen and (max-height: 437px) {
                        height: 100%;
                    }
                `;
            case 'AddVideo':
                return css`
                    @media screen and (max-height: 448px) {
                        height: 100%;
                    }

                    @media screen and (max-width: 526px) {
                        width: 100%;
                        max-height: 95%;
                    }
                    @media screen and (min-width: 526px) {
                        width: max-content;
                        max-width: 864px;
                    }
                `;
            case 'CreateFolder':
                return css`
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    max-width: 540px;
                    max-height: 328px;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                        max-height: 100%;
                    }
                `;
            case 'MoveVideo':
                return css`
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    width: 540px;
                    max-height: 720px;
                `;
            case 'MoveFolder':
                return css`
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    width: 540px;
                    max-height: 720px;
                `;
            case 'RenameFolder':
                return css`
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    max-width: 540px;
                    width: 540px;
                `;
            case 'EditVideo':
                return css`
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    max-width: 540px;
                    width: 540px;
                `;
            case 'DeleteFolder':
                return css`
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    max-width: 540px;
                    width: 540px;
                `;

            case 'RemoveVideo':
                return css`
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    max-width: 540px;
                    width: 540px;
                `;
            default:
                return css``;
        }
    }}
`;

export const ExitButtonWrapper = styled.button`
    position: absolute;
    top: 25px;
    right: 25px;
`;

export const Inner = styled.div<{ modal: ModalType }>`
    display: flex;
    overflow: auto;

    ::-webkit-scrollbar {
        width: 6px; /* ширина scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: transparent; /* цвет дорожки */
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 20px; /* закругления плашки */
        background-color: rgb(59 42 126); /* цвет плашки */
    }

    ${({modal}) => {
        switch (modal) {
                // case 'AddPlayList':
                // case 'EditPlayList':
                //   return css`
                //     @media screen and (max-height: 800px) {
                //       //padding: 0 10px 0 0;
                //     }
                //   `;
                // case 'AddPlayListVideo':
                // case 'SuggestVideo':
                //   return css`
                //     overflow: visible;
                //     @media screen and (max-height: 765px) {
                //       padding: 0 10px 0 0;
                //     }
                //   `;
            case 'registration':
                return css`
                    overflow: initial;
                `;

            case 'Video':
                return css`
                    @media screen and (max-height: 645px) {
                        padding: 0 10px 0 0;
                    }
                `;
            case 'login':
                return css`
                    overflow: initial;
                    @media screen and (max-height: 437px) {
                        padding: 0 10px 0 0;
                    }
                `;
            case 'AddVideo':
                return css`
                    flex-direction: column;
                    gap: 20px;
                `;
            case 'EditVideo':
                return css`
                    flex-direction: column;
                    gap: 20px;
                `;

            case 'CreateFolder':
                return css`
                    max-width: 540px;
                    max-height: 328px;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                        max-height: 100%;
                    }
                `;
            case 'MoveVideo':
                return css`
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                `;
            case 'MoveFolder':
                return css`
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                `;
            case 'RenameFolder':
                return css`
                    max-width: 540px;
                    max-height: 328px;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                `;
            case 'DeleteFolder':
                return css`
                    max-width: 540px;
                    max-height: 328px;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                `;
            case 'RemoveVideo':
                return css`
                    max-width: 540px;
                    max-height: 328px;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                `;
        }
    }}
`;

export const InnerAuth = styled(Inner)`
    flex-direction: column;
    gap: 16px;

    width: 100vw;
    max-width: 100%;

    @media (min-width: 768px) {
        width: 800px;
        border-radius: 10px;
    }
`;

export const Title = styled(TitleH4)`
    font-size: 24px;
    line-height: 26px;
    font-weight: 500;
    color: #ffffff;
`;

export const OtherText = styled(LabelText)`
    display: flex;
`;

export const Description = styled.p`
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #ffffff;
`;
